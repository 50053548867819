import {
  FORGET_FINAL_PASSWORD_FAILURE,
  FORGET_FINAL_PASSWORD_LOADING,
  FORGET_FINAL_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILURE,
  FORGET_PASSWORD_LOADING,
  FORGET_PASSWORD_SUCCESS,
  RESET_FINAL_FORGET_PASSWORD,
  RESET_FORGET_PASSWORD,
} from "../actions/actionTypes";

export const forgetPassReducer = (
  state = {
    resetPass: {
      isError: false,
      isLoading: false,
      message: "",
      error: undefined,
    },
    confirmResetPass: {
      isError: false,
      isLoading: false,
      message: "",
      error: undefined,
    },
  },
  action
) => {
  switch (action.type) {
    case FORGET_PASSWORD_LOADING:
      return {
        ...state,
        resetPass: {
          isError: false,
          isLoading: true,
          error: undefined,
          message: "",
        },
      };
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPass: {
          isError: false,
          isLoading: false,
          error: undefined,
          message: action.message,
        },
      };
    case FORGET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPass: {
          isError: true,
          isLoading: false,
          error: action.error,
          message: "",
        },
      };
    case FORGET_FINAL_PASSWORD_LOADING:
      return {
        ...state,
        confirmResetPass: {
          isError: false,
          isLoading: true,
          error: undefined,
          message: "",
        },
      };
    case FORGET_FINAL_PASSWORD_SUCCESS:
      return {
        ...state,
        confirmResetPass: {
          isError: false,
          isLoading: false,
          error: undefined,
          message: action.message,
        },
      };
    case FORGET_FINAL_PASSWORD_FAILURE:
      return {
        ...state,
        confirmResetPass: {
          isError: true,
          isLoading: false,
          error: action.error,
          message: "",
        },
      };
    case RESET_FORGET_PASSWORD:
      return {
        ...state,
        resetPass: {
          isLoading: false,
          isError: false,
          message: "",
          error: undefined,
        },
        confirmResetPass: {
          isLoading: false,
          isError: false,
          message: "",
          error: undefined,
        },
      };

    default:
      return state;
  }
};

import { eventChannel } from "redux-saga";

let ws; // Define ws at the module level to be accessible for logout

function WebsocketApi(endpoint) {
  return eventChannel((emit) => {
    ws = new WebSocket(endpoint);

    ws.onopen = () => {
      console.log("WebSocket connected");
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      emit(data); // Send the received data to the saga
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    ws.onclose = () => {
      console.log("WebSocket closed");
    };

    return () => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.close();
      }
    };
  });
}

export const logoutFromWebSocket = () => {
  if (ws && ws.readyState === WebSocket.OPEN) {
    ws.close(1000, "User logged out"); // Close with status 1000 for a clean close
    ws.onclose = () => {
      console.log("WebSocket closed after logout.");
      ws = null; // Clear ws reference to ensure it's not reused
    };
    console.log("Logged out from WebSocket.");
  } else {
    console.log("WebSocket is not connected or already closed.");
  }
};

export default WebsocketApi;

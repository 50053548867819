import React from "react";
import "../../../styles/Fonts.scss";
import { MdOutlineClose } from "react-icons/md";
import "./trackSubmission.css";
import Backbutton from "../../Shared/BackButton/BackButton";

const TrackSubmissionStatus = ({ onBack, onNext, onPopupClose }) => {
  return (
    <div className="trackSubmission">
      <div className="">
        <div className="trackSubmission-close-div" onClick={onPopupClose}>
          <MdOutlineClose onClick={onPopupClose} />
        </div>
        <div className="trackSubmission-rectangle">
          <div className="trackSubmission-header Mainheader">
            Track Submission
          </div>
          <div className="trackSubmission-text sub-subtitle2">
            This is the information that our music supervisors can see when
            viewing your submission.
          </div>
          <div className="trackSubmission-contents">
            <div className="trackSubmission-input">
              <div className="title2">Track Status</div>
              <div className="Body">Answer goes here.</div>
            </div>
            <div className="trackSubmissionStatus-input">
              <div className="Body">Answer</div>
              <div className="title2">
                Has the track been synced or pitched for sync before?
              </div>
            </div>
            <div className="trackSubmissionStatus-input">
              <div className="Body">Answer</div>
              <div className="title2">
                Are there any samples used or purchased in this track?
              </div>
            </div>
            <div className="trackSubmission-input">
              <div className="title2">Sample Sources</div>
              <div className="Body">Inputted text goes here.</div>
              <div className="Body">Inputted text goes here.</div>
            </div>
          </div>
          <div className="trackSubmission-Btn" onClick={onBack}>
            <Backbutton />
          </div>
          <div className="trackSubmission-button">
            <button
              className="SMLButton2 Button1 trackSubmission-btn"
              onClick={onNext}
            >
              Next Page
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackSubmissionStatus;

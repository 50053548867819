import {
  GET_ALL_ARTIST_FAILURE,
  GET_ALL_ARTIST_LOADING,
  GET_ALL_ARTIST_SUCCESS,
  GET_ALL_TRACK_FAILURE,
  GET_ALL_TRACK_SUCCESS,
  GET_ALL_TRACK_LOADING,
  UPDATE_ARTIST_REQUEST,
  UPDATE_ARTIST_SUCCESS,
  UPDATE_ARTIST_FAILURE,
  RESET_GET_ALL_TRACK,
  GET_ALL_TRACK_PAGINATION_SUCCESS,
} from "../actions/actionTypes";
import {
  GET_FEEDBACK_RECEIVED_TRACKS_FAILURE,
  GET_FEEDBACK_RECEIVED_TRACKS_LOADING,
  GET_FEEDBACK_RECEIVED_TRACKS_SUCCESS,
  GET_UNDER_REVIEW_TRACKS_FAILURE,
  GET_UNDER_REVIEW_TRACKS_LOADING,
  GET_UNDER_REVIEW_TRACKS_SUCCESS,
  DOWNLOAD_TRACK_LOADING,
  DOWNLOAD_TRACK_SUCCESS,
  DOWNLOAD_TRACK_FAILURE,
  RESET_ARTIST,
} from "../actions/smlActions";

export const smlReducer = (
  state = {
    underReviewTracks: {
      isError: false,
      isLoading: false,
      tracks: [],
      error: undefined,
    },
    feedbackReceivedTracks: {
      isError: false,
      isLoading: false,
      tracks: [],
      error: undefined,
    },
    allArtistFetched: {
      isError: false,
      isLoading: false,
      artists: [],
      error: undefined,
    },
    allTracksFetched: {
      isError: false,
      isLoading: false,
      tracks: [],
      error: undefined,
    },
    updateArtist: {
      isLoading: false,
      artist: null,
      error: null,
      updates: [],
    },
    downloadTrackById: {
      track_url: [],
      isLoading: false,
      isError: false,
      error: undefined,
    },
    pagination: {
      info: [],
      isLoading: false,
      isError: false,
      error: undefined,
    },
  },
  action
) => {
  switch (action.type) {
    case GET_UNDER_REVIEW_TRACKS_LOADING:
      return {
        ...state,
        underReviewTracks: {
          tracks: [],
          isLoading: true,
          isError: false,
          error: undefined,
        },
      };
    case GET_UNDER_REVIEW_TRACKS_SUCCESS:
      return {
        ...state,
        underReviewTracks: {
          tracks: action.tracks,
          isLoading: false,
          isError: false,
          error: undefined,
        },
      };
    case GET_UNDER_REVIEW_TRACKS_FAILURE:
      return {
        ...state,
        underReviewTracks: {
          tracks: [],
          isLoading: false,
          isError: true,
          error: action.error,
        },
      };

    case GET_FEEDBACK_RECEIVED_TRACKS_LOADING:
      return {
        ...state,
        feedbackReceivedTracks: {
          tracks: [],
          isLoading: true,
          isError: false,
          error: undefined,
        },
      };
    case GET_FEEDBACK_RECEIVED_TRACKS_SUCCESS:
      return {
        ...state,
        feedbackReceivedTracks: {
          tracks: action.tracks,
          isLoading: false,
          isError: false,
          error: undefined,
        },
      };
    case GET_FEEDBACK_RECEIVED_TRACKS_FAILURE:
      return {
        ...state,
        feedbackReceivedTracks: {
          tracks: [],
          isLoading: false,
          isError: true,
          error: action.error,
        },
      };
    case GET_ALL_ARTIST_LOADING:
      return {
        ...state,
        allArtistFetched: {
          artists: [],
          isLoading: true,
          isError: false,
          error: undefined,
        },
      };
    case GET_ALL_ARTIST_SUCCESS:
      return {
        ...state,
        allArtistFetched: {
          artists: action.artists,
          isLoading: false,
          isError: false,
          error: undefined,
        },
      };
    case GET_ALL_ARTIST_FAILURE:
      return {
        ...state,
        allArtistFetched: {
          artists: [],
          isLoading: false,
          isError: true,
          error: action.error,
        },
      };
    case RESET_ARTIST:
      return {
        ...state,
        allArtistFetched: {
          artists: [],
          isLoading: false,
          isError: false,
          error: undefined,
        },
      };
    case GET_ALL_TRACK_LOADING:
      return {
        ...state,
        allTracksFetched: {
          ...state.allTracksFetched,
          isLoading: true,
          isError: false,
          error: undefined,
        },
      };

    case GET_ALL_TRACK_PAGINATION_SUCCESS:
      // console.log(
      //   "GET_ALL_TRACK_PAGINATION_SUCCESS reducer triggered",
      //   action.pagination
      // );
      return {
        ...state,
        pagination: {
          info: action.pagination,
          isLoading: false,
          isError: false,
          error: undefined,
        },
      };

    case RESET_GET_ALL_TRACK:
      return {
        ...state,
        allTracksFetched: {
          tracks: [],
          isLoading: false,
          isError: false,
          error: undefined,
        },
      };
    case GET_ALL_TRACK_SUCCESS:
      return {
        ...state,
        allTracksFetched: {
          ...state.allTracksFetched,
          tracks: [...state.allTracksFetched.tracks, ...action.tracks],
          isLoading: false,
          isError: false,
          error: undefined,
        },
      };

    case GET_ALL_TRACK_FAILURE:
      return {
        ...state,
        allTracksFetched: {
          tracks: [],
          isLoading: false,
          isError: true,
          error: action.error,
        },
      };
    case UPDATE_ARTIST_REQUEST:
      return {
        ...state.updateArtist,
        loading: true,
        error: null,
      };
    case UPDATE_ARTIST_SUCCESS:
      return {
        updates: action.payload,
        loading: false,
        error: null,
      };
    case UPDATE_ARTIST_FAILURE:
      return {
        ...state.updateArtist,
        loading: false,
        error: action.payload,
      };
    case DOWNLOAD_TRACK_LOADING:
      return {
        ...state,
        downloadTrackById: {
          track_url: [],
          isLoading: true,
          isError: false,
          error: undefined,
        },
      };
    case DOWNLOAD_TRACK_SUCCESS:
      return {
        ...state,
        downloadTrackById: {
          track_url: action.track_url,
          isLoading: false,
          isError: false,
          error: undefined,
        },
      };
    case DOWNLOAD_TRACK_FAILURE:
      return {
        ...state,
        downloadTrackById: {
          tracks: [],
          isLoading: false,
          isError: true,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

export default smlReducer;

import React, { useEffect } from "react";
import Styles from "./HomeLayout.module.css";
import SideBar from "../common/side-bar/SideBar";
import Header from "../common/header/Header";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function HomeLayout({
  children,
  isPopupOpen,
  togglePopup,
  status,
  applyScroll, //make it true if you want scroll in children
}) {
  const location = useLocation();
  const email =
    useSelector((store) => store.sml.allArtistFetched?.artists) || [];

  useEffect(() => {
    if (email?.name) {
      localStorage.setItem("username", email?.name);
    }
  }, [email]);

  return (
    <div className={Styles.container}>
      <div>
        <SideBar />
      </div>
      <div className={Styles.contentContainer}>
        <Header
          isPopupOpen={isPopupOpen}
          togglePopup={togglePopup}
          status={status}
        />
        <div
          className={`${Styles.childrenContainer} ${
            applyScroll ? Styles.scrollableContainer : ""
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

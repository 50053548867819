import "../../styles/Fonts.scss";
import "../../SignUpPlanPages/SignUpPlanPages.css";
import SMLButton from "../../components/Shared/SMLButton/SMLButton";
import { useNavigate } from "react-router-dom";
import SuccessCheckMark from "../../assets/Images/SuccessCheckmark.svg";
import Steps from "../PlanSteps/PlanSteps";
import { MdOutlineClose } from "react-icons/md";

const Welcome = (props) => {
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate("/dashboard");
  };
  const handleLoginClick = () => {
    navigate("/login");
  };

  return (
    <div className="signUp-container">
      <div className="signup-card">
        <div className="signUp-groupby">
          <div className="signUp-progress-steps">
            <Steps step="4" />
          </div>
          <div className="signUp-close-div">
            <MdOutlineClose />
          </div>
        </div>
        <div className="signUp-Welcome-main">
          <div className="welcome-success-checkMark">
            <span className="sml-iconbtn fontcolorblack">
              <img src={SuccessCheckMark} alt="icon" />
            </span>
          </div>
          <div className="Welcome-label-div">
            <div className="Welcome-Label-text Mainheader">
              Welcome On Board!
            </div>
            <div className="Welcome-label-track subtitle3">
              Thank you for signing up! Please check your inbox (and spam
              folder) to confirm your email. After confirmation, you can login
              and submit your first track.
            </div>
            <div className="Welcome-btn-grp">
              <SMLButton
                style="SMLButton2 SMLButton-Orange"
                type="button"
                value="Login"
                handleClickEvent={handleLoginClick}
              />
              <button
                className="button1 welcome-home-btn"
                type="button"
                onClick={handleHomeClick}
              >
                Home
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;

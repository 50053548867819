import * as types from './actionTypes';

// GET VIDEO SIGNED URL actions
export const getVideoSignedUrlRequest = (payload) => ({
  type: types.GET_VIDEO_SIGNED_URL_REQUEST,
  payload
});

export const getVideoSignedUrlSuccess = (data) => ({
  type: types.GET_VIDEO_SIGNED_URL_SUCCESS,
  payload: data
});

export const getVideoSignedUrlFailure = (error) => ({
  type: types.GET_VIDEO_SIGNED_URL_FAILURE,
  payload: error
});

export const getVideoSignedUrlLoading = () => ({
  type: types.GET_VIDEO_SIGNED_URL_LOADING
});

// GET VIDEO URL actions
export const getVideoUrlRequest = (feedbackId) => ({
  type: types.GET_VIDEO_URL_REQUEST,
  payload: { feedbackId }
});

export const getVideoUrlSuccess = (response) => ({
  type: types.GET_VIDEO_URL_SUCCESS,
  payload: response
});

export const getVideoUrlFailure = (error) => ({
  type: types.GET_VIDEO_URL_FAILURE,
  payload: error
});

export const getVideoUrlLoading = () => ({
  type: types.GET_VIDEO_URL_LOADING
});

// DELETE VIDEO actions
export const deleteVideoRequest = (feedbackId) => ({
  type: types.DELETE_VIDEO_REQUEST,
  payload: feedbackId
});

export const deleteVideoSuccess = () => ({
  type: types.DELETE_VIDEO_SUCCESS
});

export const deleteVideoFailure = (error) => ({
  type: types.DELETE_VIDEO_FAILURE,
  payload: error
});

export const deleteVideoLoading = () => ({
  type: types.DELETE_VIDEO_LOADING
});

// UPLOAD VIDEO actions
export const uploadVideoRequest = (payload) => ({
  type: types.UPLOAD_VIDEO_REQUEST,
  payload
});

export const uploadVideoSuccess = () => ({
  type: types.UPLOAD_VIDEO_SUCCESS
});

export const uploadVideoFailure = (error) => ({
  type: types.UPLOAD_VIDEO_FAILURE,
  payload: error
});

export const uploadVideoLoading = () => ({
  type: types.UPLOAD_VIDEO_LOADING
}); 
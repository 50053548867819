import React from "react";
import ProgressSteps from "../../SignUpPage/ProgressSteps/ProgressSteps";
import closeIcon from "../../../assets/Images/closeIcon.svg";
import SuccessCheckMark from "../../../assets/Images/SuccessCheckmark.svg";
import "./ViewPlan.css";
const ViewPlanSuccess = (props) => {
  return (
    <div>
      <div className="signUp-progress-steps">
        {props.upgradePlan ? (
          <ProgressSteps step="3" upgrade="true" />
        ) : (
          <ProgressSteps step="4" />
        )}
      </div>
      <div className="signUp-close-div" onClick={props.closePopup}>
        <img src={closeIcon} style={{ cursor: "pointer" }} alt="Close" />
      </div>
      <div className="upgrade-welcome-main">
        <div className="welcome-success-checkMark">
          <span className="sml-iconbtn fontcolorblack">
            <img src={SuccessCheckMark} alt="icon" />
          </span>
        </div>
        <div className="Welcome-label-div">
          <div className="Welcome-Label-text Mainheader">Plan upgraded!</div>
          <div className="Welcome-label-track subtitle3">
            You can now go back to your profile
          </div>
          <div className="Welcome-btn-grp">
            <button
              className="button1 welcome-home-btn"
              type="button"
              onClick={props.closePopup}
            >
              Back to Profile
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPlanSuccess;

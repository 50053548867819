import React from "react";
import "../../../styles/Fonts.scss";
import { MdOutlineClose } from "react-icons/md";
import Backbutton from "../../Shared/BackButton/BackButton";

const TrackSubmissionFeedback = ({ onBack, onPopupClose }) => {
  return (
    <div className="trackSubmission">
      <div className="">
        <div className="trackSubmission-close-div" onClick={onPopupClose}>
          <MdOutlineClose />
        </div>
        <div className="trackSubmission-rectangle">
          <div className="trackSubmission-header Mainheader">
            Track Submission
          </div>
          <div className="trackSubmission-text sub-subtitle2">
            This is the information that our music supervisors can see when
            viewing your submission.
          </div>
          <div className="trackSubmission-content">
            <div className="trackSubmission-input">
              <div className="title2">Feedback Needed</div>
              <div className="Body">Answer goes here.</div>
            </div>
            <div className="trackSubmission-input">
              <div className="title2">
                Targets for track placement opportunities
              </div>
              <div className="Body">Answer goes here.</div>
            </div>
            <div className="trackSubmission-input">
              <div className="title2">
                Is there anything else you’d like us to know?
              </div>
              <div className="Body">Inputted text goes here.</div>
            </div>
          </div>
          <div className="trackSubmissionFeedback-Btn" onClick={onBack}>
            <Backbutton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackSubmissionFeedback;

import React, { useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import "./VideoCard.css";
import closeIcon from "../../../assets/Images/closeIcon.svg";

const VideoCard = ({ setShowVideoPopup, trackId, videoUrl }) => {
  const playerWrapperRef = useRef(null);

  useEffect(() => {
    const resizePlayerWrapper = () => {
      if (playerWrapperRef.current) {
        const width = playerWrapperRef.current.offsetWidth;
        playerWrapperRef.current.style.height = `${width * (9 / 16)}px`;
      }
    };

    resizePlayerWrapper();
    window.addEventListener("resize", resizePlayerWrapper);

    return () => {
      window.removeEventListener("resize", resizePlayerWrapper);
    };
  }, []);

  return (
    <Modal
      show={true}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="custom-modal video-player-modal"
      onHide={() => setShowVideoPopup(false)}
    >
      <div ref={playerWrapperRef} className="videoPlayerWrapper">
        <div className="close-button" onClick={() => setShowVideoPopup(false)}>
          <img src={closeIcon} alt="Close" />
        </div>
        <ReactPlayer
          playing={true}
          url={videoUrl}
          width="100%"
          height="100%"
          controls
          style={{ background: '#000' }}
        />
      </div>
    </Modal>
  );
};

export default VideoCard;

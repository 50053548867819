import api from "../api";
const BASE_URL = process.env.REACT_APP_API_URL;
//Function to Create subscriptions intent
export const getClientSecretApi = async (data) => {
  try {
    console.log(data, "data");
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${BASE_URL}/subscriptions/intent`,
      data: data,
    };
    const response = await api.request(config);
    return response.data;
  } catch (error) {
    console.error("error in getting pb key", error.messsage);
    throw error;
  }
};

import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_SINGLE_ARTIST_LOADING,
  GET_SINGLE_ARTIST_REQUEST,
} from '../actions/actionTypes';
import {
  getSingleAritstFailure,
  getSingleAritstSuccess,
} from '../actions/artistActions';
import { getSingleArtistApi } from '../../api/artists/getSingleArtist';

function* getSingleArtist(action) {
  try {
    const artistId = action.payload;
    yield put({ type: GET_SINGLE_ARTIST_LOADING });
    const result = yield call(getSingleArtistApi, artistId); 
    yield put(getSingleAritstSuccess(result));
  } catch (error) {
    yield put(getSingleAritstFailure(error.message));
  }
}
export function* getSingleArtistSaga() {
  yield takeLatest(GET_SINGLE_ARTIST_REQUEST, getSingleArtist);
}

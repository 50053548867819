import React, { useEffect, useState } from "react";
import "../../SignUpPage/SignUpPage.css";
import ProgressSteps from "../ProgressSteps/ProgressSteps";
import SMLLabel from "../../../components/Shared/SMLLabel/SMLLabel";
import SMLToggleButton from "../../../components/Shared/SMLToggleButton/SMLToggleButton";
import plans from "./ChoosePlan.json";
import bestValue from "../../../assets/Images/popular.svg";
import checkBox from "../../../assets/Images/checkboxIcon.svg";
import closeIcon from "../../../assets/Images/closeIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { getPlansRequest } from "../../../redux/actions";

const ChoosePlan = (props) => {
  const dispatch = useDispatch();
  const [selectedPlan, setSelectedPlan] = useState(props.planIndex); // Stores the selected plan index
  const [isYearly, setIsYearly] = useState(props.isYearly); // Toggles between yearly/monthly plans

  useEffect(() => {
    dispatch(getPlansRequest()); // Fetch available plans
  }, [dispatch]);

  // Fetch plans1 from Redux store
  const plans1 = useSelector((state) => state.payment.getPlansReducer?.plans);

  // Helper function to find the matching plan in plans1
  const findMatchingPlan = (plan) => {
    const priceId = isYearly ? plan.YearlyId : plan.monthlyId;
    return plans1.find((p1) => p1.id === priceId);
  };

  const handleCardClick = (index) => {
    setSelectedPlan(index);
    const selectedPlanData = findMatchingPlan(plans[index]);
    if (selectedPlanData) {
      console.log(selectedPlanData);
      props.onNextClick1(selectedPlanData, index, isYearly);
    } else {
      console.error("Selected plan not found in plans1");
    }
  };

  return (
    <div>
      <div className="signUp-progress-steps">
        {props.upgradePlan ? (
          <ProgressSteps step="0" upgrade="true" />
        ) : (
          <ProgressSteps step="0" />
        )}
      </div>
      <div className="signUp-close-div" onClick={props.closePopup}>
        <img src={closeIcon} style={{ cursor: "pointer" }} alt="Close" />
      </div>

      <div className="plan-form">
        <div className="signUp-label-div-plan">
          <SMLLabel
            value={`${props.upgradePlan ? "Upgrade Plan" : "Choose Plan"}`}
            style="Mainheader"
          />
        </div>
        <div
          className="toggle-buttons"
          style={{ top: "0px", position: "relative", bottom: "0px" }}
        >
          <label className="title2">Monthly</label>
          <SMLToggleButton
            value={isYearly}
            checked={isYearly}
            onChange={() => {
              setIsYearly(!isYearly);
              setSelectedPlan(null);
            }}
          />
          <label className="title2">Yearly</label>
          <span className="title2">Save 20% over monthly</span>
        </div>

        <div className="plan-cards">
          {plans.map((plan, index) => (
            <div
              key={index}
              className={`plan-card ${
                selectedPlan === index ? "selected-card" : ""
              }`}
              onClick={() => handleCardClick(index)}
            >
              {plan.bestValue && (
                <img
                  src={bestValue}
                  alt="Popular"
                  className="best-value-image"
                />
              )}
              <div className="plan-card-content">
                <h2 className="title1">{plan.name}</h2>
                <div className="price-container">
                  <p className="plan-price">
                    {isYearly ? plan.yearlyPrice : plan.price}
                    <span className="plan-duration">
                      {isYearly ? "/year" : "/month"}
                    </span>
                  </p>
                </div>
                <div className="horizontal-line"></div>
                <ul className="features-list">
                  {plan?.features.map((feature, i) => {
                    if (typeof feature === "string") {
                      return (
                        <li key={i} className="features-item">
                          <img
                            src={checkBox}
                            alt="checkbox"
                            className="checkbox-icon"
                          />
                          <span className="feature-text">{feature}</span>
                        </li>
                      );
                    } else if (typeof feature === "object") {
                      const [title, nestedFeatures] =
                        Object.entries(feature)[0];
                      return (
                        <React.Fragment key={i}>
                          <li className="features-item">
                            <img
                              src={checkBox}
                              alt="checkbox"
                              className="checkbox-icon"
                            />
                            <span className="feature-text">{title}</span>
                          </li>
                          {nestedFeatures.map((nestedFeature, j) => (
                            <li
                              key={`${i}-${j}`}
                              className="features-item nested-feature-item"
                            >
                              <span className="bullet-icon">•</span>
                              <span className="feature-text">
                                {nestedFeature}
                              </span>
                            </li>
                          ))}
                        </React.Fragment>
                      );
                    }
                  })}
                </ul>
              </div>

              <button className={`plan-button plan-button-${index}`}>
                {selectedPlan === index
                  ? "Selected Plan"
                  : props.upgradePlan
                  ? "Upgrade Plan"
                  : plan.button}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChoosePlan;

import { call, put, takeLatest } from "redux-saga/effects";
import {
  ASSIGN_SUPERVISOR_LOADING,
  ASSIGN_SUPERVISOR_REQUEST,
} from "../actions/actionTypes";
import {
  assignSuperVisorFailure,
  assignSuperVisorSuccess,
} from "../actions/assignSupervisorActions";
import { assignSupervisorApi } from "../../api/supervisor/assignSupervisorApi";

function* assignSupervisor(action) {
  try {
    const trackId = action.payload.trackId;
    const supId = action.payload.supervisor;
    yield put({ type: ASSIGN_SUPERVISOR_LOADING });
    const assigned = yield call(assignSupervisorApi, { trackId, supId });
    yield put(assignSuperVisorSuccess(assigned));
  } catch (error) {
    yield put(assignSuperVisorFailure(error.message));
  }
}

function* assignSupervisorSaga() {
  yield takeLatest(ASSIGN_SUPERVISOR_REQUEST, assignSupervisor);
}

export default assignSupervisorSaga;

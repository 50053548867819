import React, { useEffect, useRef, useState } from "react";
import "./parallaxHome.css";
import Navbar from "./Navbar/Navbar";
import MusicPartner from "./parallaxMusicCredits/MusicPartner";
import DynamicText from "./parallaxDynamic/parallxDynamic";
import ParallaxVideo from "./parallaxVideoPage/parallaxVideo";
import ParallaxCertified from "./parallaxGetCertified/parallaxCertified";
import UploadYourTracks from "./parallaxUploadTracks/uploadYourTracks";
import GetFeedbackExpert from "./parallaxGetFeedbackExpert/getFeedbackExpert";
import ParallaxRetain from "./parallaxRetain/ParallaxRetain";
import ParallaxPlan from "./planParallax/ParallaxPlan";
import ParallaxForm from "./parallaxForm/parallaxForm";
import ParallaxLogin from "./parallaxLogin/parallaxLogin";
import TawkChat from "../LiveChat/tawkChat";
// import { ReactLenis } from 'lenis/react'
import gsap from "gsap";
// import "lenis/dist/lenis.css";
import { useGSAP } from "@gsap/react";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import homeBg from "../../assets/Images/home-bg.svg"

gsap.registerPlugin(ScrollTrigger)

const Home = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  // const lenisRef = useRef()
  const contentRef = useRef()
  // Extract section mapping to a constant outside the component
  const SECTION_MAP = {
    about: "videoSection",
    howto: "uploadTracks",
    pricing: "pricing",
    form: "form-section",
    login: "login-section"
  };

  const onNavigate = (section) => {
    const element = document.getElementById(SECTION_MAP[section]);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Memoize scroll handler to prevent recreation on each render
  // const handleScroll = React.useCallback(() => {
  //   const scrolled = window.scrollY;
  //   const mainBackground = document.getElementById("main-background");
  //   if (mainBackground) {
  //     mainBackground.style.transform = `translateY(${scrolled * 0.5}px)`;
  //   }
  // }, []);

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, [handleScroll]);



  // useEffect(() => {
  //   function update(time) {
  //     lenisRef.current?.lenis?.raf(time * 1000)
  //   }

  //   gsap.ticker.add(update)

  //   return () => gsap.ticker.remove(update)
  // }, [])

  useGSAP(() => {
    gsap.fromTo("#introContainer", { scale: .98, }, {
      duration: 1,
      scale: 0.95,
      ease: "none",
      scrollTrigger: {
        trigger: "#introContainer",
        start: "top bottom-=20%",
        end: "center top",
        scrub: true,
        // markers: true
      }
    })

    const headingLines = document.querySelectorAll(".main-section_heading .heading-line")
    headingLines.forEach((line, index) => {
      gsap.to(line, {
        duration: 1,
        xPercent: index % 2 === 0 ? -80 : 80,
        ease: "none",
        scrollTrigger: {
          trigger: "#introContainer",
          start: "top bottom",
          end: "center center",
          scrub: true,
          // markers: true
        }
      })
    })

    gsap.to([".main-section_description", ".trial-button-container", ".scroll-container"], {
      duration: 1,
      yPercent: 80,
      stagger: 0.2,
      ease: "none",
      scrollTrigger: {
        trigger: "#introContainer",
        start: "top bottom",
        end: "center center",
        scrub: true,
        // markers: true
      }
    })

    // gsap.to("#hero-section", {
    //   duration: 1,
    //   opacity: 0,
    //   ease: "none",
    //   scrollTrigger: {
    //     trigger: "#introContainer",
    //     start: "top bottom",
    //     end: "center top",
    //     scrub: true,
    //     // markers: true
    //   }
    // })


    gsap.to(".hero-background", {
      duration: 1,
      ease: "none",
      opacity: 0,
      scrollTrigger: {
        trigger: ".hero-placeholder",
        start: "top top",
        end: "center top",
        scrub: true,
        // markers: true
      }
    })

    gsap.fromTo("#hero-section", { autoAlpha: 1 }, {
      duration: 1,
      autoAlpha: 0,
      ease: "none",
      scrollTrigger: {
        trigger: "#introContainer",
        start: "top center",
        end: "center center",
        scrub: true,
        // markers: true
      }
    })

    // Create a timeline for the "how it works" sections
    const sections = gsap.utils.toArray('.how-it-works-section-wrapper');

    // gsap.set(sections, {
    //   transformOrigin: "top",
    // })
    const target = sections[0].querySelectorAll('.how-it-works-section')

    sections.forEach((section, i) => {
      gsap.set(target[i], {
        xPercent: i === 0 ? 0 : 100,
      })
    })
    sections.forEach((section, i) => {
      gsap
        .to(target[i], {
          ease: 'none',
          xPercent: 0,
          scrollTrigger: {
            trigger: section,
            start: 'top center',
            end: "bottom bottom",
            scrub: true,
            // markers: true
          }
        })
    });

  }, { scope: contentRef })


  return (
    <div ref={contentRef} style={{ position: "relative" }}>
      <img className="home-bg" alt="home-bg" src={homeBg} />
      <Navbar
        onNavigate={onNavigate}
        isPopupOpen={isPopupOpen}
        setIsPopupOpen={setIsPopupOpen}
      />
      {/* <ReactLenis ref={lenisRef} options={{ autoRaf: true }} root> */}
      <section id="hero-section" className="hero-section">
        <div className="hero-background">
          <div className="noise-image" />
          <div className="effect-container">
            <div className="image-effect" />
          </div>
        </div>
        <div className="hero-content">
          <MusicPartner setIsPopupOpen={setIsPopupOpen} />
        </div>
        <div className="hero-placeholder"></div>
      </section>
      <div className="content-wrapper" >
        <div id="introContainer" className="intro-section">
          <div className="intro-wrapper">
            <div className="intro-content" />
            <DynamicText />

            <section id="videoSection" className="section">
              <ParallaxVideo />
            </section>
          </div>
        </div>


        <section style={{ position: "relative", overflowX: "clip" }}>
          <div className="how-it-works-section-wrapper">
            <div id="uploadTracks" className="how-it-works-section">
              <UploadYourTracks />
            </div>

            <div id="getFeedback" className="section-absolute how-it-works-section">
              <GetFeedbackExpert />
            </div>
            <div id="certified" className="section-absolute how-it-works-section">
              <ParallaxCertified />

            </div>
          </div>


          <div className="how-it-works-section-wrapper"></div>
          <div className="how-it-works-section-wrapper"></div>
        </section>

        <section id="retain" className="section" style={{ overflowX: "clip" }}>
          <ParallaxRetain />
        </section>
        <section id="pricing" className="section">
          <ParallaxPlan />
        </section>
        <div
          id="footer"
          className="landing-section"
          style={{
            transform: "translateY(0)",
          }}
        >
          <div className="footer-wrapper">
            <div
              className="footer-section"
              style={{
                height: "100dvh",
              }}
            >
              <ParallaxForm />
            </div>
            <div
              className="footer-section"
              style={{
                height: "100dvh",
              }}
            >
              <ParallaxLogin
                onNavigate={onNavigate}
                isPopupOpen={isPopupOpen}
                setIsPopupOpen={setIsPopupOpen}
              />
            </div>
          </div>
        </div>
      </div>
      {/* </ReactLenis> */}



      <TawkChat />
    </div>
  );
};

export default Home;
import React, { useEffect, useState } from "react";
import AboutYouComponent from "./AboutYou/AboutYou";
import ContactInformationComponent from "./ContactInformation/ContactInformation";
import WelcomeComponent from "./Welcome/Welcome";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ChoosePlan from "./ChoosePlan/ChoosePlan";
import PaymentPopup from "../../components/common/payment-popup/PaymentPopup";
import "./SignUpPage.css";
import Login from "../LoginPage/LoginPage";
import {
  RESET_CREATE_SUB,
  RESET_GET_CLIENT,
  RESET_PB_KEY,
} from "../../redux/actions/actionTypes";
function SignUpPage({
  onClose,
  selectedPlan,
  planIndex,
  fromHome,
  handleIndex,
  isYearly,
}) {
  const dispatch = useDispatch();
  const [currentComponent, setCurrentComponent] = useState("plan");
  const [contactInfo, setContactInfo] = useState({
    fullName: "",
    email: "",
    createPassword: "",
    verifyPassword: "",
    agreeConditions: "",
    profileType: "artist",
  });
  const [roleOptions, setRoleOptions] = useState([]);
  const [experienceWithSync, setExperienceWithSync] = useState(false);
  const [smlLeadInfo, setSmlLeadInfo] = useState("");
  const [personalLinks, setPersonalLinks] = useState([]);
  const [musicExperienceInfo, setMusicExperienceInfo] = useState("");
  const [guidanceNeededInfo, setGuidanceNeededInfo] = useState("");
  const [selectedPlanData, setSelectedPlanData] = useState(null); // State to store selected plan
  const [aboutYouData, setAboutYouData] = useState(null);
  const [index, setIndex] = useState();
  const [isYearlyPlan, setIsYearlyPlan] = useState(null);
  const [sendIndex, setSendIndex] = useState(null);
  const [isFromHome, setIsFromHome] = useState(fromHome);
  const [selectedName, setSelectedName] = useState("");
  const [showLogin, setShowLogin] = useState(false);
  const [membPlan, setMembPlan] = useState("");

  const handleRoleOptions = (roles) => {
    setRoleOptions(roles);
  };
  const handleExperienceWithSync = () => {
    setExperienceWithSync(!experienceWithSync);
  };
  const handleSmlLeadInfo = (data) => {
    setSmlLeadInfo(data);
  };
  const handlePersonalLinks = (links) => {
    setPersonalLinks(links);
  };
  const handleMusicExp = (data) => {
    setMusicExperienceInfo(data);
  };
  const handleGuidanceInfo = (data) => {
    setGuidanceNeededInfo(data);
  };
  useEffect(() => {}, [personalLinks]);
  const handleContactInformationClick = (data) => {
    setContactInfo(data);
    setCurrentComponent("about");
  };

  const handleAboutYouClick = (userData) => {
    dispatch({ type: RESET_PB_KEY });
    dispatch({ type: RESET_GET_CLIENT });
    dispatch({ type: RESET_CREATE_SUB });
    setCurrentComponent("payment");
  };

  const onBackClick = () => {
    setCurrentComponent("contact");
  };
  const onContactBackClick = (data) => {
    setIsFromHome(false);
    setContactInfo(data);
    setCurrentComponent("plan");
  };
  const onPaymentBackClick = () => {
    setCurrentComponent("about");
  };
  const handleContainerClick = (e) => {
    e.stopPropagation(); // Prevent click event from bubbling up to parent
  };
  const onNextClick = () => {
    setCurrentComponent("contact");
  };
  const onNextClick1 = (plan, index, isYearly) => {
    setSelectedPlanData(plan);
    if (index === 0) setMembPlan("basic");
    else if (index === 1) setMembPlan("pro");
    else if (index === 2) setMembPlan("sync_partner");
    setIndex(index);
    setIsYearlyPlan(isYearly);
    setCurrentComponent("contact");
  };
  const onNextClick2 = () => {
    setCurrentComponent("welcome");
  };
  useEffect(() => {
    if (isFromHome) {
      setCurrentComponent("contact");
    }
  }, [isFromHome]);
  useEffect(() => {
    if (isFromHome) {
      setSendIndex(planIndex);
      setIsYearlyPlan(isYearly);
    } else {
      setSendIndex(index);
      if (fromHome) {
        handleIndex(index, isYearlyPlan);
      }
    }
  }, [index, setIndex, planIndex, isYearlyPlan, setIsYearlyPlan]);
  const goToLogin = () => {
    setShowLogin(true);
  };
  return showLogin ? (
    <Login onClose={onClose} />
  ) : (
    <Modal
      show={true}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="custom-modal"
    >
      <div className="signUp-container">
        <div className="signup-card">
          <div onClick={handleContainerClick}>
            {currentComponent === "plan" && (
              <ChoosePlan
                onNextClick1={onNextClick1}
                isYearly={isYearlyPlan}
                closePopup={onClose}
                planIndex={sendIndex}
              />
            )}
            {currentComponent === "contact" && (
              <ContactInformationComponent
                contactData={contactInfo}
                onContactInformationClick={handleContactInformationClick}
                closePopup={onClose}
                handleBack={onContactBackClick}
                goToLogin={goToLogin}
              />
            )}
            {currentComponent === "about" && (
              <AboutYouComponent
                roleOptions={roleOptions}
                handleRoleOptions={handleRoleOptions}
                contactInfo={contactInfo}
                onAboutClick={handleAboutYouClick}
                onBackClick={onBackClick}
                closePopup={onClose}
                experienceWithSync={experienceWithSync}
                selectedName={selectedName}
                setSelectedName={setSelectedName}
                handleExperienceWithSync={handleExperienceWithSync}
                smlLeadInfo={smlLeadInfo}
                handleSmlLeadInfo={handleSmlLeadInfo}
                personalLinks={personalLinks}
                handlePersonalLinks={handlePersonalLinks}
                musicExperienceInfo={musicExperienceInfo}
                handleMusicExp={handleMusicExp}
                guidanceNeededInfo={guidanceNeededInfo}
                handleGuidanceInfo={handleGuidanceInfo}
                membPlan={membPlan}
              />
            )}
            {currentComponent === "payment" && (
              <PaymentPopup
                selectedPlan={selectedPlan || selectedPlanData}
                contactInfo={contactInfo}
                aboutYouData={aboutYouData}
                nextClick={onNextClick2}
                closePopup={onClose}
                backClick={onPaymentBackClick}
                isYearly={isYearlyPlan}
              />
            )}
            {currentComponent === "welcome" && (
              <WelcomeComponent
                onBackClick={onBackClick}
                closePopup={onClose}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default SignUpPage;

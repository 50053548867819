import React, { useEffect, useState } from "react";
import SMLLabel from "../../components/Shared/SMLLabel/SMLLabel";
import SMLButton from "../../components/Shared/SMLButton/SMLButton";
import { useDispatch, useSelector } from "react-redux";
import { postTrackRequest } from "../../redux/actions/uploadTrackActions";
import "../../styles/Fonts.scss";
import "../../pages/Feedback/feedback.css";
import "../../pages/LoginPage/LoginPage.css";
import closeIcon from "../../assets/Images/closeIcon.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Bars } from "react-loader-spinner";

const FeedbackStageFour = ({
  onFeedbackClick,
  onCloseTrack,
  audio,
  trackName,
  genreName,
  selectedRoles,
  workStatus,
  syncedBefore,
  samplesUsed,
  sampleSources,
  feedbackOptions,
  targetOptions,
  additionalInfo,
}) => {
  const dispatch = useDispatch();
  const [loginError, setLoginError] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const trackState = useSelector((state) => state.uploadTrack);

  useEffect(() => {
    // Check if tracks exist and not in loading or error state
    if (trackState.tracks && !trackState.isLoading && !trackState.isError) {
      // Show loader for 3 seconds before triggering onFeedbackClick
      setShowLoading(true);
      const timer = setTimeout(() => {
        onFeedbackClick();
        setShowLoading(false); // Hide loader after 3 seconds
      }, 3000); // 3-second delay

      return () => clearTimeout(timer); // Cleanup on component unmount or effect re-run
    }
  }, [
    trackState.tracks,
    trackState.isLoading,
    trackState.isError,
    onFeedbackClick,
  ]);

  useEffect(() => {
    if (trackState.error) setLoginError(true);
  }, [trackState.error]);

  const handleSubmitTrack = () => {
    setShowLoading(true); // Show loading immediately on submit
    dispatch(
      postTrackRequest(
        audio,
        trackName,
        genreName,
        selectedRoles,
        workStatus,
        syncedBefore,
        samplesUsed,
        sampleSources,
        feedbackOptions,
        targetOptions,
        additionalInfo
      )
    );
  };

  return (
    <div className="feedback-container">
      <div className="feedback-card">
        <div className="feedback-close-div">
          <img src={closeIcon} onClick={onCloseTrack} alt="Close" />
        </div>
        <Row className="justify-content-center align-items-center h-100">
          <Col xs={12} md={12}>
            <div className="text-center">
              <div className="feedback-groupby-bottom-margin">
                <SMLLabel
                  value="Submit Track For Evaluation?"
                  style="Mainheader"
                />
              </div>
              <div className="feedback-groupby-middel-btn">
                <SMLButton
                  style="SMLButton2 SMLButton-Orange"
                  value="Submit Track"
                  handleClickEvent={handleSubmitTrack}
                />
              </div>
              {showLoading && (
                <div className="spinner-overlay">
                  <Bars
                    height="80"
                    width="80"
                    color="#23f0c7"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              )}
              <div className="feedback-groupby-middel-btn">
                <SMLButton
                  style="SMLButton3"
                  valueStyle="Button2"
                  value="Cancel"
                  handleClickEvent={onCloseTrack}
                />
              </div>
              {loginError && (
                <span className="SMLError">{trackState.error}</span>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default FeedbackStageFour;

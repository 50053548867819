import React from "react";
import "./ParallaxRetain.css";
import apps from "../../../assets/Images/apps.svg";

const ParallaxRetain = () => {
  return (
    <div className="banner" style={{ overflowX: "clip", maxWidth: "100%" }}>
      <div className="text-container">
        <h1>
          Take the sync industry by storm with our creative & strategic
          approach!
        </h1>
        <p>
          Our network of producers and music supervisors span all forms of media
          including these major players and many more.
        </p>
      </div>
      <div className="scroll-container">
        <div className="logos">
          <img src={apps} alt="frame" />
          <img src={apps} alt="frame" />
          <img src={apps} alt="frame" />
          <img src={apps} alt="frame" />
          <img src={apps} alt="frame" />
          <img src={apps} alt="frame" />
          <img src={apps} alt="frame" />
          <img src={apps} alt="frame" />
          <img src={apps} alt="frame" />
          <img src={apps} alt="frame" />
          <img src={apps} alt="frame" />
          <img src={apps} alt="frame" />
        </div>
      </div>
    </div>
  );
};

export default ParallaxRetain;

import api from "../api";
const BASE_URL = process.env.REACT_APP_API_URL;
// Function to create subscriptions
export const cancelSubApi = async (data) => {
  try {
    console.log(data);
    const accessToken = localStorage.getItem("token");
    if (!accessToken) {
      console.error("No token found. Please log in.");
      return;
    }
    const url = `${BASE_URL}/subscriptions/${data.subscriptionId}`;
    let config = {
      maxBodyLength: Infinity,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };
    // const response = await api.delete(url, config);
    // return response.data;
  } catch (error) {
    console.error("error in getting pb key", error.messsage);
    throw error;
  }
};

import {
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SEARCH_FAILURE,
  SEARCH_LOADING,
  RESET_SEARCH,
  SEARCH_RESPONSE_SUCCESS,
  SET_SEARCH_TEXT,
} from "../actions/actionTypes";

const initialState = {
  searchedResponse: {
    pagination: [],
    isLoading: false,
    isError: false,
    error: undefined,
  },
  searchedResult: {
    result: [],
    isLoading: false,
    isError: false,
    error: undefined,
  },
  searchText: {
    text: "",
    isLoading: false,
    isError: false,
    error: undefined,
  },
};

export const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_TEXT:
      return {
        ...state,
        searchText: {
          searchText: action.payload,
          isLoading: false,
          isError: false,
          error: undefined,
        },
      };
    case SEARCH_LOADING:
      return {
        ...state,
        searchedResult: {
          ...state.searchedResult,
          isLoading: true,
          isError: false,
          error: undefined,
        },
      };
    case SEARCH_RESPONSE_SUCCESS:
      return {
        ...state,
        searchedResponse: {
          pagination: action.pagination,
          isLoading: false,
          isError: false,
          error: undefined,
        },
      };
    case SEARCH_SUCCESS:
      console.log(state.searchedResult.result, action.result, "from reducer");
      return {
        ...state,
        searchedResult: {
          ...state.searchedResult,
          result: [...state.searchedResult.result, ...action.result],
          isLoading: false,
          isError: false,
          error: undefined,
        },
      };
    case SEARCH_FAILURE:
      return {
        ...state,
        searchedResult: {
          result: [],
          isLoading: false,
          isError: true,
          error: action.error,
        },
      };
    case RESET_SEARCH:
      return {
        searchText: initialState.searchText,
        searchedResult: initialState.searchedResult,
      };
    default:
      return state;
  }
};

import React from "react";
import "../../../styles/Fonts.scss";
import "../../../styles/HTMLControls.scss";
import "./SMLRadioButton.css";

const SMLRadioButton = ({ id, label, value, checked, onChange }) => {
  return (
    <div>
      <input
        type="radio"
        id={id}
        value={value}
        checked={checked}
        onChange={onChange}
        className="SMLRadioButton"
      />
      <label className="radiobtn-lbl title2" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default SMLRadioButton;

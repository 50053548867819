import api from "../api";

const setAuthHeader = () => {
  const token = localStorage.getItem("token");
  if (token) {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
};

export const getVideoSignedUrl = async (payload) => {
  setAuthHeader();
  const { track_id, file_name, file_type } = payload;

  const response = await api.post(
    `/feedback/tracks/${track_id}/video/signed-url`,
    {
      file_name,
      file_type,
    }
  );
  return response.data;
};

export const getVideoUrl = async (feedbackId) => {
  setAuthHeader();
  const response = await api.get(`/feedback/${feedbackId}/video`);
  return response.data;
};

export const deleteVideo = async (feedbackId) => {
  console.log("deleting");
  setAuthHeader();
  const response = await api.delete(`/feedback/${feedbackId}/video`);
  return response.data;
};

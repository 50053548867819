import axios from "axios";
const BASE_URL = process.env.REACT_APP_API_URL;
// function to soical login 
export const socialLogin = async ({ token, provider, profile_type }) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/social`, {
      token,
      provider,
      profile_type,
    });
    const accessToken = response.data.access_token;
    const expiresIn = `${response.data.expires_in}`;
    const isMaster = response.data.profile.is_master
    localStorage.setItem("token", accessToken);
    const expirationTime = new Date().getTime() + expiresIn * 1000;
    localStorage.setItem("token_expiration", expirationTime);
    localStorage.setItem("profile_type", response.data.profile_type);
    localStorage.setItem("isMaster", isMaster)
    return response.data;
  } catch (error) {
    console.error("Error logging in:", error);
    throw error;
  }
};
export const socialSignup = async ({
  token,
  provider,
  profile_type,
  profile,
}) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/social`, {
      token,
      provider,
      profile_type,
      profile,
    });
    if(!response.is_new_user)
    {
      const accessToken = response.data.access_token;
      const expiresIn = `${response.data.expires_in}`;
      localStorage.setItem("token", accessToken);
      const expirationTime = new Date().getTime() + expiresIn * 1000;
      localStorage.setItem("token_expiration", expirationTime);
      localStorage.setItem("profile_type", response.data.profile_type);
    }

    return response.data;
  } catch (error) {
    console.error("Error logging in:", error);
    throw error;
  }
};

import React from "react";
import clsx from "clsx";
import Styles from "./StyledComponent.module.css";
export default function SMLCard({ children, className, style, onClick }) {
  return (
    <div className={clsx(className, Styles.card)} onClick={onClick}>
      {children}
    </div>
  );
}

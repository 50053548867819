import React from "react";
import ReactPlayer from "react-player";
import introURL from "../../../assets/video/ShannonIntro12.mp4";
import "./SMLVideoPlayer.css";

function SMLVideoPlayer() {
  const url =
    "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4";
  return (
    <div className="video-player-wrapper">
      <ReactPlayer
        url={introURL}
        controls
        width="100%"
        height="100%"
        className="react-player"
      />
    </div>
  );
}

export default SMLVideoPlayer;

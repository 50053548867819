import { call, put, take, takeLatest, all, fork } from 'redux-saga/effects';
import {
  WEBSOCKET_REQUEST,
  WEBSOCKET_SUCCESS,
  WEBSOCKET_LOADING,
  WEBSOCKET_ERROR,
} from '../actions/actionTypes';
import WebsocketApi from '../../api/websocket/websocketApi';

function* webSocketRequest() {
  yield put({ type: WEBSOCKET_LOADING });
  const token = localStorage.getItem('token');
  const endpoint = `${process.env.REACT_APP_WS_URL}?token=${token}`;
  
  // Create WebSocket channel
  const socketChannel = yield call(WebsocketApi, endpoint);
  
  try {
    while (true) {
      const message = yield take(socketChannel); // Wait for messages from the WebSocket
      yield put({ type: WEBSOCKET_SUCCESS, payload: message });
    }
  } catch (error) {
    yield put({ type: WEBSOCKET_ERROR, payload: error.message });
  } finally {
    socketChannel.close(); // Ensure the channel is closed when the saga ends
  }
}

export default function* websocketSaga() {
  yield all([takeLatest(WEBSOCKET_REQUEST, webSocketRequest)]);
}

import {
  DOWNLOAD_TRACK_FAILURE,
  DOWNLOAD_TRACK_LOADING,
  DOWNLOAD_TRACK_REQUEST,
  DOWNLOAD_TRACK_SUCCESS,
} from './actionTypes';

export const downloadTrackRequest = (trackId,forDownload) => {
  return {
    type: DOWNLOAD_TRACK_REQUEST,
    payload: {trackId,forDownload}
  };
};
export const downloadTrackSuccess = (downloadLink) => {
  return {
    type: DOWNLOAD_TRACK_SUCCESS,
    payload: downloadLink,
  };
};
export const downloadTrackFailure = (error) => {
  return {
    type: DOWNLOAD_TRACK_FAILURE,
    payload: { ...error },
  };
};
export const downloadTrackLoading = () => {
  return {
    type: DOWNLOAD_TRACK_LOADING,
  };
};

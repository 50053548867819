// redux/reducers/authReducer.js
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  SOCIAL_LOGIN_REQUEST,
  SOCIAL_LOGIN_SUCCESS,
  SOCIAL_LOGIN_FAILURE,
  SUP_SIGNUP_REQUEST,
  SUP_SIGNUP_SUCCESS,
  SUP_SIGNUP_FAILURE,
  SOCIAL_SIGNUP_REQUEST,
  SOCIAL_SIGNUP_SUCCESS,
  SOCIAL_SIGNUP_FAILURE,
  ARTIST_SIGNUP_REMOVE,
  ARTIST_SHOW_LOGIN,
  SUP_SIGNUP_REMOVE,
  SUP_SHOW_LOGIN,
  SUP_SOCIAL_SIGNUP_REQUEST,
  SUP_SOCIAL_SIGNUP_SUCCESS,
  SUP_SOCIAL_SIGNUP_FAILURE,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_REMOVE,
} from "../actions/actionTypes"; 

const initialState = {
  user: null,
  artistId: null,
  loading: false,
  error: null,
  artistSocialSignedIn: false,
  artistSignedUp: false,
  artistShowLogin: false,
  artistSignupData: null,
  supSocialSignedIn: false,
  supSignedUp: false,
  supShowLogin: false,
  supSignupData: null,
  token_refreshed: false,
  resetPassword: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT:
      return {
        user: null,
        artistId: null,
        loading: false,
        error: null,
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        artistId: action.payload.artistId,
        loading: false,
        error: null,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        user: null,
        artistId: null,
        error: null,
        loading: false,
      };

    case SUP_SIGNUP_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUP_SIGNUP_SUCCESS:
      return {
        ...state,
        supSignedUp: true,
        loading: false,
        error: null,
      };
    case SUP_SIGNUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SIGNUP_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        artistSignedUp: action.user,
        loading: false,
        error: null,
      };
    case SIGNUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ARTIST_SIGNUP_REMOVE:
      return {
        ...state,
        artistSocialSignedIn: false,
        artistSignedUp: false,
        artistShowLogin: false,
        user: null,
      };
    case ARTIST_SHOW_LOGIN:
      return {
        ...state,
        artistShowLogin: true,
      };
    case SUP_SIGNUP_REMOVE:
      return {
        ...state,
        supSocialSignedIn: false,
        supSignedUp: false,
        supShowLogin: false,
        user: null,
      };
    case SUP_SHOW_LOGIN:
      return {
        ...state,
        supShowLogin: true,
      };
    case SOCIAL_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SOCIAL_LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
        error: null,
      };
    case SOCIAL_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SOCIAL_SIGNUP_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SOCIAL_SIGNUP_SUCCESS:
      return {
        ...state,
        artistSocialSignedIn: true,
        loading: false,
        error: null,
        artistSignedUp: action.payload,
      };
    case SOCIAL_SIGNUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SUP_SOCIAL_SIGNUP_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUP_SOCIAL_SIGNUP_SUCCESS:
      return {
        ...state,
        supSocialSignedIn: true,
        loading: false,
        error: null,
        supSignupData: action.payload,
      };
    case SUP_SOCIAL_SIGNUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case REFRESH_TOKEN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        token_refreshed: true,
        loading: false,
        error: null,
      };
    case REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        token_refreshed: false,
        loading: false,
        error: action.payload,
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        resetPassword:false,
        error: null,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: true,
        loading: false,
        error: null,
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        token_refreshed: false,
        loading: false,
        error: action.payload,
      };
    case RESET_PASSWORD_REMOVE:
      return {
        ...state,
        resetPassword: false,
      };
    default:
      return state;
  }
};

export default authReducer;

import SMLLabel from "../../components/Shared/SMLLabel/SMLLabel";
import "../../styles/Fonts.scss";
import SMLButton from "../../components/Shared/SMLButton/SMLButton";
import "../../pages/Feedback/feedback.css";
import "../../pages/LoginPage/LoginPage.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import closeIcon from "../../assets/Images/closeIcon.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import successIcon from "../../assets/Images/SuccessCheckmark.svg";
import { useDispatch, useSelector } from "react-redux";
import { resetUploadTrack } from "../../redux/actions/uploadTrackActions";

const FeedbackStageFive = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const trackId = useSelector((state) => state.uploadTrack?.tracks._id);
  const handleClick = (e) => {
    e.preventDefault();
  };

  const handleViewSubmissionClick = () => {
    navigate(`/TrackDetails/${trackId}`, {
      state: { fromUserPortal: true, status: "submitted" },
    });
  };

  const handleDashboard = () => {
    dispatch(resetUploadTrack());
    props.onCloseTrack();
  };

  const onCloseViewSubmission = () => {
    dispatch(resetUploadTrack());
    props.onCloseTrack();
  };
  return (
    <div className="feedback-container">
      <div className="feedback-card">
        <div className="feedback-close-div" onClick={onCloseViewSubmission}>
          <img src={closeIcon} style={{ cursor: "pointer" }} alt="Close icon" />
        </div>
        <Row className="justify-content-center align-items-center h-100">
          <Col xs={12} md={8}>
            <div className="text-center">
              <form onSubmit={handleClick}>
                <div className="feedback-groupby-bottom-margin">
                  <img src={successIcon}></img>
                </div>
                <div className="feedback-groupby-bottom-margin">
                  <SMLLabel
                    value="Your Track Has Been Submitted"
                    style="Mainheader"
                  />
                  <SMLLabel
                    value="Hang tight,you will receive expert feedback from our music supervisors."
                    style="title"
                  ></SMLLabel>
                </div>
                <div className="feedback-groupby-middel-btn">
                  <SMLButton
                    style="SMLButton2 SMLButton-Orange"
                    value="View Submission"
                    handleClickEvent={handleViewSubmissionClick}
                  />
                </div>
                <div className="feedback-groupby-middel-btn">
                  <SMLButton
                    style="SMLButton3 "
                    valueStyle="Button2"
                    value="Dashboard"
                    handleClickEvent={handleDashboard}
                  />
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default FeedbackStageFive;

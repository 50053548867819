import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SMLLabel from "../../components/Shared/SMLLabel/SMLLabel";
import "../../styles/Fonts.scss";
import SMLTextBox from "../../components/Shared/SMLTextBox/SMLTextBox";
import SMLButton from "../../components/Shared/SMLButton/SMLButton";
import SMLButtonWithIcon from "../../components/Shared/SMLButtonWithIcon/SMLButtonWithIcon";
import SMLLinkButton from "../../components/Shared/SMLLinkButton/SMLLinkButton";
import { BiQuestionMark } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import {
  loginRequest,
  socialLoginRequest,
  supSignupRemove,
} from "../../redux/actions/authActions";
import closeIcon from "../../assets/Images/closeIcon.svg";
import "../SupervisorLogin/SupervisorLogin.css";
import SMLErrorBox from "../../components/Shared/SMLErrorBox/SMLErrorBox";
import { Modal } from "react-bootstrap";
import { Bars } from "react-loader-spinner";
import SupervisiorPage from "../SupervisorPage";
import { GoogleLogin } from "@react-oauth/google";
import ForgetPassword from "../../components/common/forget-password/ForgetPassword";
import { websocketConnect } from "../../redux/actions/websocketActions";
import { RESET_FORGET_PASSWORD } from "../../redux/actions/actionTypes";

const SupervisionLogin = ({}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [signUpShow, setSignUpShow] = useState(false);
  const [showForgetPass, setShowForgetPass] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile_type = "supervisor";
  const { user, loading, error } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user && user.profile_type === "supervisor") {
      console.log("user.name", user.profile.name);
      localStorage.setItem("username", user.profile.name);
      localStorage.setItem("emails", user.profile.email);
      dispatch(websocketConnect());
      navigate("/supervisorDashboard", { state: { username: user.name } });
    }
  }, [user, navigate]);
  const handleGoogleLogin = (credentialResponse) => {
    dispatch(
      socialLoginRequest(credentialResponse.credential, "google", "supervisor")
    );
  };
  useEffect(() => {
    if (error) setLoginError(true);
  }, [error]);

  useEffect(() => {
    setLoginError(false);
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();

    // Validate email
    if (!email.trim()) {
      setEmailError("Email is required");
      return;
    } else {
      setEmailError("");
    }

    // Validate password
    if (!password.trim()) {
      setPasswordError("Password is required");
      return;
    } else {
      setPasswordError("");
    }

    if (email.toLowerCase() == "sml" && password.toLowerCase() == "sml") {
      navigate("/supervisorDashboard");
    }

    if (error === "Login failed") setLoginError(true);
    else setLoginError(false);

    dispatch(loginRequest(email, password, profile_type));
  };
  const onClose = () => {
    navigate("/");
  };
  const handleSignUpClick = () => {
    dispatch(supSignupRemove());
    setSignUpShow(true);
  };
  const handleForgetPassClick = (e) => {
    dispatch({ type: RESET_FORGET_PASSWORD });
    setShowForgetPass(true);
  };

  return showForgetPass ? (
    <ForgetPassword onClose={onClose} />
  ) : signUpShow ? (
    navigate("/supervisor/signup")
  ) : (
    <Modal show={true} className="custom-login-modal" centered>
      <div className="superVisor-login-container effects-container content">
        <div className="superVisor-login-card">
          {/* <div
            className="superVisor-Login-close-div"
            onClick={() => {
              navigate("/");
            }}
          >
            <img src={closeIcon} style={{ cursor: "pointer" }} alt="Close" />
          </div> */}
          <div className="superVisor-login-main">
            <div className="superVisor-login-label-div">
              <SMLLabel value=" Supervisor Log In" style="Mainheader sup" />
            </div>
            <form
              className="login-content"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleLogin(e);
                }
              }}
            >
              {loading && (
                <div className="spinner-overlay">
                  <Bars
                    height="80"
                    width="80"
                    color="#23f0c7"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              )}
              <div className="superVisor-login-controls">
                <div className="superVisor-login-fields">
                  {loginError && <SMLErrorBox message={error}></SMLErrorBox>}
                  <SMLLabel value="Email" style="title2"></SMLLabel>
                  <SMLTextBox
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {emailError && (
                    <span className="button1 error">{emailError}</span>
                  )}
                </div>

                <div className="superVisor-login-fields">
                  <div className="superVisor-login-groupby1">
                    <label className="title2">Password</label>
                    <SMLLinkButton
                      handleClickEvent={handleForgetPassClick}
                      value={
                        <span className="forgotPassword">
                          Forgot Password
                          <BiQuestionMark className="questionMark" />
                        </span>
                      }
                    ></SMLLinkButton>
                  </div>
                  <div className="passwordfield">
                    <SMLTextBox
                      value={password}
                      type={showPassword ? "text" : "password"}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span
                      className="toggle-password-visibility-login"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                    </span>
                  </div>
                  {passwordError && (
                    <span className="button1 error">{passwordError}</span>
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <input
                      style={{
                        height: "14px",
                        width: "14px",
                        cursor: "pointer",
                      }}
                      type="checkbox"
                      id="Remember"
                    ></input>
                    <label
                      className="forgotPassword sub-subtitle3"
                      htmlFor="Remember"
                    >
                      Remember Me
                    </label>
                  </div>
                </div>

                {/* END */}
              </div>
              <div className="superVisor-login-controls1">
                <div className="superVisor-login-fields1">
                  <SMLButton
                    style="SMLButton1"
                    value="Log In"
                    handleClickEvent={handleLogin}
                  />
                  <div className="superVisor-login-groupby">
                    <div className="div-line"></div>
                    <p>or</p>
                    <div className="div-line"></div>
                  </div>
                </div>

                <div className="login-fields1">
                  <div className="login-groupbyBtns google">
                    <div>
                      <GoogleLogin
                        onSuccess={(codeResponse) => {
                          handleGoogleLogin(codeResponse);
                        }}
                        onError={() => console.log("Login Failed")}
                        setOneTap={false}
                        text="signup_with"
                        size="large"
                        width="209"
                      />
                    </div>
                    <SMLButtonWithIcon
                      type="button"
                      value="Sign up with  Facebook"
                      icon="Facebook"
                    />
                  </div>

                  <div className="supervisor-login-card-end">
                    <div className="supervisor-login-groupbyBtns sub-subtitle3">
                      <span style={{ cursor: "pointer" }}>Not a member?</span>

                      <button
                        className="btn-no-margin button1 sub-subtitle3"
                        type="button"
                        onClick={handleSignUpClick}
                      >
                        Sign up
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SupervisionLogin;

import React, { useState } from "react";
import TrackCard from "./TrackCard";
import Styles from "./TracksViewStyles.module.css";

export default function TracksViewList({ tracks, tracksRef }) {
  const [currentPlayingIndex, setCurrentPlayingIndex] = useState();

  const onTrackPlay = (index) => {
    setCurrentPlayingIndex(index);
  };
  return (
    <div className={Styles.trackListContainer} ref={tracksRef}>
      <>
        {tracks?.map((item, index) => {
          return (
            <TrackCard
              key={index}
              track={item}
              onPlay={() => onTrackPlay(index)}
              isStopped={currentPlayingIndex !== index}
            />
          );
        })}
      </>
    </div>
  );
}

import React, { useState } from "react";
import "./CustomTimePicker.css";

const CustomTimePicker = ({ time, setTime }) => {
  const [focusPart, setFocusPart] = useState("minutes"); // tracks focused part

  const handleChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    let formattedTime = "00:00";

    if (value.length > 0) {
      const minutes = value.slice(0, 2).padStart(2, "0");
      const seconds = value.slice(2, 4).padStart(2, "0");
      formattedTime = `${minutes}:${seconds}`;
    }

    setTime(formattedTime);
  };

  const handleFocus = (part) => {
    setFocusPart(part);
  };

  const handleKeyDown = (e) => {
    if (focusPart === "minutes" && e.key >= "0" && e.key <= "9") {
      const newMinutes = time.slice(0, 2) + e.key;
      const newSeconds = time.slice(3);
      setTime(`${newMinutes.slice(-2)}:${newSeconds}`);
    } else if (focusPart === "seconds" && e.key >= "0" && e.key <= "9") {
      const newSeconds = time.slice(3, 5) + e.key;
      const newMinutes = time.slice(0, 2);
      setTime(`${newMinutes}:${newSeconds.slice(-2)}`);
    }
  };

  const handleScroll = (e) => {
    e.preventDefault();
    const increment = e.deltaY < 0 ? 1 : -1; // Scroll up increases, down decreases
    const currentSeconds = parseInt(time.slice(3, 5), 10);
    const currentMinutes = parseInt(time.slice(0, 2), 10);

    let newSeconds = currentSeconds + increment;
    let newMinutes = currentMinutes;

    // Handle overflow for seconds
    if (newSeconds < 0) {
      newSeconds = 59; // Wrap around to 59 seconds
      newMinutes = Math.max(0, currentMinutes - 1); // Decrement minutes, but not below 0
    } else if (newSeconds > 59) {
      newSeconds = 0; // Reset seconds to 0
      newMinutes = Math.min(59, currentMinutes + 1); // Increment minutes, but not above 59
    }

    setTime(
      `${newMinutes.toString().padStart(2, "0")}:${newSeconds
        .toString()
        .padStart(2, "0")}`
    );
  };

  return (
    <input
      value={time || "00:00"}
      onChange={handleChange}
      onFocus={() => handleFocus("minutes")}
      onBlur={() => setFocusPart("")}
      onKeyDown={handleKeyDown}
      className="custom-time-picker"
      maxLength={5} // Limit input length to "MM:SS"
      placeholder="MM:SS"
      onClick={(e) => {
        const cursorPosition = e.target.selectionStart;
        if (cursorPosition < 3) {
          handleFocus("minutes");
        } else {
          handleFocus("seconds");
        }
      }}
      onMouseDown={(e) => {
        const cursorPosition = e.target.selectionStart;
        if (cursorPosition < 3) {
          handleFocus("minutes");
        } else {
          handleFocus("seconds");
        }
      }}
      onWheel={handleScroll} // Added scroll functionality
    />
  );
};

export default CustomTimePicker;

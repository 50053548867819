import { FORGET_FINAL_PASSWORD_REQUEST, FORGET_PASSWORD_REQUEST } from "./actionTypes";

export const forgetPasswordRequest = (email) => {
  return {
    type: FORGET_PASSWORD_REQUEST,
    payload: email,
  };
};

export const forgetPasswordFinalRequest = ({email, code, password}) => {
  return {
    type : FORGET_FINAL_PASSWORD_REQUEST,
    payload : {email, code, password}
  }
}
import {
  ASSIGN_SUPERVISOR_FAILURE,
  ASSIGN_SUPERVISOR_LOADING,
  ASSIGN_SUPERVISOR_SUCCESS,
  RESET_ASSIGNED_SUPERVISOR,
} from "../actions/actionTypes";

const initialState = {
  isError: false,
  isLoading: false,
  assigned: [],
  error: undefined,
};

export const assignSupervisorReducer = (state = initialState, action) => {
  switch (action.type) {
    case ASSIGN_SUPERVISOR_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: undefined,
      };
    case ASSIGN_SUPERVISOR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        assigned: action.payload, 
        error: undefined,
      };
    case ASSIGN_SUPERVISOR_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case RESET_ASSIGNED_SUPERVISOR:
      return {
        ...state,
        isLoading: false,
        isError: false,
        error: undefined,
        assigned: [],
      };
    default:
      return state;
  }
};

import React from "react";
import "../../../styles/Fonts.scss";
import "../../../styles/HTMLControls.scss";

const SMLCheckbox = (props) => {
  return (
    <div style={{ display: "flex" }}>
      <input
        type="checkbox"
        checked={props.checked}
        onChange={props.onChange}
        className="SMLCheckbox"
      />
      <label>{props.label}</label>
    </div>
  );
};

export default SMLCheckbox;

import * as types from "../actions/actionTypes";

const initialState = {
  signedUrlData: null,
  isLoading: false,
  error: null,
};

const videoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_VIDEO_URL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        signedUrlData: action.payload,
      };

    case types.GET_VIDEO_SIGNED_URL_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case types.GET_VIDEO_SIGNED_URL_SUCCESS:
      return {
        ...state,
        signedUrlData: action.payload,
        isLoading: false,
        error: null,
      };

    case types.GET_VIDEO_SIGNED_URL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        signedUrlData: null,
      };

    case types.RESET_VIDEO_URL:
      return {
        ...initialState,
        signedUrlData: null,
        isLoading: false,
        error: null
      };

    case types.UPLOAD_VIDEO_LOADING:
      return {
        ...state,
        isLoading: true,
        error: null
      };

    case types.UPLOAD_VIDEO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null
      };

    case types.UPLOAD_VIDEO_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default videoReducer;

import { React, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import "./parallaxForm.css";
import "../../../styles/Fonts.scss";
import SMLTextArea from "../../Shared/SMLTextArea/SMLTextArea";
import SMLLabel from "../../Shared/SMLLabel/SMLLabel";
import SMLTextBox from "../../Shared/SMLTextBox/SMLTextBox";

const ParallaxForm = () => {
  const formRef = useRef(null);

  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.fromTo(
      [".Parallax-Question-content", ".Parallax-Question-description", ".Parallax-Form"],
      {
        opacity: 0,
        y: -40,
      },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        stagger: 0.2,
        scrollTrigger: {
          trigger: formRef.current,
          start: "top center+=100",
          toggleActions: "play none none reverse"
        }
      }
    );
  }, { scope: formRef }); // scope the animation to the form element

  const handleLogin = () => { };

  return (
    <div className="Parallax" onClick={handleLogin}>
      <div className="Parallax-Form-container" ref={formRef}>
        <div className="Parallax-Form-content">
          <div className="Parallax-Question-content">GOT A QUESTION?</div>
          <div className="Body Parallax-Question-description">
            Send us a message and we will respond shortly.
          </div>
        </div>
        <div className="Parallax-Form">
          <div className="Parallax-Form-Contents">
            <SMLLabel value="Your name" />
            <SMLTextBox />
          </div>
          <div className="Parallax-Form-Content">
            <SMLLabel value="Your Email" />
            <SMLTextBox />
          </div>
          <div className="Parallax-Form-Content">
            <SMLLabel value="Your Message" />
            <SMLTextArea />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParallaxForm;

import axios from 'axios';
import api from '../api';

const BASE_URL = process.env.REACT_APP_API_URL;

export const videoConsultationApi = async (trackId) => {
  try {
    const accessToken = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
    };
    const response = await api.post(
      `${BASE_URL}/video-consultation/${trackId}`,
      config
    );

    return response.data;
  } catch (error) {
    console.error(
      "Error creating video consultation request",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};
import React, { useState } from "react";
import Styles from "./TrackDetails.module.css";
import TrackPlayer from "./TrackPlayer";
import TrackDetailsHeader from "./TrackDetailsHeader";
import TrackDetailsNavigation from "./TrackDetailsNaviagtion";
import UnderReviewIcon from "../../../assets/Icons/under-review.svg";

export default function TrackDetailsForFeedback() {
  const [track, setTrack] = useState({
    id: 1,
    name: "Track Name",
    length: "0.00",
    genre: "Alternative",
    status: "Feedback Received",
  });

  return (
    <div className={Styles.trackDetailsContainer}>
      <TrackContentMain />
      <TrackPlayer track={track} />
    </div>
  );
}

const TrackContentMain = () => {
  return (
    <div className={Styles.trackContentMain}>
      <TrackDetailsNavigation />
      <TrackDetailsHeader />
    </div>
  );
};

import {
    GET_SUPERVISOR_FAILURE,
    GET_SUPERVISOR_LOADING,
    GET_SUPERVISOR_SUCCESS,
  } from "../actions/actionTypes";
  
  export const supervisorReducer = (
    state = {
      supervisor: {
        isLoading: false,
        supervisors: [],
        isError: false,
        error: undefined,
      },
    },
    action
  ) => {
    switch (action.type) {
      case GET_SUPERVISOR_LOADING:
        return {
          ...state,
          supervisor: {
            supervisors: [],
            isLoading: true,
            isError: false,  
            error: undefined,
          },
        };
      case GET_SUPERVISOR_SUCCESS:
        return {
          ...state,
          supervisor: {
            supervisors: action.supervisors, 
            isLoading: false,
            isError: false,
            error: undefined,
          },
        };
      case GET_SUPERVISOR_FAILURE:
        return {
          ...state,
          supervisor: {
            supervisors: [],
            isLoading: false,
            isError: true,
            error: action.error, 
          },
        };
      default:
        return state;
    }
  };
  export default supervisorReducer;
  
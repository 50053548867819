import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import "./RateBar.css";

const rate = [
  {
    value: 0,
    label: "N/A",
  },
  {
    value: 10,
    label: "10",
  },
];

function valuetext(value) {
  return `${value}`;
}

export default function RateBar({ value, onChange, disabled, fromUserPortal }) {
  return fromUserPortal ? (
    <Box className="rate-bar-container">
      <Slider
        aria-label="Rate"
        value={value}
        onChange={(event, newValue) => onChange(newValue)}
        getAriaValueText={valuetext}
        step={1}
        valueLabelDisplay="auto"
        min={0}
        max={10}
        disabled={disabled}
        className="rate-bar-slider-user"
      />
    </Box>
  ) : (
    <Box className="rate-bar-container">
      <Slider
        aria-label="Rate"
        value={value}
        onChange={(event, newValue) => onChange(newValue)}
        getAriaValueText={valuetext}
        step={1}
        valueLabelDisplay="auto"
        marks={rate}
        min={0}
        max={10}
        disabled={disabled}
        className="rate-bar-slider"
      />
    </Box>
  );
}

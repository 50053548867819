import api from "../api";
const BASE_URL = process.env.REACT_APP_API_URL;
// Function to get plan
export const getPlansApi = async () => {
  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${BASE_URL}/subscriptions/plans`,
    };
    const response = await api.request(config);
    return response.data;
  } catch (error) {
    console.error("error in getting pb key", error.messsage);
    throw error;
  }
};

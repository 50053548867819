import React, { useEffect, useState } from "react";
import FeedbackComponent from "./Feedback";
import FeedbackStageTwoComponent from "./FeedbackStageTwo";
import FeedbackStageThreeComponent from "./FeedbackStageThree";
import FeedbackStageFourComponent from "./FeedbackStageFour";
import FeedbackStageFiveComponent from "./FeedbackStageFive";

function FeedbackForum({ onClose, audio, trackName, genreName }) {
  const [currentComponent, setCurrentComponent] = useState("feedback");
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [workStatus, setWorkStatus] = useState("work_in_progress");
  const [isInProgress, setIsInProgress] = useState(true);
  const [isCompleted, setIsCompleted] = useState(false);
  const [syncedBefore, setSyncedBefore] = useState(false);
  const [samplesUsed, setSamplesUsed] = useState(false);
  const [sampleSources, setSampleSources] = useState([]);
  const [feedbackOptions, setFeedbackOptions] = useState([]);
  const [targetOptions, setTargetOptions] = useState([]);
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [askSamples, setAskSamples] = useState(false);
  const handlFeedbackClick = () => {
    setCurrentComponent("feedbackstagetwo");
  };

  const handleFeedbackStageTwoClick = () => {
    setCurrentComponent("feedbackstagethree");
  };

  const handleFeedbackStageThreeClick = () => {
    setCurrentComponent("feedbackstagefour");
  };

  const handleFeedbackStageFourClick = () => {
    setCurrentComponent("feedbackstagefive");
  };

  const handleBack1 = () => {
    setCurrentComponent("feedback");
  };

  const handleBack2 = () => {
    setCurrentComponent("feedbackstagetwo");
  };

  const handleBack3 = () => {
    setCurrentComponent("feedbackstagethree");
  };
  const handleSelectedRolesChange = (roles) => {
    setSelectedRoles(roles);
  };
  const handleWorkStatus = (status) => {
    if (status === "completed") {
      setWorkStatus("completed");
      setIsInProgress(false);
      setIsCompleted(true);
    } else if (status === "work_in_progress") {
      setWorkStatus("work_in_progress");
      setIsInProgress(true);
      setIsCompleted(false);
    }
  };
  const handleSyncedBefore = () => {
    setSyncedBefore(!syncedBefore);
  };
  const handleSamplesUsed = () => {
    setSamplesUsed(!samplesUsed);
  };
  const handleSampleSources = (links) => {
    setSampleSources(links);
  };
  const handleFeedbackOptions = (options) => {
    setFeedbackOptions(options);
  };
  const handleTargetOptions = (options) => {
    setTargetOptions(options);
  };
  const handleAdditionalInfo = (info) => {
    setAdditionalInfo(info);
  };

  const handleAskSamples = () => {
    setAskSamples(!askSamples);
  };

  return (
    <div>
      {currentComponent === "feedback" && (
        <FeedbackComponent
          onFeedbackClick={handlFeedbackClick}
          selectedRoles={selectedRoles}
          onCloseTrack={onClose}
          audio={audio}
          trackName={trackName}
          genreName={genreName}
          onSelectedRolesChange={handleSelectedRolesChange}
        />
      )}
      {currentComponent === "feedbackstagetwo" && (
        <FeedbackStageTwoComponent
          onFeedbackClick={handleFeedbackStageTwoClick}
          handleBackClick={handleBack1}
          onCloseTrack={onClose}
          audio={audio}
          trackName={trackName}
          genreName={genreName}
          isInProgress={isInProgress}
          isCompleted={isCompleted}
          handleWorkStatus={handleWorkStatus}
          syncedBefore={syncedBefore}
          samplesUsed={samplesUsed}
          handleSyncedBefore={handleSyncedBefore}
          handleSamplesUsed={handleSamplesUsed}
          handleSampleSources={handleSampleSources}
          askSamples={askSamples}
          sampleSources={sampleSources}
          handleAskSamples={handleAskSamples}
        />
      )}
      {currentComponent === "feedbackstagethree" && (
        <FeedbackStageThreeComponent
          onFeedbackClick={handleFeedbackStageThreeClick}
          onCloseTrack={onClose}
          handleBackClick={handleBack2}
          audio={audio}
          trackName={trackName}
          genreName={genreName}
          feedbackOptions={feedbackOptions}
          targetOptions={targetOptions}
          handleFeedbackOptions={handleFeedbackOptions}
          handleTargetOptions={handleTargetOptions}
          additionalInfo={additionalInfo}
          handleAdditionalInfo={handleAdditionalInfo}
        />
      )}
      {currentComponent === "feedbackstagefour" && (
        <FeedbackStageFourComponent
          onFeedbackClick={handleFeedbackStageFourClick}
          onCloseTrack={onClose}
          handleBackClick={handleBack3}
          audio={audio}
          trackName={trackName}
          genreName={genreName}
          selectedRoles={selectedRoles}
          workStatus={workStatus}
          syncedBefore={syncedBefore}
          samplesUsed={samplesUsed}
          sampleSources={sampleSources}
          feedbackOptions={feedbackOptions}
          targetOptions={targetOptions}
          additionalInfo={additionalInfo}
        />
      )}
      {currentComponent === "feedbackstagefive" && (
        <FeedbackStageFiveComponent onCloseTrack={onClose} />
      )}
    </div>
  );
}

export default FeedbackForum;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Backbutton from "../../../components/Shared/BackButton/BackButton";
import SMLButton from "../../../components/Shared/SMLButton/SMLButton";
import "../../../components/Shared/SMLButton/SMLButton.css";
import SMLLabel from "../../../components/Shared/SMLLabel/SMLLabel";
import SMLOptionQuestion from "../../../components/Shared/SMLOptionQuestion/SMLOptionQuestion";
import SMLTextArea from "../../../components/Shared/SMLTextArea/SMLTextArea";
import SMLTextBox from "../../../components/Shared/SMLTextBox/SMLTextBox";
import SMLToggleButton from "../../../components/Shared/SMLToggleButton/SMLToggleButton";
import "../../../styles/Fonts.scss";
import "../../SignUpPage/SignUpPage.css";
import ProgressSteps from "../ProgressSteps/ProgressSteps";
import { useDispatch, useSelector } from "react-redux";
import {
  signupRequest,
  socialSignupRequest,
} from "../../../redux/actions/authActions";
import { Bars } from "react-loader-spinner";
import closeIcon from "../../../assets/Images/closeIcon.svg";

const AboutYou = ({
  contactInfo,
  onAboutClick,
  onBackClick,
  closePopup,
  roleOptions,
  handleRoleOptions,
  experienceWithSync,
  selectedName,
  setSelectedName,
  handleExperienceWithSync,
  smlLeadInfo,
  handleSmlLeadInfo,
  personalLinks,
  handlePersonalLinks,
  musicExperienceInfo,
  handleMusicExp,
  guidanceNeededInfo,
  handleGuidanceInfo,
  membPlan,
}) => {
  const { loading, artistSocialSignedIn, error, artistSignedUp } = useSelector(
    (state) => state.auth
  );
  const options = [
    { id: "option1", label: "Vocalist", value: "option1" },
    { id: "option2", label: "Musician", value: "option2" },
    { id: "option3", label: "Songwriter", value: "option3" },
    { id: "option4", label: "Producer", value: "option4" },
    { id: "option5", label: "Composer", value: "option5" },
    { id: "option6", label: "Lyricists", value: "option6" },
  ];
  const [expWithSync, setExpWithSync] = useState(experienceWithSync);
  const [loginError, setLoginError] = useState(false);
  const [personalLinks1, setPersonalLinks1] = useState(
    personalLinks.length > 0 ? personalLinks : [""]
  );
  const [validationError, setValidationError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (error) setLoginError(true);
  }, [error]);
  useEffect(() => {
    setLoginError(false);
  }, []);
  useEffect(() => {
    if (auth.artistSignedUp && !auth.loading && !auth.error) {
      onAboutClick();
    }
  }, [auth.user, auth.loading, auth.error]);
  const isValidURL = (url) => {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        '((([a-zA-Z0-9$-_@.&+!*"(),]|[a-zA-Z0-9]))+' + // domain name and possible subdomains
        "(\\.[a-zA-Z]{2,})+)" + // top-level domain
        "(\\:\\d+)?(\\/.*)?$", // port and path
      "i"
    );
    return !!pattern.test(url);
  };
  function containsXSS(input) {
    const xssPattern =
      /<\s*\/?\s*([a-z][a-z0-9]*)\b[^>]*>(?:[^<]*|<(?!\/?\1\b)[^<]*>)*<\/\1>/gi;

    return xssPattern.test(input);
  }
  const handleAbout = (e) => {
    const invalidLinks = personalLinks1.filter(
      (link) => link === "" || !isValidURL(link)
    );
    if (containsXSS(musicExperienceInfo) || containsXSS(guidanceNeededInfo)) {
      setValidationError(
        "Invalid format, Your input contains unsupported characters or scripts."
      );
      return;
    }
    if (
      roleOptions.length === 0 ||
      musicExperienceInfo === "" ||
      guidanceNeededInfo === "" ||
      selectedName === "" ||
      invalidLinks.length > 0
    ) {
      if (invalidLinks.length > 0) {
        setValidationError(
          "Please provide valid URLs for your personal links."
        );
      } else {
        setValidationError("Please fill all the details.");
      }
      return;
    }

    if (contactInfo.credential) {
      const profile = {
        role: roleOptions,
        experience_with_sync: expWithSync,
        sml_lead_info: smlLeadInfo,
        personal_links: personalLinks1,
        music_experience_info: musicExperienceInfo,
        guidance_needed_info: guidanceNeededInfo,
      };
      dispatch(
        socialSignupRequest(
          contactInfo.credential,
          contactInfo.provider,
          contactInfo.profile_type,
          profile
        )
      );
    } else {
      // Dispat.ch the signup request
      console.log(
        contactInfo.fullName,
        contactInfo.email,
        contactInfo.createPassword,
        contactInfo.profileType,
        roleOptions,
        experienceWithSync,
        smlLeadInfo === "" ? selectedName : smlLeadInfo,
        personalLinks,
        musicExperienceInfo,
        guidanceNeededInfo,
        membPlan
      );
      dispatch(
        signupRequest(
          contactInfo.fullName,
          contactInfo.email,
          contactInfo.createPassword,
          contactInfo.profileType,
          roleOptions,
          experienceWithSync,
          smlLeadInfo === "" ? selectedName : smlLeadInfo,
          personalLinks,
          musicExperienceInfo,
          guidanceNeededInfo,
          membPlan
        )
      );
    }
  };
  useEffect(() => {
    if (artistSocialSignedIn) {
      if (artistSignedUp?.is_new_user === false) {
        navigate("/Dashboard");
      } else {
        onAboutClick();
      }
    }
  }, [artistSocialSignedIn]);
  const handleClickOption = (label) => {
    const newFeedbackOptions = roleOptions.includes(label)
      ? roleOptions.filter((option) => option !== label)
      : [...roleOptions, label];
    handleRoleOptions(newFeedbackOptions);
  };
  const handleExpWithSync = () => {
    setExpWithSync(!expWithSync);
    handleExperienceWithSync();
  };
  const handleSmlLead = (data) => {
    handleSmlLeadInfo(data);
  };
  const handleAddLink = () => {
    const newPersonalLinks1 = [...personalLinks1, ""];
    setPersonalLinks1(newPersonalLinks1);
    handlePersonalLinks(newPersonalLinks1);
  };

  const handleLinkChange = (index, newValue) => {
    const updatedLinks = personalLinks1.map((link, i) =>
      i === index ? newValue : link
    );
    setPersonalLinks1(updatedLinks);
    handlePersonalLinks(updatedLinks);
  };

  const handleRemoveLink = (index) => {
    if (personalLinks1.length > 1) {
      const updatedLinks = personalLinks1.filter((_, i) => i !== index);
      setPersonalLinks1(updatedLinks);
      handlePersonalLinks(updatedLinks);
    }
  };

  const handleMusicExperience = (data) => {
    handleMusicExp(data);
  };
  const handleGuidanceNeededInfo = (data) => {
    handleGuidanceInfo(data);
  };

  const handleBackClick = () => {
    onBackClick("contact");
  };

  const handleClose = () => {
    navigate("/login");
  };

  return (
    <div>
      {loading && (
        <div className="spinner-overlay">
          <Bars
            height="80"
            width="80"
            color="#23f0c7"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
      <div className="signUp-progress-steps">
        <ProgressSteps step="2" />
      </div>

      {auth.loading && (
        <div className="spinner-overlay">
          <Bars
            height="80"
            width="80"
            color="#23f0c7"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
      <div className="signUp-close-div" onClick={closePopup}>
        <img src={closeIcon} style={{ cursor: "pointer" }} alt="Close" />
      </div>

      <div className="signUp-main">
        <div className="signUp-label-div">
          <SMLLabel value="Tell Us About Yourself" style="Mainheader" />
        </div>
        <div className="error-box">
          <SMLLabel style="error" value={validationError} />
          {loginError && <SMLLabel value={error} style="error" />}
        </div>
        <form className="signUp-grid-about">
          <div className="signUp-content">
            <div className="signUp-controls-about">
              <div className="signUp-fields1" style={{ marginBottom: "0" }}>
                <SMLLabel value="Who are you?" style="title2"></SMLLabel>
                <div className="signUp-options-container">
                  {options.map((option) => (
                    <div key={option.value}>
                      <SMLOptionQuestion
                        key={option.id}
                        value={option.label}
                        type="button"
                        style={
                          roleOptions.includes(option.label)
                            ? "SMLSecondaryButtonSelected"
                            : "SMLSecondaryButton"
                        }
                        handleClickEvent={() => handleClickOption(option.label)}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="signUp-fields" style={{ marginBottom: "0px" }}>
                <div className="signUp-groupby">
                  <SMLToggleButton
                    value="Yes"
                    checked={expWithSync}
                    onChange={handleExpWithSync}
                  />
                  <SMLLabel
                    value="Do you have experience with sync?"
                    style="title2"
                  ></SMLLabel>
                </div>
              </div>
              <div className="signUp-fields">
                <SMLLabel
                  value="How did you hear about Sync Music Lab?"
                  style="title2"
                ></SMLLabel>
                <div className="" style={{ paddingBottom: "2rem" }}>
                  <div className="SMLDropdownWrapper" style={{ width: "80%" }}>
                    <select
                      value={selectedName}
                      onChange={(e) => {
                        setSelectedName(e.target.value);
                      }}
                      className="form-control SMLDropdown"
                      style={{ width: "40%" }}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="google">Google</option>
                      <option value="facebook">Facebook Group</option>
                      <option value="social_media">Social Media</option>
                      <option value="friends">Friends</option>
                      <option value="others">Others</option>
                    </select>
                  </div>

                  {selectedName === "others" && (
                    <SMLTextBox
                      value={smlLeadInfo}
                      onChange={(e) => {
                        handleSmlLead(e.target.value);
                      }}
                      width={"65%"}
                      left={"35%"}
                    />
                  )}
                </div>
              </div>
              <div className="signUp-fields-about">
                <SMLLabel value="Add Personal Links" style="title2"></SMLLabel>
                <div className="link_scrollbar">
                  <div className="about-link-groupby">
                    {personalLinks1.map((link, index) => (
                      <div key={index} className="feedback-groupby">
                        <SMLTextBox
                          placeHolder="Link"
                          value={link}
                          onChange={(e) =>
                            handleLinkChange(index, e.target.value)
                          }
                          imgSrc={index > 0 ? closeIcon : null} // Only show remove icon for boxes after the first one
                          onImgClick={() => handleRemoveLink(index)}
                          width={"98%"}
                          widthInput={"100%"}
                        />
                      </div>
                    ))}
                  </div>
                  <div onClick={handleAddLink} className="orange">
                    +
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="signUp-content">
            <div className="signUp-controls-about-right">
              <div className="signUp-fields-about">
                <SMLLabel
                  value="Tell us about your music experience."
                  style="title2"
                ></SMLLabel>
                <SMLTextArea
                  value={musicExperienceInfo}
                  onChange={(e) => {
                    handleMusicExperience(e.target.value);
                  }}
                  maxLength="500"
                />
              </div>
              <div>{500 - musicExperienceInfo.length} characters remaining</div>
              <div className="signUp-fields-about">
                <SMLLabel
                  value="What kind of guidance are you looking for?"
                  style="title2"
                ></SMLLabel>
                <SMLTextArea
                  value={guidanceNeededInfo}
                  onChange={(e) => {
                    handleGuidanceNeededInfo(e.target.value);
                  }}
                  maxLength="500"
                />
                <div>
                  {500 - guidanceNeededInfo.length} characters remaining
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="signUp-footer">
          <div onClick={handleBackClick}>
            <Backbutton />
          </div>
          {/* <div className="" style={{ textAlign: "center" }}> */}
          {/* </div> */}
          <div className="continue-btns" onClick={handleAbout}>
            <SMLButton style="SMLButton2 SMLButton-Orange" value="Continue" />
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default AboutYou;

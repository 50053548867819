import * as actionTypes from "./actionTypes";
export const loginRequest = (email, password, profile_type) => ({
  type: actionTypes.LOGIN_REQUEST,
  payload: { email, password, profile_type },
});

export const loginSuccess = (user, artistId) => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    payload: { user, artistId },
  };
};
export const logoutRequest = () => ({
  type: actionTypes.LOGOUT,
});
export const loginFailure = (error) => ({
  type: actionTypes.LOGIN_FAILURE,
  payload: error,
});
export const supSignupRequest = ({
  email,
  password,
  fullName,
  profileType,
  name,
  company,
  profession,
  payment_info,
}) => ({
  type: actionTypes.SUP_SIGNUP_REQUEST,
  payload: {
    email,
    password,
    fullName,
    profileType,
    name,
    company,
    profession,
    payment_info,
  },
});
export const supSignupSuccess = (user) => ({
  type: actionTypes.SUP_SIGNUP_SUCCESS,
  payload: user,
});
export const supSignupFailure = (error) => ({
  type: actionTypes.SUP_SIGNUP_FAILURE,
  payload: error,
});
export const signupRequest = (
  fullName,
  email,
  password,
  profileType,
  roles,
  experienceWithSync,
  smlLeadInfo,
  personalLinks,
  musicExperienceInfo,
  guidanceNeededInfo,
  membPlan
) => ({
  type: actionTypes.SIGNUP_REQUEST,
  payload: {
    fullName,
    email,
    password,
    profileType,
    roles,
    experienceWithSync,
    smlLeadInfo,
    personalLinks,
    musicExperienceInfo,
    guidanceNeededInfo,
    membPlan,
  },
});

export const artistSignupRemove = () => ({
  type: actionTypes.ARTIST_SIGNUP_REMOVE,
});
export const artistShowLogin = () => ({
  type: actionTypes.ARTIST_SHOW_LOGIN,
});
export const supSignupRemove = () => ({
  type: actionTypes.SUP_SIGNUP_REMOVE,
});
export const supShowLogin = () => ({
  type: actionTypes.SUP_SHOW_LOGIN,
});
export const signupSuccess = (user) => ({
  type: actionTypes.SIGNUP_SUCCESS,
  payload: user,
});

export const signupFailure = (error) => ({
  type: actionTypes.SIGNUP_FAILURE,
  payload: error,
});

export const socialLoginRequest = (token, provider, profile_type) => ({
  type: actionTypes.SOCIAL_LOGIN_REQUEST,
  payload: { token, provider, profile_type },
});
export const socialLoginSuccess = (user) => ({
  type: actionTypes.SOCIAL_LOGIN_SUCCESS,
  payload: user,
});
export const socialLoginFailure = (error) => ({
  type: actionTypes.SOCIAL_LOGIN_FAILURE,
  payload: error,
});
export const socialSignupRequest = (
  token,
  provider,
  profile_type,
  profile
) => ({
  type: actionTypes.SOCIAL_SIGNUP_REQUEST,
  payload: { token, provider, profile_type, profile },
});
export const socialSignupSuccess = (user) => ({
  type: actionTypes.SOCIAL_SIGNUP_SUCCESS,
  payload: user,
});
export const socialSignupFailure = (error) => ({
  type: actionTypes.SOCIAL_SIGNUP_FAILURE,
  payload: error,
});
export const supSocialSignupRequest = (
  token,
  provider,
  profile_type,
  profile
) => ({
  type: actionTypes.SUP_SOCIAL_SIGNUP_REQUEST,
  payload: { token, provider, profile_type, profile },
});
export const supSocialSignupSuccess = (user) => ({
  type: actionTypes.SUP_SOCIAL_SIGNUP_SUCCESS,
  payload: user,
});
export const supSocialSignupFailure = (error) => ({
  type: actionTypes.SUP_SOCIAL_SIGNUP_FAILURE,
  payload: error,
});
export const refreshTokenRequest = () => ({
  type: actionTypes.REFRESH_TOKEN_REQUEST,
});
export const refreshTokenSuccess = (data) => ({
  type: actionTypes.REFRESH_TOKEN_SUCCESS,
  payload: data,
});
export const refreshTokenFailure = (error) => ({
  type: actionTypes.REFRESH_TOKEN_FAILURE,
  payload: error,
});
export const resetPasswordRequest = (oldPassword, newPassword) => ({
  type: actionTypes.RESET_PASSWORD_REQUEST,
  payload: { oldPassword, newPassword },
});
export const resetPasswordSuccess = (data) => ({
  type: actionTypes.RESET_PASSWORD_SUCCESS,
  payload: data,
});
export const resetPasswordFailure = (error) => ({
  type: actionTypes.RESET_PASSWORD_FAILURE,
  payload: error,
});
export const resetPasswordRemove = () => ({
  type: actionTypes.RESET_PASSWORD_REMOVE,
});

import {
  UPDATE_ARTIST_REQUEST,
  UPDATE_ARTIST_SUCCESS,
  UPDATE_ARTIST_FAILURE,
  GET_ALL_TRACK_FETCH,
} from "./actionTypes";

export const GET_UNDER_REVIEW_TRACKS_SUCCESS = "GET_UNDER_REVIEW_TRACKS";
export const GET_UNDER_REVIEW_TRACKS_FAILURE =
  "GET_UNDER_REVIEW_TRACKS_FAILURE";
export const GET_UNDER_REVIEW_TRACKS_FETCH = "GET_UNDER_REVIEW_TRACKS_FETCH";

export const GET_UNDER_REVIEW_TRACKS_LOADING =
  "GET_UNDER_REVIEW_TRACKS_LOADING";

export const GET_FEEDBACK_RECEIVED_TRACKS_SUCCESS =
  "GET_FEEDBACK_RECEIVED_TRACKS";
export const GET_FEEDBACK_RECEIVED_TRACKS_FAILURE =
  "GET_UNDER_REVIEWED_TRACKS_FAILURE";
export const GET_FEEDBACK_RECEIVED_TRACKS_FETCH =
  "GET_UNDER_REVIEWED_TRACKS_FETCH";
export const GET_FEEDBACK_RECEIVED_TRACKS_LOADING =
  "GET_FEEDBACK_RECEIVED_TRACKS_LOADING";
export const GET_ALL_ARTIST_FETCH = "GET_ALL_ARTIST_FETCH";
export const GET_ALL_ARTIST_SUCCESS = "GET_ALL_ARTIST_SUCCESS";
export const GET_ALL_ARTIST_FAILURE = "GET_ALL_ARTIST_FAILURE";
export const GET_ALL_ARTIST_LOADING = "GET_ALL_ARTIST_LOADING";
export const RESET_ARTIST = "RESET_ARTIST";
// export const GET_ALL_TRACK_FETCH = 'GET_ALL_TRACK_FETCH';
export const GET_ALL_TRACK_SUCCESS = "GET_ALL_TRACK_SUCCESS";
export const GET_ALL_TRACK_FAILURE = "GET_ALL_TRACK_FAILURE";
export const GET_ALL_TRACK_LOADING = "GET_ALL_TRACK_LOADING";
export const DOWNLOAD_TRACK_REQUEST = "DOWNLOAD_TRACK_REQUEST";
export const DOWNLOAD_TRACK_SUCCESS = "DOWNLOAD_TRACK_SUCCESS";
export const DOWNLOAD_TRACK_FAILURE = "DOWNLOAD_TRACK_FAILURE";
export const DOWNLOAD_TRACK_LOADING = "DOWNLOAD_TRACK_LOADING";
export const getAllTracksFetch = (trackStatus, type, offset, limit) => {
  console.log("action", trackStatus, offset, limit);
  return {
    type: GET_ALL_TRACK_FETCH,
    payload: { trackStatus, type, offset, limit },
  };
};
export const inReviewTracksFetch = (trackStatus, type, offset, limit) => {
  return {
    type: GET_UNDER_REVIEW_TRACKS_FETCH,
    payload: { trackStatus, type, offset, limit },
  };
};
export const feedbackReceivedFetch = (trackStatus, type, offset, limit) => {
  return {
    type: GET_FEEDBACK_RECEIVED_TRACKS_FETCH,
    payload: { trackStatus, type, offset, limit },
  };
};

export const getAllArtistFetch = (artistId) => {
  return {
    type: GET_ALL_ARTIST_FETCH,
    payload: artistId,
  };
};
export const downloadTrackById = (trackId) => {
  return {
    type: DOWNLOAD_TRACK_REQUEST,
    payload: trackId,
  };
};

export const getFeedBackReceivedTracks = () => {
  return {
    type: GET_FEEDBACK_RECEIVED_TRACKS_FETCH,
  };
};

export const updateArtistRequest = (updates) => {
  return {
    type: UPDATE_ARTIST_REQUEST,
    payload: { updates },
  };
};

export const updateArtistSuccess = (updates) => {
  return {
    type: UPDATE_ARTIST_SUCCESS,
    payload: { updates },
  };
};
export const updateArtistFailure = () => {
  return {
    type: UPDATE_ARTIST_FAILURE,
  };
};

import HomeLayout from "../../components/Layout/HomeLayout";
import React, { useEffect, useRef, useState } from "react";
import TracksView from "../../components/common/tracks-view/TracksView";
import FeedbackReceivedIcon from "../../assets/Icons/feedback-recieved.svg";
import { useDispatch, useSelector } from "react-redux";
import { getAllTracksFetch } from "../../redux/actions/smlActions";
import { Bars } from "react-loader-spinner";
import {
  RESET_GET_ALL_TRACK,
  RESET_SEARCH,
} from "../../redux/actions/actionTypes";
import { getTrackStats, searchRequest } from "../../redux/actions";

export default function FeedbackReceived() {
  const dispatch = useDispatch();
  const FeedbackReceivedref = useRef(null);
  const [offset, setOffset] = useState(0);
  const [searchOffset, setSearchOffset] = useState(0);
  const tracksfromsearch = useSelector(
    (store) => store.search.searchedResult.result
  );
  const searchText = useSelector(
    (store) => store.search.searchText?.searchText?.value
  );
  const tracks = useSelector(
    (state) => state.sml.allTracksFetched?.tracks || state.uploadTrack?.tracks
  );
  const loading = useSelector(
    (store) =>
      store.uploadTrack?.isLoading ||
      store.searchText?.searchedResult?.isLoading
  );
  const trackStats = useSelector(
    (store) => store.trackStats.statsFetched?.stats
  );

  const pagination = useSelector((state) => state.sml.pagination?.info);

  if (!trackStats || trackStats.length === 0) {
    dispatch(getTrackStats("artist"));
  }
  useEffect(() => {
    dispatch({ type: RESET_SEARCH });
    dispatch({ type: RESET_GET_ALL_TRACK });
    dispatch(
      getAllTracksFetch("final_feedback_submitted", "artist_id", offset, 10)
    );
  }, [dispatch]);

  useEffect(() => {
    if (hasSearched) {
      setSearchOffset(0);
    }
  }, [searchText]);

  const hasSearchResults = tracksfromsearch && tracksfromsearch?.length > 0;
  const hasSearched = searchText && searchText.trim() !== ""; // Check if the search box is not empty

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  const hasMore = useSelector((state) => state.sml.pagination?.info?.hasMore);
  const searchHasMore = useSelector(
    (state) => state.search.searchedResponse?.pagination?.hasMore
  );

  const handleScroll = debounce(() => {
    if (FeedbackReceivedref.current) {
      // Only trigger scroll if no search
      const { scrollTop, scrollHeight, clientHeight } =
        FeedbackReceivedref.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        console.log("live2", searchText);
        if (hasSearched) {
          if (searchHasMore) {
            setSearchOffset((prevOffset) => {
              const newOffset = prevOffset + 10;
              dispatch(
                searchRequest(
                  searchText,
                  "final_feedback_submitted",
                  newOffset,
                  10
                )
              );
              return newOffset;
            });
          }
        } else {
          setOffset((prevOffset) => {
            const newOffset = prevOffset + 10;
            dispatch(
              getAllTracksFetch("submitted", "artist_id", newOffset, 10)
            );
            return newOffset;
          });
        }
      }
    }
  }, 600);

  useEffect(() => {
    const currentRef = FeedbackReceivedref.current;

    if (currentRef) {
      const preventScroll = (e) => {
        const { scrollTop, scrollHeight, clientHeight } = currentRef;
        if (
          (scrollTop <= 0 && e.deltaY < 0) ||
          (scrollTop + clientHeight >= scrollHeight && e.deltaY > 0)
        ) {
          e.preventDefault();
        }
      };
      (searchHasMore || hasMore) &&
        currentRef.addEventListener("wheel", preventScroll, { passive: false });
      (searchHasMore || hasMore) &&
        currentRef.addEventListener("scroll", handleScroll);

      return () => {
        currentRef.removeEventListener("wheel", preventScroll);
        currentRef.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  return (
    <HomeLayout status="final_feedback_submitted">
      <TracksView
        headerLabel={"Feedback Received"}
        headerLabelIcon={FeedbackReceivedIcon}
        tracks={
          tracksfromsearch === null
            ? []
            : hasSearched === ""
            ? tracks
            : hasSearchResults
            ? tracksfromsearch
            : hasSearched
            ? []
            : tracks
        }
        noTracksMessage="No tracks found."
        count={pagination?.total}
        tracksRef={FeedbackReceivedref}
      />
      {loading && (
        <div className="spinner-overlay">
          <Bars
            height="80"
            width="80"
            color="#23f0c7"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
    </HomeLayout>
  );
}

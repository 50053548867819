import React, { useEffect, useRef, useState } from "react";
import HomeLayout from "../../components/Layout/HomeLayout";
import TracksView from "../../components/common/tracks-view/TracksView";
import { useDispatch, useSelector } from "react-redux";
import { Bars } from "react-loader-spinner";
import { getAllTracksFetch } from "../../redux/actions/smlActions";
import {
  RESET_GET_ALL_TRACK,
  RESET_SEARCH,
} from "../../redux/actions/actionTypes";
import { getTrackStats, searchRequest } from "../../redux/actions";

export default function UploadedTracksPage() {
  const dispatch = useDispatch();
  const uploadedRef = useRef(null);
  const [offset, setOffset] = useState(0);
  const [searchOffset, setSearchOffset] = useState(0);
  const sml = useSelector((store) => store.sml);
  const trackStats = useSelector(
    (store) => store.trackStats.statsFetched?.stats
  );
  if (!trackStats || trackStats.length === 0) {
    dispatch(getTrackStats("artist"));
  }
  const searchText = useSelector(
    (store) => store.search.searchText?.searchText?.value
  );
  const tracksfromsearch = useSelector(
    (store) => store.search.searchedResult?.result
  );

  const tracks = useSelector(
    (state) => state.sml.allTracksFetched?.tracks || state.uploadTrack?.tracks
  );
  const loading = useSelector((store) => store.uploadTrack.isLoading);
  useEffect(() => {
    if (hasSearched) {
      setSearchOffset(0);
    }
  }, [searchText]);

  useEffect(() => {
    dispatch({ type: RESET_SEARCH });
    dispatch({ type: RESET_GET_ALL_TRACK });
    dispatch(getAllTracksFetch("submitted", "artist_id", offset, 10));
  }, [dispatch]);

  const hasSearchResults = tracksfromsearch && tracksfromsearch?.length > 0;
  const hasSearched = searchText && searchText.trim() !== "";

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };
  const pagination = useSelector((state) => state.sml.pagination?.info);
  const hasMore = useSelector((state) => state.sml.pagination?.info?.hasMore);
  const searchHasMore = useSelector(
    (state) => state.search.searchedResponse?.pagination?.hasMore
  );
  console.log("live", searchText, searchHasMore);
  const handleScroll = debounce(() => {
    if (uploadedRef.current) {
      // Only trigger scroll if no search
      const { scrollTop, scrollHeight, clientHeight } = uploadedRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        console.log("live2", searchText);
        if (hasSearched) {
          if (searchHasMore) {
            setSearchOffset((prevOffset) => {
              const newOffset = prevOffset + 10;
              dispatch(searchRequest(searchText, "submitted", newOffset, 10));
              return newOffset;
            });
          }
        } else {
          setOffset((prevOffset) => {
            const newOffset = prevOffset + 10;
            dispatch(
              getAllTracksFetch("submitted", "artist_id", newOffset, 10)
            );
            return newOffset;
          });
        }
      }
    }
  }, 600);

  useEffect(() => {
    const currentRef = uploadedRef.current;

    if (currentRef) {
      const preventScroll = (e) => {
        const { scrollTop, scrollHeight, clientHeight } = currentRef;
        if (
          (scrollTop <= 0 && e.deltaY < 0) ||
          (scrollTop + clientHeight >= scrollHeight && e.deltaY > 0)
        ) {
          e.preventDefault();
        }
      };
      // if (!hasSearched) {
      // Prevent scroll behavior only when no search is active
      (searchHasMore || hasMore) &&
        currentRef.addEventListener("wheel", preventScroll, {
          passive: false,
        });
      (searchHasMore || hasMore) &&
        currentRef.addEventListener("scroll", handleScroll);
      // }

      return () => {
        currentRef.removeEventListener("wheel", preventScroll);
        currentRef.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  return (
    <HomeLayout status="submitted">
      <TracksView
        headerLabel="Uploaded Tracks"
        tracks={
          tracksfromsearch === null
            ? []
            : hasSearched === ""
            ? tracks
            : hasSearchResults
            ? tracksfromsearch
            : hasSearched
            ? []
            : tracks
        }
        noTracksMessage="No tracks found."
        tracksRef={uploadedRef}
        count={pagination?.total}
      />
      {loading && (
        <div className="spinner-overlay">
          <Bars
            height="80"
            width="80"
            color="#23f0c7"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
    </HomeLayout>
  );
}

import React, { useState, useEffect } from "react";
import "../../styles/Fonts.scss";
import "../../styles/HTMLControls.scss";
import "./SupAssignPopup.css";
import SMLLabel from "../../components/Shared/SMLLabel/SMLLabel";
import SMLButton from "../../components/Shared/SMLButton/SMLButton";
import closeIcon from "../../assets/Images/closeIcon.svg";
import SupAssignSuccess from "./SupAssignSuccess";
import { useDispatch, useSelector } from "react-redux";
import {
  assignSuperVisorRequest,
  getAllSupervisorRequest,
} from "../../redux/actions";
import SMLRadioButton from "../../components/Shared/SMLRadioButton/SMLRadioButton";
import { getSingleTrackRequest } from "../../redux/actions/uploadTrackActions";
import { useLocation, useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import { RESET_GET_ALL_TRACK } from "../../redux/actions/actionTypes";
import { getAllTracksFetch } from "../../redux/actions/smlActions";

function SupAssignPopup(props) {
  const location = useLocation();
  const isTrackFeedbackPath = location.pathname.includes("trackFeedback");
  const navigate = useNavigate();
  const [selectedSupervisor, setSelectedSupervisor] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isCompleteEnabled, setIsCompleteEnabled] = useState(false);
  const dispatch = useDispatch();

  // Fetch current supervisor ID to be marked as "Myself"
  const currentAssignedSupId = useSelector(
    (state) => state.uploadTrack?.singleTrack?.assigned_supervisor?.id
  );
  console.log("this coming?", currentAssignedSupId);
  const currentSupId = useSelector(
    (state) => state.sml.allArtistFetched?.artists?._id
  );

  const supervisors = useSelector(
    (state) => state.supervisor?.supervisor?.supervisors
  );
  const assignedSupervisor1 = useSelector(
    (state) => state.assignSupervisor.assigned
  );
  const assigned = useSelector(
    (state) => state.uploadTrack?.singleTrack?.assigned_supervisor_id
  );

  const loading = useSelector((state) => state.assignSupervisor.isLoading);
  // Fetch supervisors on component load
  useEffect(() => {
    dispatch(getSingleTrackRequest(props.trackId));
    dispatch(getAllSupervisorRequest());
  }, [dispatch, props.trackId]);

  // (only one can be selected)
  const handleOptionChange = (supervisorId) => {
    setSelectedSupervisor(supervisorId);
    setIsCompleteEnabled(!!supervisorId); // Enable the button if one option is selected
  };

  useEffect(() => {
    if (currentAssignedSupId) {
      console.log("this coming", currentAssignedSupId);
      setSelectedSupervisor(currentAssignedSupId);
    }
  }, [currentAssignedSupId]);

  const handleSubmitTrack = () => {
    if (selectedSupervisor) {
      dispatch(
        assignSuperVisorRequest({
          trackId: props.trackId,
          supervisor: selectedSupervisor,
        })
      );
    }
  };

  useEffect(() => {
    if (assigned && assigned.length > 0 && !props.mandate) {
      setIsSuccess(true);
    }
  }, [assigned, props.mandate]);

  useEffect(() => {
    if (assignedSupervisor1 && Object.keys(assignedSupervisor1).length > 0) {
      props.onClose();
      props.handleStatus();
      setTimeout(() => {
        if (currentSupId === assignedSupervisor1.assigned_supervisor_id) {
          navigate(`/trackFeedback/${props.trackId}`, {
            state: { status: "in_review" },
          });
          setIsSuccess(true);
        } else {
          !isTrackFeedbackPath && window.location.reload();
          setIsSuccess(true);
        }
      }, 1000);
    }
  }, [assignedSupervisor1, props]);

  const handleCloseButton = () => {
    props.onClose();
  };

  // Move the current supervisor to the top
  const sortedSupervisors = supervisors?.slice().sort((a, b) => {
    if (a._id === currentSupId) return -1;
    if (b._id === currentSupId) return 1;
    return 0;
  });

  return isSuccess ? (
    <SupAssignSuccess
      trackId={props.trackId}
      onClose={handleCloseButton}
      currentStatus={props.currentStatus}
      handleStatus={props.handleStatus}
      assigned={props.assignedSupervisor}
    />
  ) : (
    <div className="supAssign-container">
      <div className="supAssign-card">
        {loading && (
          <div className="spinner-overlay">
            <Bars
              height="80"
              width="80"
              color="#23f0c7"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
        <div
          className="supAssign-close-div"
          onClick={() => {
            // !isTrackFeedbackPath && dispatch({ type: RESET_GET_ALL_TRACK });
            // !isTrackFeedbackPath &&
            //   dispatch(getAllTracksFetch("submitted", "supervisor_id", 0, 10));
            props.onClose();
          }}
        >
          <img src={closeIcon} alt="close" />
        </div>
        <div className="supAssign-main">
          <div className="supAssign-Popup-Content">
            <SMLLabel
              value="Who do you want to assign the track to?"
              style="Mainheader supAssign-heading"
            />
            <div className="supAssign-GroupBy-container">
              {sortedSupervisors?.map((supervisor) => (
                <div
                  className="supAssign-GroupBy radio-wrapper"
                  key={supervisor._id}
                  onClick={() => handleOptionChange(supervisor._id)}
                >
                  <SMLRadioButton
                    id={supervisor._id}
                    label={
                      supervisor._id === currentSupId
                        ? "Myself"
                        : supervisor.name
                    }
                    value={supervisor._id}
                    checked={selectedSupervisor === supervisor._id} // Only one can be selected
                    onChange={() => handleOptionChange(supervisor._id)} // Update onChange
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <SMLButton
          value="Assign Track"
          style={`supAssignButton ${isCompleteEnabled ? "" : "disabled"}`}
          handleClickEvent={handleSubmitTrack}
          handleDisable={!isCompleteEnabled}
        />
      </div>
    </div>
  );
}

export default SupAssignPopup;

import clsx from "clsx";
import React from "react";
import Styles from "./StyledComponent.module.css";

export default function SMLButton({ children, className, style, onClick }) {
  return (
    <button
      className={clsx(Styles.smlButton, className)}
      style={style}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

import { call, put, takeLatest } from "redux-saga/effects";
import {
  CANCEL_SUB_FAILURE,
  CANCEL_SUB_LOADING,
  CANCEL_SUB_REQUEST,
  CANCEL_SUB_SUCCESS,
  CREATE_SUB_FAILURE,
  CREATE_SUB_LOADING,
  CREATE_SUB_REQUEST,
  CREATE_SUB_SUCCESS,
  GET_CLIENT_SECRET_LOADING,
  GET_CLIENT_SECRET_REQUEST,
  GET_CLIENT_SECRET_SUCCESS,
  GET_PB_KEY_FAILURE,
  GET_PB_KEY_LOADING,
  GET_PB_KEY_REQUEST,
  GET_PB_KEY_SUCCESS,
  GET_PLANS_FAILURE,
  GET_PLANS_LOADING,
  GET_PLANS_REQUEST,
  GET_PLANS_SUCCESS,
} from "../actions/actionTypes";
import { getPbKeyApi } from "../../api/payment/getPbKey";
import { getPlansApi } from "../../api/payment/getPlans";
import { getClientSecretApi } from "../../api/payment/getClientSecret";
import { createSubApi } from "../../api/payment/createSub";
import { cancelSubApi } from "../../api/payment/cancelSub";

function* getPbKey() {
  try {
    yield put({ type: GET_PB_KEY_LOADING });
    const key = yield call(getPbKeyApi);
    yield put({ type: GET_PB_KEY_SUCCESS, key });
  } catch (error) {
    yield put({ type: GET_PB_KEY_FAILURE, error });
  }
}
function* getPlans() {
  try {
    yield put({ type: GET_PLANS_LOADING });
    const plans = yield call(getPlansApi);
    yield put({ type: GET_PLANS_SUCCESS, plans });
  } catch (error) {
    yield put({ type: GET_PLANS_FAILURE, error });
  }
}
function* getClientSecret(action) {
  try {
    yield put({ type: GET_CLIENT_SECRET_LOADING });
    const secret = yield call(getClientSecretApi, action.payload);
    yield put({ type: GET_CLIENT_SECRET_SUCCESS, secret: secret.clientSecret });
  } catch (error) {
    yield put({ type: GET_PB_KEY_FAILURE, error });
  }
}
function* createSub(action) {
  try {
    yield put({ type: CREATE_SUB_LOADING });
    const result = yield call(createSubApi, action.payload);
    yield put({ type: CREATE_SUB_SUCCESS, result });
  } catch (error) {
    yield put({ type: CREATE_SUB_FAILURE, error });
  }
}
function* cancelSub(action) {
  try {
    yield put({ type: CANCEL_SUB_LOADING });
    const data = action.payload;
    const cancelled = yield call(cancelSubApi, data);
    yield put({ type: CANCEL_SUB_SUCCESS, cancelled });
  } catch (error) {
    yield put({ type: CANCEL_SUB_FAILURE, error });
  }
}
function* paymentSaga() {
  yield takeLatest(GET_PB_KEY_REQUEST, getPbKey);
  yield takeLatest(GET_PLANS_REQUEST, getPlans);
  yield takeLatest(GET_CLIENT_SECRET_REQUEST, getClientSecret);
  yield takeLatest(CREATE_SUB_REQUEST, createSub);
  yield takeLatest(CANCEL_SUB_REQUEST, cancelSub);
}
export default paymentSaga;

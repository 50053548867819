import api from "./api";
const BASE_URL = process.env.REACT_APP_API_URL;
export const getArtist = async () => {
  try {
    const accessToken = localStorage.getItem("token");
    const token = localStorage.getItem("token");
    const url = `https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev/profiles/me`;
    let config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await api.get(url, config);
    return response?.data;
  } catch (error) {
    console.error(
      "Error fetching artists:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

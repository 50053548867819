// sagas/authSaga.js
import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import {
  loginSuccess,
  loginFailure,
  signupSuccess,
  signupFailure,
  socialLoginSuccess,
  socialLoginFailure,
  supSignupSuccess,
  supSignupFailure,
  socialSignupSuccess,
  socialSignupFailure,
  refreshTokenSuccess,
  refreshTokenFailure,
  refreshTokenRequest,
  resetPasswordSuccess,
  resetPasswordFailure,
} from "../actions/authActions";
import { login } from "../../api/auth/login";
import { signup, supSignupApi } from "../../api/auth/signup";
import { social, socialLogin, socialSignup } from "../../api/auth/social";
import { jwtDecode } from "jwt-decode";
import { refreshToken } from "../../api/auth/refreshToken";
import { resetPassword } from "../../api/auth/resetPassword";

function* loginSaga(action) {
  try {
    const user = yield call(login, action.payload);
    const decodedToken = jwtDecode(user.access_token);
    const artistId = decodedToken["custom:artist_id"];
    yield put(loginSuccess(user, artistId));
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "An unknown error occurred";
    yield put(loginFailure(errorMessage));
  }
}

function* signupSaga(action) {
  try {
    const user = yield call(signup, action.payload);
    yield put({ type: actionTypes.SIGNUP_SUCCESS, user: user });
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "An unknown error occurred";
    yield put(signupFailure(errorMessage));
  }
}
function* supSignupSaga(action) {
  try {
    const user = yield call(supSignupApi, action.payload);
    yield put(supSignupSuccess(user));
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "An unknown error occurred";
    yield put(supSignupFailure(errorMessage));
  }
}
function* socialLoginSaga(action) {
  try {
    const user = yield call(socialLogin, action.payload);
    yield put(socialLoginSuccess(user));
  } catch (error) {
    yield put(socialLoginFailure(error.message));
  }
}
function* socialSignupSaga(action) {
  try {
    const user = yield call(socialSignup, action.payload);
    yield put(socialSignupSuccess(user));
  } catch (error) {
    yield put(socialSignupFailure(error.message));
  }
}
function* refreshTokenSaga() {
  try {
    const access_token = localStorage.getItem("token");
    const refresh_token = localStorage.getItem("refresh_token");

    const data = yield call(refreshToken, { access_token, refresh_token });
    yield put(refreshTokenSuccess(data));
  } catch (error) {
    yield put(refreshTokenFailure(error.message));
  }
}
function* resetPasswordSaga(action) {
  try {
    const data = yield call(resetPassword, action.payload);
    yield put(resetPasswordSuccess(data));
  } catch (error) {
    yield put(resetPasswordFailure(error.message));
  }
}

export default function* authSaga() {
  yield takeLatest(actionTypes.LOGIN_REQUEST, loginSaga);
  yield takeLatest(actionTypes.SIGNUP_REQUEST, signupSaga);
  yield takeLatest(actionTypes.SUP_SIGNUP_REQUEST, supSignupSaga);
  yield takeLatest(actionTypes.SOCIAL_LOGIN_REQUEST, socialLoginSaga);
  yield takeLatest(actionTypes.SOCIAL_SIGNUP_REQUEST, socialSignupSaga);
  yield takeLatest(actionTypes.SUP_SOCIAL_SIGNUP_REQUEST, socialSignupSaga);
  yield takeLatest(actionTypes.REFRESH_TOKEN_REQUEST, refreshTokenSaga);
  yield takeLatest(actionTypes.RESET_PASSWORD_REQUEST, resetPasswordSaga);
}

import {

// Import Action Types
  VIDEO_CONSULTATION_REQUEST,
  VIDEO_CONSULTATION_SUCCESS,
  VIDEO_CONSULTATION_FAILURE,
  VIDEO_CONSULTATION_LOADING,
} from '../actions/actionTypes';

// Initial State
const initialState = {
  loading: false,
  data: null,
  error: null,
};

// Reducer
const videoConsultationReducer = (state = initialState, action) => {
  switch (action.type) {
    case VIDEO_CONSULTATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case VIDEO_CONSULTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case VIDEO_CONSULTATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case VIDEO_CONSULTATION_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default videoConsultationReducer;
import React, { useState, useRef, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import SMLLabel from "../../components/Shared/SMLLabel/SMLLabel";
import "../Supervisor-Dashboard/SupDashboard.css";
import "../../styles/consistent-theme.scss";
import audioSrc from "../../assets/audio/audio-test.mp3";
import trackImg from "../../assets/Images/TrackRectangle.svg";
import SUPHeader from "../../components/Shared/Header/SupHeader";
import TrackSection from "../../components/Shared/TrackSection/TrackSection";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllTracksFetch } from "../../redux/actions/smlActions";
import SupAssignPopup from "./SupAssignPopup";
import { getTrackStats } from "../../redux/actions";
import { Bars } from "react-loader-spinner";
import {
  RESET_ASSIGNED_SUPERVISOR,
  RESET_GET_ALL_TRACK,
  RESET_GET_SINGLE_TRACK,
} from "../../redux/actions/actionTypes";

const SupDashboard = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [offset, setOffset] = useState(0);
  const [trackId, setTrackId] = useState("");
  const isMaster = localStorage.getItem("isMaster");
  const [progress, setProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const mediaRef = useRef(null);
  const tracksRef = useRef(null);
  const [btnStyle, setBtnStyle] = useState("SMLToggleBtn-white");
  const [showWrapper, setShowWrapper] = useState(true);
  const [showAssignPopup, setShowAssignPopup] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("submitted");
  const assigned_supervisor = useSelector(
    (state) => state.assignSupervisor?.assigned
  );
  const handleButtonClick = () => {
    setBtnStyle("SMLToggleBtn-orange");
    setShowWrapper(false);
  };

  const [selectedSection, setSelectedSection] = useState("Waiting");
  const [labelText, setLabelText] = useState("Submitted Tracks");
  const handleSectionChange = (section) => {
    section !== selectedSection && dispatch({ type: RESET_GET_ALL_TRACK });
    setSelectedSection(section);
  };
  const tracks = useSelector((state) => state.sml.allTracksFetched?.tracks);
  const loading = useSelector((state) => state.sml.allTracksFetched?.isLoading);
  useEffect(() => {
  }, [tracks]);
  const handleShowAssignPopup = (trackID) => {
    dispatch({ type: RESET_ASSIGNED_SUPERVISOR });
    setTrackId(trackID);
    setShowAssignPopup(!showAssignPopup);
  };
  const stats = useSelector((state) => state.trackStats?.statsFetched?.stats);
  const hasMore = useSelector((state) => state.sml.pagination?.info?.hasMore);

  useEffect(() => {
    dispatch({ type: RESET_GET_SINGLE_TRACK });
    dispatch({ type: RESET_GET_ALL_TRACK });
    setOffset(0);
    dispatch(getTrackStats("supervisor"));
    if (selectedSection === "Waiting") {
      setLabelText("Submitted Tracks");
      dispatch(getAllTracksFetch("submitted", "supervisor_id", 0, 10));
    } else if (selectedSection === "Assigned") {
      setLabelText("Assigned Tracks");
      dispatch(getAllTracksFetch("Assigned", "supervisor_id", 0, 10));
    } else if (selectedSection === "UnderReview") {
      setLabelText("Under Review Tracks");
      dispatch(getAllTracksFetch("in_review", "supervisor_id", 0, 10));
    } else if (selectedSection === "UnderMasterReview") {
      setLabelText("Completed Tracks");
      dispatch(getAllTracksFetch("feedback_submitted", "supervisor_id", 0, 10));
    } else if (selectedSection === "Pending") {
      setLabelText("Externally Reviewed Tracks");
      dispatch(getAllTracksFetch("Pending", "supervisor_id", 0, 10));
    } else if (selectedSection === "Delivered") {
      setLabelText("Delivered Tracks");
      dispatch(
        getAllTracksFetch("final_feedback_submitted", "supervisor_id", 0, 10)
      );
    }
  }, [selectedSection, dispatch]);

  const handleTogglePlay = () => {
    const video = mediaRef.current;
    if (isPlaying) {
      video.pause();
    } else {
      video.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const media = mediaRef.current;
    const progressPercent = (media.currentTime / media.duration) * 100;
    setProgress(progressPercent);
  };

  const handleTrackFeedback = (trackID, status) => {
    dispatch({ type: RESET_ASSIGNED_SUPERVISOR });
    dispatch({ type: RESET_GET_SINGLE_TRACK });
    setCurrentStatus(status);
    status === "final_feedback_submitted" ||
    (status === "feedback_submitted" && isMaster === "false")
      ? navigate(`/TrackFeedbackReviewed/${trackID}`, {
          state: { status },
        })
      : navigate(`/TrackFeedback/${trackID}`, {
          state: { fromUserPortal: false, status },
        });
  };
  const handleCloseAssignPopup = () => {
    setShowAssignPopup(false);
  };
  useEffect(() => {
    if (assigned_supervisor !== null && !showAssignPopup) {
      if (selectedSection === "Waiting") {
        setLabelText("Submitted Tracks");
        dispatch(getAllTracksFetch("submitted", "supervisor_id", 0, 10));
      } else if (selectedSection === "Assigned") {
        setLabelText("Assigned Tracks");
        dispatch(getAllTracksFetch("Assigned", "supervisor_id", 0, 10));
      } else if (selectedSection === "UnderReview") {
        setLabelText("Under Review Tracks");
        dispatch(getAllTracksFetch("in_review", "supervisor_id", 0, 10));
      } else if (selectedSection === "UnderMasterReview") {
        setLabelText("Completed Tracks");
        dispatch(
          getAllTracksFetch("feedback_submitted", "supervisor_id", 0, 10)
        );
      } else if (selectedSection === "Pending") {
        setLabelText("Externally Reviewed Tracks");
        dispatch(getAllTracksFetch("Pending", "supervisor_id", 0, 10));
      } else if (selectedSection === "Delivered") {
        setLabelText("Delivered Tracks");
        dispatch(
          getAllTracksFetch("final_feedback_submitted", "supervisor_id", 0, 10)
        );
      }
    }
  }, [assigned_supervisor]);

  function debounce(func, delay) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  }

  const handleScroll = debounce((e) => {
    e.stopPropagation();

    if (tracksRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = tracksRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        setOffset((prevOffset) => {
          const newOffset = prevOffset + 10;
          if (assigned_supervisor !== null) {
            if (selectedSection === "Waiting") {
              setLabelText("Submitted Tracks");
              dispatch(
                getAllTracksFetch("submitted", "supervisor_id", newOffset, 10)
              );
            } else if (selectedSection === "Assigned") {
              setLabelText("Assigned Tracks");
              dispatch(
                getAllTracksFetch("Assigned", "supervisor_id", newOffset, 10)
              );
            } else if (selectedSection === "UnderReview") {
              setLabelText("Under Review Tracks");
              dispatch(
                getAllTracksFetch("in_review", "supervisor_id", newOffset, 10)
              );
            } else if (selectedSection === "UnderMasterReview") {
              setLabelText("Completed Tracks");
              dispatch(
                getAllTracksFetch(
                  "feedback_submitted",
                  "supervisor_id",
                  newOffset,
                  10
                )
              );
            } else if (selectedSection === "Pending") {
              setLabelText("Externally Reviewed Tracks");
              dispatch(
                getAllTracksFetch("Pending", "supervisor_id", newOffset, 10)
              );
            } else if (selectedSection === "Delivered") {
              setLabelText("Delivered Tracks");
              dispatch(
                getAllTracksFetch(
                  "final_feedback_submitted",
                  "supervisor_id",
                  newOffset,
                  10
                )
              );
            }
          }

          return newOffset;
        });
      }
    }
  }, 600);

  useEffect(() => {
    const currentRef = tracksRef.current;

    if (currentRef) {
      const preventScroll = (e) => {
        const { scrollTop, scrollHeight, clientHeight } = currentRef;

        if (
          (scrollTop <= 0 && e.deltaY < 0) || // At top and scrolling up
          (scrollTop + clientHeight >= scrollHeight && e.deltaY > 0) // At bottom and scrolling down
        ) {
          e.preventDefault();
        }
      };
      hasMore &&
        currentRef.addEventListener("wheel", preventScroll, { passive: false });
      hasMore && currentRef.addEventListener("scroll", handleScroll);

      return () => {
        currentRef.removeEventListener("wheel", preventScroll);
        currentRef.removeEventListener("scroll", handleScroll);
      };
    }
  });

  const handleStatus = () => {
    if (currentStatus === "submitted") {
      setCurrentStatus("in_review");
    }
  };
  return (
    <>
      {showAssignPopup && (
        <SupAssignPopup
          trackId={trackId}
          onClose={handleCloseAssignPopup}
          handleTrackFeedback={handleTrackFeedback}
          currentStatus={currentStatus}
          handleStatus={handleStatus}
        />
      )}
      <div
        className="vh-100 px-3"
        style={{ background: "white", color: "black", overflowY: "auto" }}
        ref={tracksRef}
      >
        <SUPHeader />
        <div className=" padding-0">
          <div className="bg-main-div mt-3 ">
            <Row className="mb-3 supervisor-dashboard-container ">
              <Col
                className="d-flex justify-content-center align-items-center py-3"
                style={{ borderRight: "3px solid #FFFFFF" }}
              >
                <div className="box d-flex justify-content-between align-items-center supd-stats">
                  <div>
                    <SMLLabel
                      style="title4 marginRight"
                      value={stats?.submitted}
                    ></SMLLabel>
                  </div>
                  <div>
                    <SMLLabel
                      style="title"
                      value="Track Waiting for Reviews"
                    ></SMLLabel>
                  </div>
                </div>
              </Col>
              <Col
                className="d-flex justify-content-center align-items-center py-3"
                style={{ borderRight: "3px solid #FFFFFF" }}
              >
                <div className="box d-flex justify-content-between align-items-center supd-stats">
                  <div>
                    <SMLLabel
                      style="title4 marginRight"
                      value={stats?.under_review}
                    ></SMLLabel>
                  </div>
                  <div>
                    <SMLLabel
                      style="title"
                      value="Track Under Reviews"
                    ></SMLLabel>
                  </div>
                </div>
              </Col>

              <Col className="d-flex justify-content-center align-items-center py-3">
                <div className="box d-flex justify-content-between align-items-center supd-stats">
                  <div>
                    <SMLLabel
                      style="title4 marginRight"
                      value={stats?.feedback_complete}
                    ></SMLLabel>
                  </div>
                  <div>
                    <SMLLabel
                      style="title"
                      value="Feedback Submitted"
                    ></SMLLabel>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div
            className="bg-main-div mt-3 mb-3 bg-main-res"
            style={{ backgroundColor: "#f8f9fa" }}
          >
            <Row className="mb-3">
              <Col>
                <div className="supd-header2">
                  {loading && (
                    <div className="spinner-overlay">
                      <Bars
                        height="80"
                        width="80"
                        color="#23f0c7"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </div>
                  )}
                  <SMLLabel style="title1" value={labelText} />
                  <div className="supd-heading">
                    <button
                      className={
                        selectedSection === "Waiting"
                          ? "SMLToggleBtn-orange"
                          : "SMLToggleBtn-white"
                      }
                      onClick={() => handleSectionChange("Waiting")}
                    >
                      Submitted
                    </button>
                    {isMaster === "true" ? (
                      <button
                        className={
                          selectedSection === "Assigned"
                            ? "SMLToggleBtn-orange"
                            : "SMLToggleBtn-white"
                        }
                        onClick={() => handleSectionChange("Assigned")}
                      >
                        Assigned
                      </button>
                    ) : (
                      <></>
                    )}
                    <button
                      className={
                        selectedSection === "UnderReview"
                          ? "SMLToggleBtn-orange ml-2 mr-2"
                          : "SMLToggleBtn-white ml-2 mr-2"
                      }
                      onClick={() => handleSectionChange("UnderReview")}
                    >
                      Under Review
                    </button>
                    {isMaster === "false" && (
                      <button
                        className={
                          selectedSection === "UnderMasterReview"
                            ? "SMLToggleBtn-orange ml-2 mr-2"
                            : "SMLToggleBtn-white ml-2 mr-2"
                        }
                        onClick={() => handleSectionChange("UnderMasterReview")}
                      >
                        Completed
                      </button>
                    )}
                    {isMaster === "true" ? (
                      <button
                        className={
                          selectedSection === "Pending"
                            ? "SMLToggleBtn-orange ml-2 mr-2"
                            : "SMLToggleBtn-white ml-2 mr-2"
                        }
                        onClick={() => handleSectionChange("Pending")}
                      >
                        Externally Reviewed
                      </button>
                    ) : (
                      <></>
                    )}
                    {isMaster === "true" ? (
                    <button
                      className={
                        selectedSection === "Delivered"
                          ? "SMLToggleBtn-orange"
                          : "SMLToggleBtn-white"
                      }
                      onClick={() => handleSectionChange("Delivered")}
                      >
                        Delivered
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            {selectedSection === "Waiting" && (
              <div className="Waiting">
                {tracks && Array.isArray(tracks) && tracks.length > 0 ? (
                  tracks.map((track, index) => (
                    <React.Fragment key={index}>
                      <TrackSection
                        track={track}
                        handleTrackFeedback={handleTrackFeedback}
                        trackImg={trackImg}
                        audioSrc={audioSrc}
                        mediaRef={mediaRef}
                        handleTimeUpdate={handleTimeUpdate}
                        handleTogglePlay={handleTogglePlay}
                        isPlaying={isPlaying}
                        progress={progress}
                        onClick={handleShowAssignPopup}
                        handleShowAssignPopup={handleShowAssignPopup}
                      />
                      <div style={{ marginBottom: "20px" }} />
                    </React.Fragment>
                  ))
                ) : (
                  <div style={{ textAlign: "center", padding: "0  0 20px 0" }}>
                    No tracks available
                  </div>
                )}
              </div>
            )}
            {selectedSection === "Assigned" && (
              <div className="Assigned">
                {tracks && Array.isArray(tracks) && tracks.length > 0 ? (
                  tracks.map((track, index) => (
                    <React.Fragment key={index}>
                      <TrackSection
                        track={track}
                        handleTrackFeedback={handleTrackFeedback}
                        trackImg={trackImg}
                        audioSrc={audioSrc}
                        mediaRef={mediaRef}
                        handleTimeUpdate={handleTimeUpdate}
                        handleTogglePlay={handleTogglePlay}
                        isPlaying={isPlaying}
                        progress={progress}
                        onClick={handleShowAssignPopup}
                        handleShowAssignPopup={handleShowAssignPopup}
                      />
                      <div style={{ marginBottom: "20px" }} />
                    </React.Fragment>
                  ))
                ) : (
                  <div style={{ textAlign: "center", padding: "0  0 20px 0" }}>
                    No tracks available
                  </div>
                )}
              </div>
            )}
            {selectedSection === "UnderReview" && (
              <div className="UnderReview" onClick={handleTrackFeedback}>
                {tracks && Array.isArray(tracks) && tracks.length > 0 ? (
                  tracks.map((track, index) => (
                    <React.Fragment key={index}>
                      <TrackSection
                        track={track}
                        handleTrackFeedback={handleTrackFeedback}
                        trackImg={trackImg}
                        audioSrc={audioSrc}
                        mediaRef={mediaRef}
                        handleTimeUpdate={handleTimeUpdate}
                        handleTogglePlay={handleTogglePlay}
                        isPlaying={isPlaying}
                        progress={progress}
                      />
                      <div style={{ marginBottom: "20px" }} />
                    </React.Fragment>
                  ))
                ) : (
                  <div style={{ textAlign: "center", padding: "0  0 20px 0" }}>
                    No tracks available
                  </div>
                )}
              </div>
            )}
            {selectedSection === "UnderMasterReview" && (
              <div className="UnderMasterReview" onClick={handleTrackFeedback}>
                {tracks && Array.isArray(tracks) && tracks.length > 0 ? (
                  tracks.map((track, index) => (
                    <React.Fragment key={index}>
                      <TrackSection
                        track={track}
                        handleTrackFeedback={handleTrackFeedback}
                        trackImg={trackImg}
                        audioSrc={audioSrc}
                        mediaRef={mediaRef}
                        handleTimeUpdate={handleTimeUpdate}
                        handleTogglePlay={handleTogglePlay}
                        isPlaying={isPlaying}
                        progress={progress}
                      />
                      <div style={{ marginBottom: "20px" }} />{" "}
                    </React.Fragment>
                  ))
                ) : (
                  <div style={{ textAlign: "center", padding: "0  0 20px 0" }}>
                    No tracks available
                  </div>
                )}
              </div>
            )}
            {selectedSection === "Pending" && (
              <div className="Pending" onClick={handleTrackFeedback}>
                {tracks && Array.isArray(tracks) && tracks.length > 0 ? (
                  tracks.map((track, index) => (
                    <React.Fragment key={index}>
                      <TrackSection
                        track={track}
                        handleTrackFeedback={handleTrackFeedback}
                        trackImg={trackImg}
                        audioSrc={audioSrc}
                        mediaRef={mediaRef}
                        handleTimeUpdate={handleTimeUpdate}
                        handleTogglePlay={handleTogglePlay}
                        isPlaying={isPlaying}
                        progress={progress}
                      />
                      <div style={{ marginBottom: "20px" }} />
                    </React.Fragment>
                  ))
                ) : (
                  <div style={{ textAlign: "center", padding: "0  0 20px 0" }}>
                    No tracks available
                  </div>
                )}
              </div>
            )}
            {selectedSection === "Delivered" && (
              <div className="Delivered" onClick={handleTrackFeedback}>
                {tracks && Array.isArray(tracks) && tracks.length > 0 ? (
                  tracks.map((track, index) => (
                    <React.Fragment key={index}>
                      <TrackSection
                        track={track}
                        handleTrackFeedback={handleTrackFeedback}
                        trackImg={trackImg}
                        audioSrc={audioSrc}
                        mediaRef={mediaRef}
                        handleTimeUpdate={handleTimeUpdate}
                        handleTogglePlay={handleTogglePlay}
                        isPlaying={isPlaying}
                        progress={progress}
                      />
                      <div style={{ marginBottom: "20px" }} />
                    </React.Fragment>
                  ))
                ) : (
                  <div style={{ textAlign: "center", padding: "0  0 20px 0" }}>
                    No tracks available
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SupDashboard;

// components/Appointment/CalendlyConsultation/CalendlyConsultation.js
import React, { useEffect, useState } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import SMLLabel from '../../../components/Shared/SMLLabel/SMLLabel';
import { Bars } from 'react-loader-spinner';

const CalendlyConsultation = ({ 
  isOpen, 
  onClose, 
  supervisorName = "Supervisor",
  artistName,
  artistEmail,
  trackTitle 
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    if (isOpen && !isScriptLoaded) {
      // Remove any existing Calendly widgets
      const existingWidgets = document.querySelectorAll('.calendly-inline-widget');
      existingWidgets.forEach(widget => {
        if (widget.parentElement) {
          widget.parentElement.removeChild(widget);
        }
      });

      // Load Calendly script
      const script = document.createElement('script');
      script.src = 'https://assets.calendly.com/assets/external/widget.js';
      script.async = true;
      script.onload = () => {
        setIsScriptLoaded(true);
        if (window.Calendly) {
          setTimeout(() => {
            window.Calendly.initInlineWidget({
              url: 'https://calendly.com/syncmusiclab-contact/30min',
              parentElement: document.getElementById('calendlyContainer'),
              prefill: {
                name: artistName || '', // Make sure artistName is passed correctly
                email: artistEmail || '',
                customAnswers: {
                  a1: trackTitle ? `Track Title: ${trackTitle}` : ''
                }
              },
              styles: {
                height: '100%'
              }
            });
            setIsLoading(false);
          }, 100);
        }
      };
      document.body.appendChild(script);

      return () => {
        // Cleanup
        if (script.parentNode) {
          script.parentNode.removeChild(script);
        }
        setIsScriptLoaded(false);
        setIsLoading(true);
      };
    }
  }, [isOpen, artistName, artistEmail, trackTitle]);

  if (!isOpen) return null;

  return (
    <div className="popup-background-blur" style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000
    }}>
      <div className="ArtistProfile-popup" style={{
        width: '95%', // Increased from 100%
        maxWidth: '1200px', // Increased from 900px
        height: '90vh', // Increased from 700px
        background: '#FFFFFF',
        borderRadius: '8px',
        overflow: 'hidden',
        margin: '20px auto' // Added margin
      }}>
        <div className="signUp-groupby" style={{ 
          padding: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <div className="track-detail-header">
            <SMLLabel 
              value="Schedule Video Consultation" 
              style="Mainheader" 
            />
          </div>
          <div 
            className="signUp-close-div" 
            onClick={onClose}
            style={{ cursor: 'pointer' }}
          >
            <MdOutlineClose size={24} />
          </div>
        </div>
  
        <div style={{ 
          height: 'calc(100% - 80px)', 
          position: 'relative',
          minHeight: '700px' // Added minimum height
        }}>
          {isLoading && (
            <div style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '1rem'
            }}>
              <Bars
                height="80"
                width="80"
                color="#FF4405"
                ariaLabel="loading-indicator"
              />
              <div>Loading calendar...</div>
            </div>
          )}
          <div 
            id="calendlyContainer"
            style={{ 
              width: '100%', 
              height: '100%',
              border: 'none',
              visibility: isLoading ? 'hidden' : 'visible'
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CalendlyConsultation;
import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import closeIcon from "../../../assets/Images/closeIcon.svg";
import "./CancelSubPopup.css";
import { useDispatch } from "react-redux";
import { cancelSubRequest } from "../../../redux/actions";

const CancelSubPopup = ({ onClose }) => {
  const dispatch = useDispatch();
  const [reason, setReason] = useState("");
  const [description, setDescription] = useState("");

  return (
    <Modal
      show={true}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="cancel-sub-modal"
    >
      <div className="modal-header">
        <div className="flex flex-col justify-content-start align-items-center">
          <h1 className="modal-title">Cancel Plan</h1>
          <p>
            Cancelling will stop the recurring billing immediately. You will
            still keep you access until ....{" "}
          </p>
        </div>
        <img
          src={closeIcon}
          alt="Close"
          onClick={onClose}
          className="close-icon"
        />
      </div>
      <div className="modal-body">
        <Form>
          <Form.Group>
            <Form.Label>What is your main reason for cancelling?</Form.Label>
            <Form.Control
              as="select"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              placeholder="Select a reason"
            >
              <option value="">Select option</option>
              <option value="Too Expensive">Too Expensive</option>
              <option value="Not using the service">
                Not using the service
              </option>
              <option value="Found a better alternative">
                Found a better alternative
              </option>
              <option value="Dissatisfied with features">
                Dissatisfied with features
              </option>
              <option value="Other">Other</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="mt-5">
            <Form.Label>
              Please briefly describe what led to this decision?
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="e.g. reasons for cancel"
              maxLength="500"
            />
          </Form.Group>
          <div style={{ display: "flex", justifyContent: "end" }}>
            {description.length}/500
          </div>
          {/* <p className="confirmation-text">
            Are you sure you want to cancel your subscription?
          </p> */}

          <div className="modal-footer mt-5">
            <Button variant="secondary" onClick={onClose}>
              Go Back
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                dispatch(cancelSubRequest("123", reason, description));
              }}
            >
              Cancel Plan
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default CancelSubPopup;

import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useWavesurfer } from "@wavesurfer/react";
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions.esm.js";
import { Box, Stack, IconButton, Typography } from "@mui/material";
import { Edit, Close } from "@mui/icons-material";

import HomeLayout from "../../components/Layout/HomeLayout";
import TrackDetailsHeader from "../../components/common/track-details/TrackDetailsHeader";
import TrackDetailsNavigation from "../../components/common/track-details/TrackDetailsNaviagtion";
import TrackPlayer from "../../components/common/track-details/TrackPlayer";
import UnderReviewIcon from "../../assets/Icons/under-review.svg";
import CommentIcon from "../../assets/Icons/comment.svg";
import CommentGrayIcon from "../../assets/Icons/comment-grey.svg";
import AddNoteIcon from "../../assets/Icons/addNote.svg";
import EnhancedCustomTimePicker from "../../components/Shared/TimeInput/EnhancedCustomTimePicker";

import { addCommentApi } from "../../api/supervisor/comment";
import { getSingleTrackApi } from "../../api/track/getSingleTrack";
import { downloadTrackApi } from "../../api/track/downloadTrackApi";

import "./AudioGraph.css";
import { Bars } from "react-loader-spinner";

export const formatTime = (seconds) => {
  const pad = (num, size) => num.toString().padStart(size, "0");
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${pad(minutes, 2)}:${pad(remainingSeconds, 2)}`;
};

export const parseTime = (timeString) => {
  const parts = timeString.split(":");
  const minutes = parseInt(parts[0], 10) || 0; // Default to 0 if NaN
  const seconds = parseInt(parts[1], 10) || 0; // Default to 0 if NaN
  return minutes * 60 + seconds; // Convert to total seconds
};

export default function TrackUnderReview() {
  const { trackId } = useParams();
  const [track, setTrack] = useState();
  const [trackBlobUrl, setTrackBlobUrl] = useState();

  useEffect(() => {
    getSingleTrackApi(trackId).then(setTrack);
    downloadTrackApi({ trackId }).then(({ audio_signed_url }) => {
      setTrackBlobUrl(audio_signed_url);
    });
  }, [trackId]);

  const wavesurfRef = useRef(null);
  const regions = useMemo(() => RegionsPlugin.create(), []);

  const { wavesurfer, isPlaying } = useWavesurfer({
    container: wavesurfRef,
    waveColor: "#CBCBCB",
    progressColor: "#FF4405",
    cursorColor: "#FF4405",
    selectionColor: "#FF4405",
    barWidth: 3,
    height: 150,
    url: trackBlobUrl,
    plugins: useMemo(() => [regions], [regions]),
  });
  const [comments, setComments] = useState([]);
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [showAddCommentButton, setShowAddCommentButton] = useState(false);
  const [currentComment, setCurrentComment] = useState("");
  const inputRef = useRef(null);
  const [commentPosition, setCommentPosition] = useState(0);
  const commentBoxPosition = useMemo(() => {
    if (!wavesurfRef.current) return 0;
    const rect = wavesurfRef.current.getBoundingClientRect();
    const x = (commentPosition / wavesurfer.getDuration()) * rect.width;
    return Math.min(x, rect.width - 300);
  }, [commentPosition, wavesurfer, wavesurfRef]);

  const [showGuide, setShowGuide] = useState(true);
  const [focusedComment, setFocusedComment] = useState(null);

  useEffect(() => {
    if (!!wavesurfer && focusedComment !== null) {
      if (typeof comments[focusedComment].time === "number") {
        wavesurfer.setTime(comments[focusedComment].time);
      } else {
        regions.clearRegions();
        regions.addRegion({
          start: comments[focusedComment].time[0],
          end: comments[focusedComment].time[1],
          color: "#ff440540",
          resize: false,
        });
      }
    }
  }, [focusedComment, wavesurfer, regions, comments]);

  useEffect(() => {
    if (comments.length > 0) setShowGuide(false);
  }, [comments]);

  const [selcetedPart, setSelectedPart] = useState();
  const [activePosition, setActivePosition] = useState();
  const [activeRegion, setActiveRegion] = useState();

  useEffect(() => {
    if (!!wavesurfer) {
      regions.enableDragSelection({
        color: "#ff440540",
      });

      regions.on("region-created", (newRegion) => {
        const allRegions = regions.getRegions();
        allRegions.forEach((region) => {
          if (newRegion !== region) region.remove();
        });
        newRegion.play();

        setSelectedPart("region");
        setActiveRegion([newRegion.start, newRegion.end]);
        setCommentPosition(newRegion.start);
        setShowAddCommentButton(true);
        setShowCommentBox(false);
      });

      regions.on("region-updated", (updatedRegion) => {
        updatedRegion.play();

        setSelectedPart("region");
        setActiveRegion([updatedRegion.start, updatedRegion.end]);
        setCommentPosition(updatedRegion.start);
        setShowAddCommentButton(true);
        setShowCommentBox(false);
      });

      regions.on("region-clicked", (region, e) => {
        e.stopPropagation();
        region.play();
      });
    }
  }, [wavesurfRef, wavesurfer, regions]);

  useEffect(() => {
    if (!!wavesurfer)
      wavesurfRef.current.addEventListener("click", (event) => {
        if (selcetedPart === "region") regions.clearRegions();
        setSelectedPart("point");
        setActivePosition(wavesurfer.getCurrentTime());
        setCommentPosition(wavesurfer.getCurrentTime());
        setShowAddCommentButton(true);
        setShowCommentBox(false);
      });
  }, [
    wavesurfRef,
    wavesurfer,
    regions,
    setSelectedPart,
    setActivePosition,
    selcetedPart,
  ]);

  useEffect(() => {
    if (!showCommentBox) {
      setActivePosition(undefined);
      setActiveRegion(undefined);
      setSelectedPart(undefined);
      setCurrentComment("");
    } else if (inputRef.current) {
      setShowGuide(false);
      inputRef.current.focus();
    }
  }, [showCommentBox]);

  const handleCommentSubmit = (event) => {
    if (event.key === "Enter" && currentComment.trim()) {
      let newComment;
      if (selcetedPart === "point") {
        newComment = {
          time: activePosition,
          transcript: currentComment,
          idSuffix: `${comments.length}-${activePosition}`,
        };
        addCommentApi({
          trackId,
          startTime: activePosition,
          endTime: activePosition,
          comment: currentComment,
        });
      } else if (selcetedPart === "region") {
        newComment = {
          time: activeRegion,
          transcript: currentComment,
          idSuffix: `${comments.length}-${activePosition}`,
        };
        addCommentApi({
          trackId,
          startTime: activeRegion[0],
          endTime: activeRegion[1],
          comment: currentComment,
        });
      }

      setComments((prevSentiments) => {
        const updatedSentiments = [...prevSentiments, newComment];
        return updatedSentiments;
      });

      setShowCommentBox(false);
      setShowAddCommentButton(false);
    }
  };

  return (
    <HomeLayout>
      {track ? (
        <Box
          sx={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <Stack>
            <TrackDetailsNavigation
              text={"Under Review"}
              textIconSrc={UnderReviewIcon}
            />
            <TrackDetailsHeader />
          </Stack>
          <Stack
            sx={{
              flexGrow: 1,
              height: "100%",
              maxHeight: "100%",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                position: "relative",
                height: "100%",
                maxHeight: "100%",
                overflow: "hidden",
                paddingTop: "30px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <div style={{ position: "relative", height: "30px" }}>
                {(showCommentBox || showAddCommentButton) &&
                  focusedComment === null &&
                  (selcetedPart === "point" ? (
                    <div
                      className="time-box"
                      style={{ left: commentBoxPosition }}
                    >
                      <EnhancedCustomTimePicker
                        time={formatTime(commentPosition)}
                        setTime={(timeString) => {
                          const newPoint = parseTime(timeString);
                          setCommentPosition(newPoint);
                          if (!!wavesurfer) wavesurfer.setTime(newPoint);
                        }}
                      />
                    </div>
                  ) : selcetedPart === "region" ? (
                    <div
                      className="time-box"
                      style={{ left: commentBoxPosition }}
                    >
                      <EnhancedCustomTimePicker
                        time={formatTime(activeRegion[0])}
                        setTime={(timeString) => {
                          const newPoint = parseTime(timeString);
                          setActiveRegion([newPoint, activeRegion[1]]);
                          const allRegions = regions.getRegions();
                          if (allRegions.length > 0) {
                            allRegions[0].setOptions({
                              start: newPoint,
                            });
                            setCommentPosition(newPoint);
                          }
                        }}
                      />{" "}
                      -{" "}
                      <EnhancedCustomTimePicker
                        time={formatTime(activeRegion[1])}
                        setTime={(timeString) => {
                          const newPoint = parseTime(timeString);
                          setActiveRegion([activeRegion[0], newPoint]);
                          const allRegions = regions.getRegions();
                          if (allRegions.length > 0) {
                            allRegions[0].setOptions({
                              end: newPoint,
                            });
                          }
                        }}
                      />
                    </div>
                  ) : null)}
                {wavesurfer &&
                  comments.map((comment, index) => (
                    <div
                      onClick={() => {
                        document
                          .getElementById(`transcript-${comment.idSuffix}`)
                          .scrollIntoView({ behavior: "smooth" });
                        setFocusedComment(index);
                        regions.clearRegions();
                      }}
                      key={`comment-${comment.idSuffix}`}
                      style={{
                        position: "absolute",
                        left: `${
                          ((typeof comment.time === "object"
                            ? comment.time[0]
                            : comment.time) /
                            wavesurfer.getDuration()) *
                          100
                        }%`,
                        top: "3px",
                        height: "24px",
                        width: " 24px",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={
                          focusedComment === index
                            ? CommentIcon
                            : CommentGrayIcon
                        }
                        alt="Comment"
                      />
                    </div>
                  ))}
              </div>
              <div id="waveform" ref={wavesurfRef}></div>
              <div style={{ position: "relative", height: "20px" }}>
                {showCommentBox && focusedComment === null && (
                  <div
                    className="comment-box"
                    style={{ left: commentBoxPosition }}
                  >
                    <div className="comment-header">
                      <span className="timestamp">
                        <div style={{ height: "24px", width: " 24px" }}>
                          <img src={CommentIcon} alt="Comment" />
                        </div>
                        {selcetedPart === "point"
                          ? `${formatTime(activePosition)}`
                          : selcetedPart === "region"
                          ? `${formatTime(activeRegion[0])} - ${formatTime(
                              activeRegion[1]
                            )}`
                          : ""}
                      </span>
                      <IconButton
                        sx={{ padding: 0 }}
                        onClick={() => {
                          if (selcetedPart === "region") regions.clearRegions();
                          setShowCommentBox(false);
                          setShowAddCommentButton(false);
                        }}
                      >
                        <Close />
                      </IconButton>
                    </div>
                    <label>Name</label>
                    <textarea
                      ref={inputRef}
                      type="text"
                      value={currentComment}
                      onChange={(e) => setCurrentComment(e.target.value)}
                      onKeyPress={handleCommentSubmit}
                      placeholder="Type your note..."
                    />
                  </div>
                )}
                {showAddCommentButton && focusedComment === null && (
                  <div
                    className="add-note"
                    style={{ left: commentBoxPosition - 16 }}
                    onClick={() => {
                      setShowAddCommentButton(false);
                      setShowCommentBox(true);
                    }}
                  >
                    <img src={AddNoteIcon} alt="Comment" />
                    Add Note
                  </div>
                )}
              </div>
              <div id="descriptions">
                {showGuide && (
                  <div className="guide-note">
                    <IconButton
                      sx={{
                        padding: 0,
                        position: "absolute",
                        top: 8,
                        right: 8,
                        color: "white",
                      }}
                      onClick={() => {
                        setShowGuide(false);
                      }}
                    >
                      <Close />
                    </IconButton>
                    <Typography
                      sx={{
                        color: "white",
                        width: "100%",
                        padding: "16px",
                        textWrap: "wrap",
                      }}
                    >
                      Add a note by clicking to select a point in the timeline,
                      or by clicking and dragging to select a snippet. Your
                      notes will be viewed by our music supervisors when you
                      submit the track for feedback.
                    </Typography>
                  </div>
                )}
                {comments.map((comment, index) =>
                  focusedComment !== index ? (
                    <div
                      id={`transcript-${comment.idSuffix}`}
                      key={`transcript-${comment.idSuffix}`}
                      className="input-track-note"
                      style={{
                        opacity: focusedComment !== null ? 0.25 : 1,
                      }}
                    >
                      <div className="comment-header">
                        <span className="timestamp">
                          <div style={{ height: "24px", width: " 24px" }}>
                            <img src={CommentIcon} alt="Comment" />
                          </div>
                          {typeof comment.time === "object"
                            ? `${formatTime(comment.time[0])} - ${formatTime(
                                comment.time[1]
                              )}`
                            : `${formatTime(comment.time)}`}
                        </span>
                        <div>
                          <IconButton
                            sx={{ padding: 0.5 }}
                            onClick={() => setFocusedComment(index)}
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            sx={{ padding: 0.5 }}
                            onClick={() =>
                              setComments([
                                ...comments.slice(0, index),
                                ...comments.slice(index + 1),
                              ])
                            }
                          >
                            <Close />
                          </IconButton>
                        </div>
                      </div>
                      <div className="author">Sync Music Lab</div>
                      <div className="transcript">{comment.transcript}</div>
                    </div>
                  ) : (
                    <div
                      id={`transcript-${comment.idSuffix}`}
                      key={`transcript-${comment.idSuffix}`}
                      className="input-track-note"
                    >
                      <div className="comment-header">
                        <span className="timestamp">
                          <div style={{ height: "24px", width: " 24px" }}>
                            <img src={CommentIcon} alt="Comment" />
                          </div>
                          {typeof comment.time === "object"
                            ? `${formatTime(comment.time[0])} - ${formatTime(
                                comment.time[1]
                              )}`
                            : `${formatTime(comment.time)}`}
                        </span>
                        <IconButton
                          sx={{ padding: 0 }}
                          onClick={() => {
                            setFocusedComment(null);
                            regions.clearRegions();
                            setFocusedComment(null);
                          }}
                        >
                          <Close />
                        </IconButton>
                      </div>
                      <label>Name</label>
                      <textarea
                        ref={inputRef}
                        type="text"
                        value={comment.transcript}
                        onChange={(e) => {
                          setComments([
                            ...comments.slice(0, index),
                            {
                              ...comments,
                              transcript: setCurrentComment(e.target.value),
                            },
                            ...comments.slice(index + 1),
                          ]);
                        }}
                        onKeyPress={() => {
                          setFocusedComment(null);
                        }}
                        placeholder="Type your note..."
                      />
                    </div>
                  )
                )}
              </div>
            </div>
          </Stack>
          <Stack
            sx={{
              margin: "0 -30px -30px",
            }}
          >
            <TrackPlayer
              track={track}
              isPlaying={isPlaying}
              wavesurfer={wavesurfer}
            />
          </Stack>
        </Box>
      ) : (
        <div className="spinner-overlay">
          <Bars
            height="80"
            width="80"
            color="#23f0c7"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
    </HomeLayout>
  );
}

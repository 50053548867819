import { call, put, takeLatest } from "redux-saga/effects";
import {
  FORGET_FINAL_PASSWORD_FAILURE,
  FORGET_FINAL_PASSWORD_LOADING,
  FORGET_FINAL_PASSWORD_REQUEST,
  FORGET_FINAL_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILURE,
  FORGET_PASSWORD_LOADING,
  FORGET_PASSWORD_REQUEST,
  FORGET_PASSWORD_SUCCESS,
} from "../actions/actionTypes";
import { forgetPassApi, forgetPassFinalApi } from "../../api/forgetPassword/forgetPassword";

function* forgetPass(action) {
  try {
    yield put({ type: FORGET_PASSWORD_LOADING });
    const email = action.payload;
    const message = yield call(forgetPassApi, email);
    yield put({ type: FORGET_PASSWORD_SUCCESS, message });
  } catch (error) {
    const errorMessage = error.response?.data || "An unknown error occured";
    yield put({ type: FORGET_PASSWORD_FAILURE, error: errorMessage });
  }
}

function* forgetPassFinal(action) {
  try {
    yield put({ type: FORGET_FINAL_PASSWORD_LOADING });
    const data = action.payload;
    const message = yield call(forgetPassFinalApi, data);
    yield put({ type: FORGET_FINAL_PASSWORD_SUCCESS, message });
  } catch (error) {
    const errorMessage = error.response?.data || "An unknown error occured";
    yield put({ type: FORGET_FINAL_PASSWORD_FAILURE, error: errorMessage });
  }
}

function* forgetPasswordSaga() {
  yield takeLatest(FORGET_PASSWORD_REQUEST, forgetPass);
  yield takeLatest(FORGET_FINAL_PASSWORD_REQUEST, forgetPassFinal)
}

export default forgetPasswordSaga;

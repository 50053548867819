import api from "../api";
const BASE_URL = process.env.REACT_APP_API_URL;
// Function to Update Final Feedback
export const putFinalFeedbackMsApi = async (data) => {
  try {
    const trackId = data.track_id;
    const feedbackData = { final_feedback: data.feedback_data };
    const accessToken = localStorage.getItem("token");
    if (!accessToken) {
      console.error("No token found. Please log in.");
      return;
    }
    const url = `${BASE_URL}/feedback/${trackId}/master-supervisor/final`;
    const config = {
      method: "put",
      maxBodyLength: Infinity,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      url: url,
      data: feedbackData,
    };
    const response = await api.request(config);
    if (response.status !== 200) {
      console.error("Failed to post feedback. Status code:", response.status);
    }
    return response.data;
  } catch (error) {
    if (error.response) {
      console.error("Error response data:", error.response.data);
      console.error("Error response status:", error.response.status);
      console.error("Error response headers:", error.response.headers);
    } else if (error.request) {
      console.error("Error request data:", error.request);
    } else {
      console.error("Error message:", error.message);
    }
    console.error("Error config:", error.config);
    throw error;
  }
};

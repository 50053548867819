import {
  WEBSOCKET_REQUEST,
  WEBSOCKET_LOADING,
  WEBSOCKET_SUCCESS,
  WEBSOCKET_ERROR,
} from "./actionTypes";

export const  websocketConnect = () => ({
  type: WEBSOCKET_REQUEST,
});

export const websocketLoading = () => ({
  type: WEBSOCKET_LOADING,
});

export const websocketSuccess = (message) => ({
  type: WEBSOCKET_SUCCESS,
  payload: { message },
});

export const websocketError = (error) => ({
  type: WEBSOCKET_ERROR,
  payload: { error },
});

import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
  PaymentRequestButtonElement,
  AddressElement,
} from "@stripe/react-stripe-js";
import "./PaymentPopup.css";
import { useDispatch, useSelector } from "react-redux";
import { getPbKeyRequest } from "../../../redux/actions";
import {
  createSubReqeuest,
  getClientSecret,
  getPlansRequest,
  setSelectedPlan,
} from "../../../redux/actions/paymentActions";
import {
  signupRequest,
  socialSignupRequest,
} from "../../../redux/actions/authActions";
import { useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import ProgressSteps from "../../../pages/SignUpPage/ProgressSteps/ProgressSteps";
import closeIcon from "../../../assets/Images/closeIcon.svg";
import Backbutton from "../../Shared/BackButton/BackButton";
import SMLButton from "../../Shared/SMLButton/SMLButton";
import plans from "../../../pages/SignUpPage/ChoosePlan/ChoosePlan.json";
import checkBox from "../../../assets/Images/checkboxIcon.svg";
const appearance = {
  theme: "stripe",
  variables: {
    fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
    fontSizeBase: "16px",
    colorPrimary: "#4a90e2",
    colorBackground: "#f5f5f5",
    borderRadius: "8px",
    spacingUnit: "4px",
  },
};

const CheckoutForm = ({
  dpmCheckerLink,
  contactInfo,
  aboutYouData,
  selectedPlan,
  isPaymentTriggered,
  handlePayment,
  nextClick,
  upgrade,
}) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const clientSecret = useSelector(
    (state) => state.payment?.getClientSecretReducer?.secret
  );

  const handleError = (error) => {
    setMessage(error.message);
    setIsLoading(false);
  };

  const handleSubmit = async (event) => {
    if (!stripe || !elements) {
      setMessage("Stripe.js hasn't loaded yet. Please try again.");
      handlePayment();
      return;
    }
    setIsLoading(true);
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      handlePayment();
      return;
    }
    // console.log(selectedPlan)
    dispatch(setSelectedPlan(selectedPlan));
    dispatch(
      getClientSecret({
        amount: selectedPlan.amount,
        currency: selectedPlan.currency,
      })
    );
    if (!clientSecret) {
      setMessage("Client secret is not available.");
      setIsLoading(false);
      return;
    }
    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {},
      redirect: "if_required",
    });
    if (error) {
      handleError(error);
    } else {
      setMessage("Payment successful!");
      if (upgrade) {
        dispatch(
          createSubReqeuest({
            id: artists?._id,
            email: artists?.email,
            priceId: selectedPlan?.id,
            methodId: "pm_card_visa",
          })
        );
      } else {
        dispatch(
          createSubReqeuest({
            id: artistSignedUp?.profile?._id,
            email: artistSignedUp?.profile?.email,
            priceId: selectedPlan?.id,
            methodId: "pm_card_visa",
          })
        );
      }
    }
  };
  const auth = useSelector((state) => state.auth);
  const { loading, artistSocialSignedIn, artistSignedUp } = useSelector(
    (state) => state.auth
  );
  const { artists } = useSelector((state) => state.sml.allArtistFetched);
  useEffect(() => {
    if (isPaymentTriggered && stripe && elements) {
      handleSubmit();
    }
  }, [isPaymentTriggered]);
  useEffect(() => {
    if (elements) {
      const element = elements.getElement("payment");
      element.on("ready", () => {
        setIsLoading(false);
        console.log("READY");
      });
    }
  }, [elements]);

  return (
    <form id="payment-form">
      {isLoading && (
        <div className="spinner-overlay">
          <Bars
            height="80"
            width="80"
            color="#23f0c7"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
      <PaymentElement />
      <AddressElement
        options={{
          mode: "billing",

          hide: ["addressLine2"],
        }}
      />
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
};

const PaymentPopup = ({
  selectedPlan,
  contactInfo,
  aboutYouData,
  nextClick,
  closePopup,
  backClick,
  forUpgrade,
  isYearly,
}) => {
  const [stripePromise, setStripePromise] = useState(null);
  const [secret, setSecret] = useState(null);
  const [paymentTriggered, setPaymentTriggered] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handlePayment = () => {
    setPaymentTriggered(false);
  };
  useEffect(() => {
    dispatch(getPbKeyRequest());
    // console.log(selectedPlan)
    dispatch(
      getClientSecret({
        mode: "subscription",
        amount: selectedPlan.amount,
        currency: selectedPlan.currency,
      })
    );
  }, []);

  const pbKey = useSelector(
    (state) => state.payment?.PbKeyReducer?.key?.publishableKey
  );
  const clientSecret = useSelector(
    (state) => state.payment?.getClientSecretReducer?.secret
  );

  useEffect(() => {
    if (pbKey) {
      const stripe = loadStripe(pbKey);
      setStripePromise(stripe);
    }
  }, [pbKey]);

  useEffect(() => {
    if (clientSecret) {
      setSecret(clientSecret);
    }
  }, [clientSecret]);
  const auth = useSelector((state) => state.auth);
  const { loading, artistSocialSignedIn, artistSignedUp } = useSelector(
    (state) => state.auth
  );
  const subCreated = useSelector(
    (state) => state.payment?.createSubReducer?.result
  );
  useEffect(() => {
    if (subCreated.length !== 0) {
      if (artistSignedUp?.is_new_user === false) {
        navigate("/Dashboard");
      } else {
        nextClick();
      }
    }
  }, [subCreated]);
  const options = {
    clientSecret: secret,
    appearance: {
      theme: "stripe",
    },
  };
  const findMatchingPlan = (selectedPlan) => {
    const priceId = selectedPlan.id; // This is the plan's ID that was selected
    console.log("Selected Plan ID:", priceId);

    // Make sure plans are available
    if (!plans || plans.length === 0) {
      console.log("Plans array is empty or undefined.");
      return undefined;
    }

    // Log whether you're expecting yearly or monthly ID
    console.log("Is Yearly?", isYearly);

    // Check if we're correctly finding the plan
    const matchingPlan = plans.find((plan) => {
      const planIdToCompare = isYearly ? plan.YearlyId : plan.monthlyId;
      console.log("Comparing:", planIdToCompare, "with", priceId);
      return planIdToCompare === priceId;
    });

    if (!matchingPlan) {
      console.log("No matching plan found for the given ID.");
    } else {
      console.log("Matching Plan Found:", matchingPlan);
    }

    return matchingPlan;
  };

  // const findMatchingPlan = (plan) => {
  //   const priceId = selectedPlan.id
  //isYearly ? plan.YearlyId : plan.monthlyId;

  //   return plans.find((p1) => p1.id === priceId);
  // };

  const [planData, setPlanData] = useState(null);

  useEffect(() => {
    const matchingPlan = findMatchingPlan(selectedPlan);
    console.log("Matching Plan:", matchingPlan); // Log the matching plan before setting it
    setPlanData(matchingPlan); // Set the plan data once confirmed
  }, [selectedPlan]);

  useEffect(() => {
    console.log("Plan Data:", planData); // Log the plan data after it's updated
  }, [planData]);

  return (
    <div id="checkout">
      <div className="signUp-progress-steps">
        {forUpgrade ? (
          <ProgressSteps step="1" upgrade="true" />
        ) : (
          <ProgressSteps step="3" />
        )}
      </div>
      <div
        className="signUp-close-div"
        onClick={() => {
          closePopup();
          dispatch(getPlansRequest());
        }}
      >
        <img src={closeIcon} style={{ cursor: "pointer" }} alt="Close" />
      </div>

      {stripePromise && secret && (
        <Elements stripe={stripePromise} options={options}>
          <div className="d-flex justify-content-center align-items-center payment-popup">
            <div className="left-payment">
              {planData && (
                <div className="payment-plan-card">
                  <div className="plan-card-content">
                    <h2 className="title1">{planData?.name}</h2>
                    <div className="payment-price-container">
                      <p className="plan-price">
                        {isYearly ? planData?.yearlyPrice : planData?.price}
                        <span className="plan-duration">
                          {isYearly ? "/year" : "/month"}
                        </span>
                      </p>
                    </div>
                    <div className="horizontal-line"></div>
                    <ul className="features-list">
                      {planData?.features.map((feature, i) => {
                        if (typeof feature === "string") {
                          return (
                            <li key={i} className="features-item">
                              <img
                                src={checkBox}
                                alt="checkbox"
                                className="checkbox-icon"
                              />
                              <span className="feature-text">{feature}</span>
                            </li>
                          );
                        } else if (typeof feature === "object") {
                          const [title, nestedFeatures] =
                            Object.entries(feature)[0];
                          return (
                            <React.Fragment key={i}>
                              <li className="features-item">
                                <img
                                  src={checkBox}
                                  alt="checkbox"
                                  className="checkbox-icon"
                                />
                                <span className="feature-text">{title}</span>
                              </li>
                              <ul className="nested-features-list">
                                {nestedFeatures.map((nestedFeature, j) => (
                                  <li
                                    key={`${i}-${j}`}
                                    className="nested-feature-item"
                                  >
                                    <span className="bullet-icon">•</span>
                                    <span className="feature-text">
                                      {nestedFeature}
                                    </span>
                                  </li>
                                ))}
                              </ul>
                            </React.Fragment>
                          );
                        }
                      })}
                    </ul>
                  </div>
                </div>
              )}
            </div>
            <div className="line-payment"></div>
            <div className="right-payment">
              <CheckoutForm
                selectedPlan={selectedPlan}
                contactInfo={contactInfo}
                aboutYouData={aboutYouData}
                isPaymentTriggered={paymentTriggered}
                handlePayment={handlePayment}
                nextClick={nextClick}
                upgrade={forUpgrade}
              />
            </div>
          </div>
        </Elements>
      )}
      <div className="signUp-footer">
        <div onClick={backClick}>
          <Backbutton />
        </div>
        <div
          className="continue-btns"
          onClick={() => setPaymentTriggered(true)}
        >
          <SMLButton style="SMLButton2 SMLButton-Orange" value="Continue" />
        </div>
      </div>
    </div>
  );
};

export default PaymentPopup;

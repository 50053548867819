import {
  ASSIGN_SUPERVISOR_FAILURE,
  ASSIGN_SUPERVISOR_LOADING,
  ASSIGN_SUPERVISOR_REQUEST,
  ASSIGN_SUPERVISOR_SUCCESS,
} from "./actionTypes";

export const assignSuperVisorRequest = ({ trackId, supervisor }) => {
  return {
    type: ASSIGN_SUPERVISOR_REQUEST,
    payload: { trackId, supervisor },
  };
};
export const assignSuperVisorSuccess = (assigned) => {
  return {
    type: ASSIGN_SUPERVISOR_SUCCESS,
    payload: assigned,
  };
};
export const assignSuperVisorFailure = (error) => {
  return {
    type: ASSIGN_SUPERVISOR_FAILURE,
    payload: error,
  };
};
export const assignSuperVisorLoading = () => {
  return {
    type: ASSIGN_SUPERVISOR_LOADING,
  };
};

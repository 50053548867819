import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import SMLLabel from "../../../components/Shared/SMLLabel/SMLLabel";
import SMLButton from "../../../components/Shared/SMLButton/SMLButton";
import playIcon from "../../../assets/Images/playIcon.svg";
import pauseIcon from "../../../assets/Images/pauseIcon.svg";
import "./TrackSection.css";
import { useDispatch, useSelector } from "react-redux";
import { assignSuperVisorRequest } from "../../../redux/actions";
import { useNavigate } from "react-router-dom";
import { RESET_ASSIGNED_SUPERVISOR } from "../../../redux/actions/actionTypes";
import { Tooltip } from "react-tooltip";
import DurationFormatter from "../DurationFormatter/DurationFormatter";
import { Clock4 } from "lucide-react";

const TrackSection = ({
  track,
  handleTrackFeedback,
  trackImg,
  audioSrc,
  mediaRef,
  handleTimeUpdate,
  handleTogglePlay,
  isPlaying,
  progress,
  handleShowAssignPopup,
}) => {
  const trackID = track._id;
  const status = track.status.code;
  const isMaster = localStorage.getItem("isMaster");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentSup = useSelector(
    (state) => state.sml.allArtistFetched?.artists?._id
  );
  const handleAssignMyslef = (trackID) => {
    dispatch(
      assignSuperVisorRequest({ trackId: trackID, supervisor: currentSup })
    );
  };
  const assigned = useSelector((state) => state.assignSupervisor.assigned);
  useEffect(() => {
    if (assigned && Object.keys(assigned).length > 0 && isMaster === "false") {
      navigate(`/TrackFeedback/${trackID}`);
      dispatch({ type: RESET_ASSIGNED_SUPERVISOR });
    }
  }, [assigned]);
  function formatDate(isoDateStr) {
    const date = new Date(isoDateStr);
    const now = new Date();
    const diffInMs = now - date;

    const seconds = Math.floor(diffInMs / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days}d`;
    } else if (hours > 0) {
      return `${hours}h`;
    } else if (minutes > 0) {
      return `${minutes}m`;
    } else {
      return `0m`;
    }
  }

  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation(); // Prevent the click from bubbling up
          handleTrackFeedback(trackID, status);
        }}
        className="trackSectionMain"
      >
        <Row className="mb-3 trackRow">
          <Col style={{ maxWidth: "111px", marginTop: "15px" }}>
            <div className="video-player">
              <img
                src={trackImg}
                className="trackImg"
                width={82}
                height={82}
                alt="Track"
              />
              <audio
                ref={mediaRef}
                src={audioSrc}
                onTimeUpdate={handleTimeUpdate}
                style={{ width: "100%" }}
                onClick={handleTogglePlay}
              />
              <div className="controls">
                {isPlaying ? (
                  <img src={pauseIcon} alt="Pause" onClick={handleTogglePlay} />
                ) : (
                  <img src={playIcon} alt="Play" onClick={handleTogglePlay} />
                )}
              </div>
            </div>
          </Col>
          <Col className="mt-3 track-name">
            <Row>
              <Col>
                <div>
                  <SMLLabel
                    style="title1"
                    value={
                      <>
                        <span className="d-xl-none">
                          {track.title.length > 10 ? (
                            <>
                              {track?.title.substring(0, 10)}
                              <a
                                data-tooltip-id="trackTitle"
                                place="top"
                                effect="solid"
                                data-tooltip-content={track?.title}
                                data-tooltip-place="bottom"
                                multiline="true"
                              >
                                ....
                              </a>
                              <Tooltip id="trackTitle" />
                            </>
                          ) : (
                            track.title
                          )}
                        </span>
                        <span className="d-none d-xl-inline">
                          {track.title.length > 25 ? (
                            <>
                              {track?.title.substring(0, 25)}
                              <a
                                data-tooltip-id="trackTitleXL"
                                place="top"
                                effect="solid"
                                data-tooltip-content={track?.title}
                                data-tooltip-place="bottom"
                                multiline="true"
                              >
                                ....
                              </a>
                              <Tooltip id="trackTitleXL" />
                            </>
                          ) : (
                            track.title
                          )}
                        </span>
                      </>
                    }
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  <SMLLabel
                    style="float-right"
                    value={<DurationFormatter seconds={track?.duration} />}
                  />
                </div>
              </Col>
            </Row>
          </Col>

          <Col
            className="mt-3 col-auto"
            style={{ marginLeft: "20px", marginRight: "20px" }}
          >
            <Row>
              <Col className="d-flex align-items-end justify-content-end">
                <div>
                  <SMLLabel style="sub-subtitle2" value={"Genre"} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex align-items-end justify-content-end">
                <div>
                  <SMLLabel style="title" value={track.genre} />
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            className="mt-3 col-auto"
            style={{ marginLeft: "20px", marginRight: "20px" }}
          >
            <Row>
              <Col className="d-flex align-items-end justify-content-end">
                <div>
                  <SMLLabel style="sub-subtitle2 float-right" value="Status" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex align-items-end justify-content-end">
                <div>
                  {/* Only visible on screens smaller than 1200px */}
                  <div className="d-xl-none">
                    {track.status.label.length > 10 ? (
                      <>
                        {track?.status.label.substring(0, 10)}
                        <a
                          data-tooltip-id="trackStatusXL"
                          place="top"
                          effect="solid"
                          data-tooltip-content={track?.status.label}
                          data-tooltip-place="bottom"
                          multiline="true"
                        >
                          ....
                        </a>
                        <Tooltip id="trackStatusXL" />
                      </>
                    ) : (
                      track.status.label
                    )}
                  </div>

                  {/* Only visible on screens larger than or equal to 1200px */}
                  <div className="d-none d-xl-block">
                    <SMLLabel
                      style={{ float: "right" }} // Corrected style as an object
                      value={track.status.label}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          {isMaster === "true" && track?.assigned_supervisor && (
            <>
              <Col
                className="mt-3 col-auto"
                style={{
                  marginLeft: "20px",
                  marginRight: "20px",
                  maxWidth: "250px",
                }}
              >
                <Row>
                  <Col className="d-flex align-items-end justify-content-end">
                    <div>
                      <SMLLabel style="sub-subtitle2" value={"Assigned to"} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex align-items-end justify-content-end">
                    <div>
                      <SMLLabel
                        style="title"
                        value={
                          track?.assigned_supervisor?.name
                            ? track.assigned_supervisor.name
                            : status !== "feedback_submitted"
                            ? "Myself"
                            : ""
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col
                className="mt-3 col-auto d-flex justify-content-start align-items-center gap-2"
                style={{
                  marginLeft: "20px",
                  marginRight: "20px",
                  minWidth: "4rem",
                  // maxWidth: "250px",
                }}
              >
                <SMLLabel style="sub-subtitle2" value={<Clock4 />} />
                <SMLLabel
                  style="sub-subtitle3"
                  value={`
                          ${formatDate(
                            track?.assigned_supervisor?.date_assigned
                          )}`}
                />
              </Col>
            </>
          )}

          {!track?.assigned_supervisor?.name &&
            isMaster === "true" &&
            status === "submitted" && (
              <Col
                className="mt-3 col-auto"
                style={{ marginLeft: "20px", marginRight: "20px" }}
              >
                <Row>
                  <SMLButton
                    style="SMLAssignBtn"
                    value="Assign"
                    handleClickEvent={(e) => {
                      e.stopPropagation(); // Prevent the click from bubbling up
                      handleShowAssignPopup(trackID);
                    }}
                    textColor={"#ff4405"}
                  />
                </Row>
              </Col>
            )}
        </Row>
        <Row>
          {/* <div className="progress">
            <div
              className="progress-bar"
              style={{ width: `${progress}%` }}
            ></div>
          </div> */}
        </Row>
      </div>
    </>
  );
};

export default TrackSection;

import React, { useEffect, useState } from "react";
import Styles from "./Header.module.css";
import search from "../../../assets/Images/Search.svg";
import upload from "../../../assets/Images/Upload.svg";
import ProfileDropdown from "./ProfileDropdown";
import UploadTrack from "../../Shared/UploadTrack/UploadTrack";
import NotifPanel from "../notif-panel/NotifPanel";
import { useDispatch, useSelector } from "react-redux";
import {
  searchRequest,
  setSearchTextAction,
} from "../../../redux/actions/searchActions";
import { getNotificationRequest } from "../../../redux/actions";
import { RESET_SEARCH } from "../../../redux/actions/actionTypes";

export default function Header({ status }) {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  const handleInputChange = (e) => {
    dispatch({ type: RESET_SEARCH });
    const value = e.target.value;
    setSearchText(value);

    dispatch(setSearchTextAction(value));

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    if (value.trim() !== "") {
      setTypingTimeout(
        setTimeout(() => {
          console.log("i am getting called", value);
          dispatch(searchRequest(value, status, 0, 10));
        }, 1000)
      );
    } else {
      // If searchText is empty, do not trigger search
      setTypingTimeout(null); // Clear any previous timeout if text is empty
    }
  };
  useEffect(() => {
    console.log("calling");
    // dispatch(getNotificationRequest({ offset: 0, limit: 10 }));
  }, [dispatch]);
  const websocketNotifications =
    useSelector((state) => state.websocket?.result) || [];
  const unread = useSelector(
    (state) => state.notifs?.notifs?.notifs?.unreadCount
  );

  const apiNotifications =
    useSelector((state) => state.notifs?.notifs?.notifs?.data) || [];

  const notifications =
    websocketNotifications.length > 0
      ? websocketNotifications
      : unread > 0
      ? apiNotifications
      : [];
  const isWebSocket = websocketNotifications.length > 0;
  // const notification = useSelector((state) => state.websocket?.result || []);

  return (
    <div className={Styles.container}>
      <div className={Styles.leftContainer}>
        <input
          onChange={handleInputChange}
          value={searchText}
          placeholder="Search"
        />
        <img
          onClick={() => {
            if (searchText.trim() !== "") {
              dispatch(searchRequest(searchText));
            }
          }}
          src={search}
          alt="Search"
        />
      </div>
      <div className={Styles.rightContainer}>
        <button
          onClick={togglePopup}
          style={{ backgroundColor: "transparent" }}
        >
          <img src={upload} alt="Cloud Arrow Up" />
        </button>
        {isPopupOpen && <UploadTrack onPopupClose={togglePopup} />}
        <NotifPanel
          panelType={"artist"}
          data={notifications}
          isWebSocket={isWebSocket}
        />
        <ProfileDropdown />
      </div>
    </div>
  );
}

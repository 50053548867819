import React from "react";
import { useNavigate } from "react-router-dom";
import Backbutton from "../../components/Shared/BackButton/BackButton";
import SMLButton from "../../components/Shared/SMLButton/SMLButton";
import "../../components/Shared/SMLButton/SMLButton.css";
import SMLLabel from "../../components/Shared/SMLLabel/SMLLabel";
import SMLOptionQuestion from "../../components/Shared/SMLOptionQuestion/SMLOptionQuestion";
import SMLTextArea from "../../components/Shared/SMLTextArea/SMLTextArea";
import SMLTextBox from "../../components/Shared/SMLTextBox/SMLTextBox";
import SMLToggleButton from "../../components/Shared/SMLToggleButton/SMLToggleButton";
import "../../styles/Fonts.scss";

import Steps from "../PlanSteps/PlanSteps";
import { MdOutlineClose } from "react-icons/md";
import "../PlanContactInfo/PlanContactInfo.css";

const PlanAboutYou = (props) => {
  const [optionSelected, setoptionSelected] = React.useState(false);
  const [optionSelected1, setoptionSelected1] = React.useState(false);
  const [optionSelected2, setoptionSelected2] = React.useState(false);
  const [optionSelected3, setoptionSelected3] = React.useState(false);
  const navigate = useNavigate();

  const handleAbout = (e) => {
    e.preventDefault();
    props.onAboutClick();
  };

  const handleClickOption = (option) => {
    if (option === "option") {
      setoptionSelected(!optionSelected);
    }
    if (option === "option1") {
      setoptionSelected1(!optionSelected1);
    }
    if (option === "option2") {
      setoptionSelected2(!optionSelected2);
    }
    if (option === "option3") {
      setoptionSelected3(!optionSelected3);
    }
  };

  const handleBackClick = () => {
    props.onBackClick("contact");
  };

  return (
    <div className="plan-signUp-container">
      <div className="plan-signup-card">
        <div className="plan-signUp-groupby">
          <div className="plan-signUp-progress-steps">
            <Steps step="2" />
          </div>
          <div className="plan-signUp-close-div">
            <MdOutlineClose />
          </div>
        </div>
        <div className="plan-signUp-main">
          <div className="plan-signUp-label-div">
            <SMLLabel value="Tell Us About Yourself" style="Mainheader" />
          </div>
          <form className="plan-signUp-grid" onSubmit={handleAbout}>
            <div className="plan-signUp-content">
              <div className="plan-signUp-controls">
                <div className="plan-signUp-fields">
                  <SMLLabel value="Who are you?" style="title2"></SMLLabel>
                  <div className="plan-signUp-groupby">
                    <SMLOptionQuestion
                      type="button"
                      style={
                        optionSelected
                          ? "SMLSecondaryButtonSelected"
                          : "SMLSecondaryButton"
                      }
                      handleClickEvent={() => handleClickOption("option")}
                      value="Vocalist/Musician"
                    />
                    <SMLOptionQuestion
                      type="button"
                      style={
                        optionSelected1
                          ? "SMLSecondaryButtonSelected"
                          : "SMLSecondaryButton"
                      }
                      handleClickEvent={() => handleClickOption("option1")}
                      value="Songwriter"
                    />
                    <SMLOptionQuestion
                      type="button"
                      style={
                        optionSelected2
                          ? "SMLSecondaryButtonSelected"
                          : "SMLSecondaryButton"
                      }
                      handleClickEvent={() => handleClickOption("option2")}
                      value="Producer"
                    />
                    <SMLOptionQuestion
                      type="button"
                      style={
                        optionSelected3
                          ? "SMLSecondaryButtonSelected"
                          : "SMLSecondaryButton"
                      }
                      handleClickEvent={() => handleClickOption("option3")}
                      value="Composer"
                    />
                  </div>
                </div>
                <div className="plan-signUp-fields">
                  <div className="signUp-groupby">
                    <SMLToggleButton value="Yes" checked="checked" />
                    <SMLLabel
                      value="Do you have experience with sync?"
                      style="title2"
                    ></SMLLabel>
                  </div>
                </div>
                <div className="plan-signUp-fields">
                  <SMLLabel
                    value="How did you hear about Sync Music Lab?"
                    style="title2"
                  ></SMLLabel>
                  <SMLTextBox />
                </div>
                <div className="plan-signUp-fields">
                  <SMLLabel
                    value="Add Personal Links"
                    style="title2"
                  ></SMLLabel>
                  <SMLTextBox />
                  <b>+</b>
                </div>
              </div>
              <div onClick={handleBackClick}>
                <Backbutton />
              </div>
            </div>
            <div className="plan-signUp-content">
              <div className="plan-signUp-controls">
                <SMLLabel
                  value="Tell us about your music experience."
                  style="title2"
                ></SMLLabel>
                <SMLTextArea />
                <SMLLabel
                  value="What kind of guidance are you looking for?"
                  style="title2"
                ></SMLLabel>
                <SMLTextArea />
              </div>
              <div
                className="plan-signUp-footer"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row-reverse",
                }}
              >
                <div>
                  <SMLButton
                    style="SMLButton2 SMLButton-Orange"
                    value="Continue"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PlanAboutYou;

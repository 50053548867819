import {
VIDEO_CONSULTATION_REQUEST,
VIDEO_CONSULTATION_SUCCESS,
VIDEO_CONSULTATION_FAILURE,
VIDEO_CONSULTATION_LOADING
} from './actionTypes';

// Action to Schedule Live Consultation
export const requestVideoConsultation = (trackId) => ({
  type: VIDEO_CONSULTATION_REQUEST,
  payload: trackId
});

// Action to handle successful video consultation
export const videoConsultationSuccess = (data) => ({
  type: VIDEO_CONSULTATION_SUCCESS,
  payload: data
});

// Action to handle failed video consultation
export const videoConsultationFailure = (error) => ({
  type: VIDEO_CONSULTATION_FAILURE,
  payload: error
});

// Action to set loading state for video consultation
export const videoConsultationLoading = () => ({
  type: VIDEO_CONSULTATION_LOADING
});
import {
  GET_NOTIFICATION_FAILURE,
  GET_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_RESPONSE_SUCCESS,
  GET_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_REQUEST,
} from "./actionTypes";

export const getNotificationRequest = ({ offset, limit }) => {
  return {
    type: GET_NOTIFICATION_REQUEST,
    payload: { offset, limit },
  };
};
export const getNotificationSuccess = (notifs) => {
  return {
    type: GET_NOTIFICATION_SUCCESS,
    payload: { notifs },
  };
};
export const getNotificationResponseSuccess = (response) => {
  return {
    type: GET_NOTIFICATION_RESPONSE_SUCCESS,
    payload: { response },
  };
};
export const getNotificationFailure = ({ error }) => {
  return {
    type: GET_NOTIFICATION_FAILURE,
    payload: error,
  };
};

export const updateNotificationRequest = () => {
  return {
    type: UPDATE_NOTIFICATION_REQUEST,
  };
};

import React from "react";
import back from "../../../assets/Images/backButton.svg";
import "./BackButton.css";
import "../../../styles/Fonts.scss";

const Backbutton = (props) => {
  var icon = back;

  return (
    <div className="footer-back">
      <button className="button-with-icon" type="button">
        <span className="sml-iconbtn fontcolorblack">
          <img src={icon} alt="icon" style={{ padding: "3px 5px 4px 1px" }} />
        </span>
        <span className="title2 back-btn">Back</span>
      </button>
    </div>
  );
};

export default Backbutton;

import { jwtDecode } from "jwt-decode"; // Corrected import
import api from "../api";
const BASE_URL = process.env.REACT_APP_API_URL;
// Function to assign supervisor  track
export const assignSupervisorApi = async ({ trackId, supId }) => {
  try {
    const accessToken = localStorage.getItem("token");
    if (!accessToken) {
      console.error("No token found. Please log in.");
      return;
    }
    const decodedToken = jwtDecode(accessToken);
    const supervisorId = decodedToken["custom:supervisor_id"];
    let data = new FormData();
    data.append("supervisor_id", supId);
    let url = ``;
    if (supervisorId === supId) {
      url = `${BASE_URL}/tracks/${trackId}/me/assign`;
    } else {
      url = `${BASE_URL}/tracks/${trackId}/supervisors/assign`;
    }
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await api.post(url, data, config);
    if (response.status !== 200) {
      console.error(
        "Failed to assign supervisor. Status code:",
        response.status
      );
    }

    return response.data;
  } catch (error) {
    console.log(
      "Error message in assigning supervisor to track by id",
      error.message
    );
  }
};

import { call, put, takeLatest } from 'redux-saga/effects';
import {
VIDEO_CONSULTATION_REQUEST
} from '../actions/actionTypes';
import { videoConsultationFailure, videoConsultationLoading, videoConsultationSuccess } from '../actions/videoConsultationActions';
import { videoConsultationApi } from '../../api/videoConsultation/videoConsultationApi';

// Worker saga: will be fired on VIDEO_CONSULTATION_REQUEST actions
function* videoConsultationSaga(action) {
try {
  yield put(videoConsultationLoading());
  const response = yield call(videoConsultationApi,action.payload);
  yield put(videoConsultationSuccess(response.data));
} catch (error) {
  yield put(videoConsultationFailure(error));
}
}

// Watcher saga: spawns a new videoConsultationSaga task on each VIDEO_CONSULTATION_REQUEST
function* watchVideoConsultationSaga() {
yield takeLatest(VIDEO_CONSULTATION_REQUEST, videoConsultationSaga);
}

export default watchVideoConsultationSaga;
import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../actions/actionTypes";
import {
  getVideoSignedUrl,
  getVideoUrl,
  deleteVideo,
} from "../../api/video/videoApi";
import {
  getVideoSignedUrlSuccess,
  getVideoSignedUrlFailure,
  getVideoUrlSuccess,
  getVideoUrlFailure,
  deleteVideoSuccess,
  deleteVideoFailure,
  uploadVideoSuccess,
  uploadVideoFailure,
  getVideoSignedUrlLoading,
  getVideoUrlLoading,
  deleteVideoLoading,
  uploadVideoLoading,
  getVideoUrlRequest,
} from "../actions/videoActions";

function* getVideoSignedUrlSaga(action) {
  try {
    yield put(getVideoSignedUrlLoading());
    const data = yield call(getVideoSignedUrl, action.payload);

    if (data && data.upload_url) {
      const signedUrlData = {
        upload_url: data.upload_url,
        file_key: data.file_key,
        expires_in: data.expires_in,
        feedback_id: data.feedback_id,
      };

      yield put({
        type: types.GET_VIDEO_SIGNED_URL_SUCCESS,
        payload: signedUrlData,
      });
    } else {
      throw new Error("Missing required data in response");
    }
  } catch (error) {
    yield put({
      type: types.GET_VIDEO_SIGNED_URL_FAILURE,
      payload: error.message,
    });
  }
}

function* getVideoUrlSaga(action) {
  try {
    yield put(getVideoUrlLoading());
    const response = yield call(getVideoUrl, action.payload.feedbackId);
    yield put(getVideoUrlSuccess(response));
  } catch (error) {
    yield put(getVideoUrlFailure(error.message));
  }
}

function* deleteVideoSaga(action) {
  try {
    yield put(deleteVideoLoading());
    const feedbackId = action.payload;
    
    yield call(deleteVideo, feedbackId);
    yield put(deleteVideoSuccess(feedbackId));
  } catch (error) {
    console.error('Error deleting video:', error);
    yield put(deleteVideoFailure(error.message));
  }
}

function* uploadVideoSaga(action) {
  try {
    yield put(uploadVideoLoading());
    const { file, signedUrl, feedbackId } = action.payload;

    const uploadResponse = yield call(fetch, signedUrl, {
      method: "PUT",
      body: file,
      headers: { "Content-Type": file.type },
    });

    if (!uploadResponse.ok) {
      throw new Error("Upload failed");
    }

    yield put(uploadVideoSuccess());
    yield put({ type: types.GET_VIDEO_URL_REQUEST, payload: { feedbackId } });
  } catch (error) {
    yield put(uploadVideoFailure(error.message));
  }
}

export default function* videoSaga() {
  yield takeLatest(types.GET_VIDEO_SIGNED_URL_REQUEST, getVideoSignedUrlSaga);
  yield takeLatest(types.GET_VIDEO_URL_REQUEST, getVideoUrlSaga);
  yield takeLatest(types.DELETE_VIDEO_REQUEST, deleteVideoSaga);
}

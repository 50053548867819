import React, { useEffect, useRef, useState } from "react";
import "./parallaxVideo.css";
import ReactPlayer from "react-player";
import video from "../../../assets/video/ShannonIntro12.mp4";

const ParallaxVideo = () => {
  const playerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsPlaying(true);
        } else {
          setIsPlaying(false);
        }
      },
      {
        threshold: 0.5,
      }
    );

    if (playerRef.current) {
      observer.observe(playerRef.current);
    }

    return () => {
      if (playerRef.current) {
        observer.unobserve(playerRef.current);
      }
    };
  }, []);

  return (
    <div className="parallax-videoContainer" ref={playerRef}>
      <h1>SML in a nutshell!</h1>
      <div className="parallax-screen">
        <ReactPlayer
          url={video}
          playing={isPlaying} 
          muted={true}
          loop={true}
          volume={0}
          className="react-player"
          width="100%"
          height="100%"
          controls
        />
        <div className="react-player__controls"></div>
      </div>
    </div>
  );
};

export default ParallaxVideo;

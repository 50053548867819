import React, { useState } from "react";
import SMLLabel from "../../components/Shared/SMLLabel/SMLLabel";
import "../../styles/Fonts.scss";
import Steps from "../PlanSteps/PlanSteps";
import { MdOutlineClose } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { Card, Container, Row, Col } from "react-bootstrap";
import checkBox from "../../assets/Images/checkboxIcon.svg";
import bestValue from "../../assets/Images/popular.svg";
import SMLTextBox from "../../components/Shared/SMLTextBox/SMLTextBox";
import SMLCheckbox from "../../components/Shared/SMLCheckbox/SMLCheckbox";
import "../PlanPayment/PlanPayment.css";
import TermsAndConditions from "../../components/UI/TermsAndConditions/TermsAndCondition";

const PlanPayment = (props) => {
  const location = useLocation();
  const selectedCard = location?.state?.selectedCard;
  const [fullName, setFullName] = useState();
  const [email, setEmail] = useState();
  const [createPassword, setCreatePassword] = useState();
  const [agreeConditions, setAgreeConditions] = useState();
  const [verifyPassword, setVerifyPassword] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handlePayment = (e) => {
    e.preventDefault();
    props.onpaymentClick();
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="plan-signUp-container">
      <div className="plan-signup-card">
        <div className="plan-signUp-groupby">
          <div className="plan-signUp-progress-steps">
            <Steps step="3" />
          </div>
          <div className="plan-signUp-close-div">
            <MdOutlineClose />
          </div>
        </div>
        <div className="plan-signUp-main">
          <div className="plan-signUp-label-div">
            <SMLLabel value="Payment" style="Mainheader" />
          </div>
          <form className="plan-signUp-grid" onSubmit={handlePayment}>
            <div className="plan-signUp-content">
              <div className="plan-signUp-controls">
                <div className="plan-signUp-fields">
                  <SMLLabel value="Cardholder Name" style="title2"></SMLLabel>
                  <SMLTextBox
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </div>
                <div className="plan-signUp-fields">
                  <SMLLabel value="CardNumber" style="title2"></SMLLabel>
                  <SMLTextBox
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="plans-signUp-fields signUp-fields-inline">
                  <div className="signUp-fields-inline">
                    <SMLLabel value="Expiry Date" style="title2" />
                    <SMLTextBox
                      value={createPassword}
                      onChange={(e) => setCreatePassword(e.target.value)}
                    />
                  </div>
                  <div className="signUp-fields-inline">
                    <SMLLabel value="CVV" style="title2" />
                    <SMLTextBox
                      value={email}
                      onChange={(e) => setVerifyPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="plans-signUp-fields signUp-fields-inline">
                  <div className="signUp-fields-inline">
                    <SMLLabel value="Country" style="title2" />
                    <SMLTextBox
                      value={createPassword}
                      onChange={(e) => setCreatePassword(e.target.value)}
                    />
                  </div>
                  <div className="signUp-fields-inline">
                    <SMLLabel value="ZIP CODE" style="title2" />
                    <SMLTextBox
                      value={email}
                      onChange={(e) => setVerifyPassword(e.target.value)}
                    />
                  </div>
                </div>

                <div className="signUp-fields">
                  <div className="signUp-groupby">
                    <SMLCheckbox
                      value={agreeConditions}
                      onChange={(e) => setAgreeConditions(e.target.value)}
                    />
                    <div className="title2">
                      I accept all{" "}
                      <a className="link" onClick={openModal}>
                        terms and conditions
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {selectedCard && (
                <Container>
                  <Row>
                    <Col xs={4}>
                      <Card
                        className="payment-card"
                        style={{ backgroundColor: "#FF4405" }}
                      >
                        <Card.Header
                          className="card-Header"
                          style={{
                            background: "none",
                            borderStyle: "none",
                            position: "relative",
                          }}
                        >
                          <Card.Title style={{ top: "50px" }}>
                            {selectedCard.name}
                          </Card.Title>
                          <img
                            src={bestValue}
                            alt="bestValue"
                            style={{
                              position: "absolute",
                              top: "-15px",
                              left: "167px",
                              width: "66px",
                              height: "50px",
                            }}
                          />
                        </Card.Header>
                        <Card.Body>
                          <div className="viewPlan-sub-header">
                            {selectedCard.price}
                          </div>
                          <div className="viewPlan-horizontal-line"></div>
                          <Card.Text className="viewPlan-card-content">
                            {selectedCard.features.map((feature, index) => (
                              <div
                                key={index}
                                className="feature-with-checkbox"
                              >
                                <img src={checkBox} alt="checkbox" />
                                <SMLLabel value={feature} />
                              </div>
                            ))}
                            <button
                              className="custom-button"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Make Payment
                            </button>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              )}
            </div>
          </form>
        </div>
      </div>
      <TermsAndConditions isOpen={isModalOpen} onClose={closeModal}>
        <div>
          <h2>Terms and Conditions</h2>
          <p>Here are the terms and conditions...</p>
        </div>
      </TermsAndConditions>
    </div>
  );
};

export default PlanPayment;

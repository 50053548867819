import React, { useEffect, useState } from "react";
import SMLLabel from "../../components/Shared/SMLLabel/SMLLabel";
import SMLTextBox from "../../components/Shared/SMLTextBox/SMLTextBox";
import Backbutton from "../../components/Shared/BackButton/BackButton";
import "../ContactInfo/ContactInfo.css";
import SMLOptionQuestion from "../../components/Shared/SMLOptionQuestion/SMLOptionQuestion";
import SMLButton from "../../components/Shared/SMLButton/SMLButton";
import closeIcon from "../../assets/Images/closeIcon.svg";
import { useSelector } from "react-redux";
import SMLErrorBox from "../../components/Shared/SMLErrorBox/SMLErrorBox";
import { Bars } from "react-loader-spinner";
const ContactInfo = ({
  onContactInformationClick,
  onBackClick,
  onClose,
  contactData,
}) => {
  const [company, setCompany] = useState(contactData.company || "");
  const [profession, setProfession] = useState(contactData.profession || "");
  const [paymentInfo, setPaymentInfo] = useState(
    contactData?.payment_info.venmo || contactData?.payment_info.zelle || ""
  );
  const [paymentMethod, setPaymentMethod] = useState(
    contactData?.payment_info?.venmo
      ? "venmo"
      : contactData?.payment_info?.zelle
      ? "zelle"
      : ""
  );
  const [signupError, setSignupError] = useState(false);
  const [agreeConditions, setAgreeConditions] = useState(false);
  const { error, loading, supSocialSignedIn, supSignedUp } = useSelector(
    (state) => state.auth
  );
  useEffect(() => {
    if (profession && paymentInfo && company && paymentMethod) {
      console.log(profession.trim);
      setAgreeConditions(true);
    } else {
      setAgreeConditions(false);
    }
  }, [profession, paymentInfo, company, paymentMethod]);
  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepare the payment_info object in camelCase
    const payment_info = {
      venmo: paymentMethod === "venmo" ? paymentInfo : "",
      zelle: paymentMethod === "zelle" ? paymentInfo : "",
    };

    // Prepare the data with camelCase
    const contactData = {
      company,
      profession,
      payment_info: {
        venmo: payment_info.venmo, // camelCase
        zelle: payment_info.zelle, // camelCase
      },
    };

    // Call the parent function with the formatted data
    onContactInformationClick(contactData);
  };
  const payment_info = {
    venmo: paymentMethod === "venmo" ? paymentInfo : "",
    zelle: paymentMethod === "zelle" ? paymentInfo : "",
  };
  const backData = {
    company,
    profession,
    payment_info: {
      venmo: payment_info.venmo,
      zelle: payment_info.zelle,
    },
  };
  const handleBackClick = () => {
    onBackClick(backData);
  };
  useEffect(() => {
    if (error) setSignupError(true);
  }, [error]);

  useEffect(() => {
    setSignupError(false);
  }, []);

  const handleClickOption = (option) => {
    setPaymentMethod(option);
  };

  return (
    <div className="contact-signUp-container">
      <div className="contact-signup-card">
        {loading && (
          <div className="spinner-overlay">
            <Bars
              height="80"
              width="80"
              color="#23f0c7"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
        <div className="contact-signUp-main">
          {/* <div className="contact-close-div" onClick={onClose}>
            <img src={closeIcon} style={{ cursor: "pointer" }} alt="Close" />
          </div> */}
          <div className="contact-label-div">
            <span
              style={{
                color: "#000000",
                fontFamily: "DM Sans",
                fontWeight: "600",
                fontSize: "26px",
              }}
            >
              Sign Up
            </span>
          </div>
          <form className="contact-grid" onSubmit={handleSubmit}>
            <div className="contact-content">
              <div className="contact-controls">
                <div className="contact-fields">
                  {signupError && <SMLLabel style="error" value={error} />}
                  <SMLLabel value="Company" style="title2" />
                  <SMLTextBox
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                  />
                </div>
                <div className="contact-fields">
                  <SMLLabel value="Profession" style="title2" />
                  <SMLTextBox
                    value={profession}
                    onChange={(e) => setProfession(e.target.value)}
                  />
                </div>
                <div className="contact-fields">
                  <SMLLabel
                    value="Venmo/Zelle Transfer Information"
                    style="title2"
                  />
                  <SMLTextBox
                    value={paymentInfo}
                    onChange={(e) => setPaymentInfo(e.target.value)}
                  />
                </div>
                <div
                  className="buttons-container"
                  style={{ "margin-left": "10px" }}
                >
                  <SMLOptionQuestion
                    type="button"
                    style={
                      paymentMethod === "venmo"
                        ? "SMLSecondaryButtonSelected method"
                        : "SMLSecondaryButton method"
                    }
                    handleClickEvent={() => handleClickOption("venmo")}
                    value="Venmo"
                  />
                  <SMLOptionQuestion
                    type="button"
                    style={
                      paymentMethod === "zelle"
                        ? "SMLSecondaryButtonSelected method"
                        : "SMLSecondaryButton method"
                    }
                    handleClickEvent={() => handleClickOption("zelle")}
                    value="Zelle"
                  />
                </div>
                <div className="contact-footer">
                  <div className="contact-backBtn" onClick={handleBackClick}>
                    <Backbutton />
                  </div>
                  <div className="Contact-Btn">
                    <SMLButton
                      style={`SMLButton2 SMLButton-Orange ${
                        agreeConditions ? "" : "disabled"
                      }`}
                      // style="SMLButton2 SMLButton-Orange"
                      value="Sign Up"
                      type="submit"
                      handleDisable={!agreeConditions}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;

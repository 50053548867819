import React, { useEffect, useState } from "react";

const TawkChat = () => {
  const [isMinimized, setIsMinimized] = useState(false);

  useEffect(() => {
    //  minimize the chat window once
    const minimizeChat = () => {
      if (
        typeof Tawk_API !== "undefined" &&
        Tawk_API.minimize &&
        !isMinimized
      ) {
        Tawk_API.minimize();
        setIsMinimized(true);
      } else if (!isMinimized) {
        setTimeout(minimizeChat, 500);
      }
    };

    var Tawk_API = Tawk_API || {};
    var Tawk_LoadStart = new Date();

    (function () {
      var s1 = document.createElement("script");
      var s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/66dba20450c10f7a00a506f9/1i74v1o58";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();
    minimizeChat();
  }, [isMinimized]);

  return null;
};

export default TawkChat;

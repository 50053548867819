import axios from "axios";
import api from "../api";

const BASE_URL = process.env.REACT_APP_API_URL;

export const resetPassword = async ({ oldPassword, newPassword }) => {
  const accessToken = localStorage.getItem("token");

  if (!accessToken) {
    console.error("No token found. Please log in");
    return;
  }
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await api.post(
      `${BASE_URL}/auth/change-password`,
      {
        old_password: oldPassword,
        new_password: newPassword,
      },
      config
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

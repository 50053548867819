import React, { useState } from "react";
import AboutTrackStatus from "./aboutTrackStatus";

const AboutTrack = ({ onAboutTrackClose, track, fromArtistPortal }) => {
  const [page, setPage] = useState("aboutTrackStatus");

  const handleNext = () => {
    switch (page) {
      case "aboutTrackStatus":
        setPage("aboutTrackFeedback");
        break;
      default:
        break;
    }
  };

  const handleBack = () => {
    switch (page) {
      case "aboutTrackStatus":
        setPage("aboutTrackMusicCredits");
        break;
      case "aboutTrackFeedback":
        setPage("aboutTrackStatus");
        break;
      default:
        break;
    }
  };
  return (
    <div>
      {page === "aboutTrackStatus" && (
        <AboutTrackStatus
          onBackBtn={handleBack}
          onNextBtn={handleNext}
          onClose={onAboutTrackClose}
          track={track}
          fromArtistPortal={fromArtistPortal}
        />
      )}
    </div>
  );
};

export default AboutTrack;

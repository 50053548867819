import {
  RESET_WEBSOSCKET_RESULT,
  WEBSOCKET_ERROR,
  WEBSOCKET_LOADING,
  WEBSOCKET_REQUEST,
  WEBSOCKET_SUCCESS,
} from "../actions/actionTypes";

export const websocketReducers = (
  state = {
    loading: false,
    result: [],
    error: null,
  },
  action
) => {
  switch (action.type) {
    case WEBSOCKET_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case WEBSOCKET_SUCCESS:
      return {
        ...state,
        loading: false,
        result: [...state.result, action.payload],
        error: null,
      };
    case WEBSOCKET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_WEBSOSCKET_RESULT:
      return {
        loading: false,
        result: [],
        error: null,
      };
    default:
      return state;
  }
};
export default websocketReducers;

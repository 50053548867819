import React from "react";
import "../../../styles/Fonts.scss";
import "../../../styles/HTMLControls.scss";
import "./SMLErrorBox.css";

function SMLErrorBox(props) {
  return (
    <div className={`SMLErrorBox ${props.style}`}>
      <span className="SMLError props.style">{props.message}</span>
    </div>
  );
}

export default SMLErrorBox;

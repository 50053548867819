import {
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SEARCH_FAILURE,
  SET_SEARCH_TEXT,
} from "./actionTypes";

export const setSearchTextAction = (value) => {
  return {
    type: SET_SEARCH_TEXT,
    payload: { value },
  };
};
export const searchRequest = (query, status, offset, limit) => {
  return {
    type: SEARCH_REQUEST,
    payload: { query, status, offset, limit },
  };
};

import React from 'react';

const DurationFormatter = ({ seconds, className }) => {
  const formatDuration = (seconds) => {
    if (!seconds && seconds !== 0) return '0:00';
    
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  return (
    <span className={className}>
      {formatDuration(seconds)}
    </span>
  );
};

export default DurationFormatter; 
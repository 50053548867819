import api from "../api";
const BASE_URL = process.env.REACT_APP_API_URL;
const isTokenExpired = () => {
  const tokenExpiryTime = localStorage.getItem("tokenExpiryTime");
  return !tokenExpiryTime || Date.now() > tokenExpiryTime;
};
const getValidToken = async () => {
  if (isTokenExpired()) {
    throw new Error("Token expired. Please log in again.");
  }
  return localStorage.getItem("accessToken");
};

export const updateTrackApi = async ({ trackId, updatedData }) => {
  try {
    const token = await getValidToken();
    const response = await api.put(
      `${BASE_URL}/track/${trackId}`,
      updatedData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error updating track",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

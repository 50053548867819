import { call, put, takeLatest } from "redux-saga/effects";
import {
  SEARCH_REQUEST,
  SEARCH_LOADING,
  SEARCH_SUCCESS,
  SEARCH_FAILURE,
  SEARCH_RESPONSE_SUCCESS,
} from "../actions/actionTypes";
import { searchApi } from "../../api/search/searchApi";

function* getSearch(query) {
  try {
    yield put({ type: SEARCH_LOADING });
    console.log("call hogi", query);
    const result = yield call(
      searchApi,
      query.payload.query,
      query.payload.status,
      query.payload.offset,
      query.payload.limit
    );
    yield put({ type: SEARCH_SUCCESS, result: result.data });
    yield put({ type: SEARCH_RESPONSE_SUCCESS, pagination: result.pagination });
  } catch (error) {
    yield put({ type: SEARCH_FAILURE, error: error });
  }
}

function* searchSaga() {
  yield takeLatest(SEARCH_REQUEST, getSearch);
}
export default searchSaga;
